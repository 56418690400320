.custom-lab {
  &__choose-putter {
    margin-top: 30px;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 34px;

    padding-bottom: 40px;

    @include sm {
      padding-bottom: 0;
    }

    @include md {
      margin-top: 50px;
    }
  }

  &__choose-putter-item {
    position: relative;
    height: 31vw;

    @include sm {
      height: 180px;
    }

    @include md {
      height: 240px;
    }

    @include lg {
      height: 300px;
    }

    @include xl {
      height: 380px;
    }

    input[type=radio] {
      display: none;
    }
  }

  &__choose-putter-label {
    margin-left: 5px;
    margin-right: 5px;
    border: 10px solid transparent;
    display: block;
    height: 100%;
    transition: $trs;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;

      @include object-fit(cover, center);
    }
  }

  input:checked+&__choose-putter-label {
    border-color: #2F5C6E;
  }

  &__choose-putter-title {
    position: absolute;
    top: 105%;
    left: 20px;
    right: 20px;
    color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    font-family: $ff-headers;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 3px;
    transition: $trs;
    user-select: none;

    @include sm {
      top: auto;
      color: #00000a;
      bottom: 20px;
    }

    @include md {
      font-size: 24px;
      bottom: 30px;
    }

    @include lg {
      font-size: 30px;
      bottom: 46px;
    }

    svg {
      width: 16px;
      height: 13px;
      fill: transparent;
      stroke: #fff;
      margin-right: 5px;
      transition: $trs;
      flex-shrink: 0;

      @include sm {
        stroke: #000;
      }

      @include md {
        width: 24px;
        height: 24px;
        margin-right: 14px;
        margin-top: -1px;
      }

      @include lg {
        margin-top: 2px;
      }
    }
  }

  input:checked+&__choose-putter-label &__choose-putter-title {
    color: $accent;

    svg {
      stroke: $accent;
    }
  }
}
.tech-banner {
    background-color: #fafafa;
    padding: 40px 0 42px;
    overflow: hidden;
    @include lg {
        padding: 56px 0 64px;
    }
    &__inner {
        background: white;
        position: relative;
        padding: 40px 35px 40px;
        @include sm {
            padding: 40px 40px 34px;
        }
        @include md {
            padding: 40px 40px 56px 40px;
        }
        &-title {
            font-size: 22px;
            font-weight: bold;
            margin: 0 0 29px;
            letter-spacing: 4px;
        }
        p {
            line-height: 1.79;
            font-size: 14px;
        }
        img {
            position: absolute;
            width: 120px;
            height: 85px;
            right: -60px;
            bottom: -25px;
            @include sm {
                width: 206px;
                height: 188px;
                bottom: -18px;
                right: 0;
            }
            @include lg {
                top: -30px;
                width: 305px;
                height: 278px;
                right: -30px;
                bottom: auto;
            }
            @include xl {
                width: 339px;
                height: 278px;
                right: 0;
            }
        }
    }
}

.tech-content {
    padding: 60px 0 55px;
    @include lg {
        padding: 100px 0 68px;
    }
    @include xl {
        padding: 100px 0 78px;
    }
    overflow: hidden;
    &__title {
        font-size: 30px;
        margin: 0 0 40px;
        &.tech-content__title2 {
            font-size: 28px;
            margin: 0 0 26px;
            @include lg {
                font-size: 36px;
                margin: 0 0 34px;
            }
        }
    }
    &__inner {
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.64;
            margin: 0 0 28px;
            &:last-child {
                margin: 0;
            }
        }
    }
  
   
    img {
        margin: 61px auto 0;
        display: block;
        @include lg {
           margin: 40px 0 0 0;
        }
    }
}

.tech-content-small {
    img {
        max-width: initial;
        margin: 60px 0 -160px auto;
        width: 260px;
        @include sm {
            margin: 60px 0 -200px auto;
            width: 350px;
        }
        @include lg {
            width: 276px;
            margin: 0 0 -90px 0;
        }
    }
}

.tech-ul {
        margin: 0 0 28px;
        padding: 0;
        list-style-type: none;
        li {
            position: relative;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.64;
            margin: 0 0 25px;
            padding-left: 20px;
            &:before {
                display: block;
                position: absolute;
                left: 0;
                top: 5px;
                content: "";
                background-image: url(../img/icons/icn-carret-down.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 5px;
                width: 5px;
                height: 9px;
            }
          
            &:last-child {
                margin: 0;
            }
    }
}
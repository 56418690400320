.locations-list {
  &__item {
    padding-left: 24px;
    padding-top: 21px;
    padding-bottom: 9px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    transition: background-color .3s ease;

    @include xl {
      padding-bottom: 20px;
    }

    &.highlighted {
      background-color: rgba($accent, 0.2);
    }

    &:not(:last-child) {
      border-bottom: solid 1px #e6e6e6;
    }
  }

  &__item-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    color: #4a4a4a;
    flex-grow: 1;

    @include xl {
      font-size: 14px;
      line-height: 1.5;
    }

    b {
      font-weight: 500;
    }

    a,
    a:visited {
      color: $accent;
    }

    a:hover {
      color: $accent-dark;
    }
  }

  &__item-side {
    margin-left: 15px;
  }

  &__item-btn {
    display: block;
    margin-bottom: 15px;

    svg {
      width: 30px;
      height: 36px;
      fill: #9b9b9b;
      transition: $trs;
    }

    &:hover svg {
      fill: $accent;
    }
  }

  &__item-distance {
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    color: #000000;
    font-weight: bold;
    text-align: center;
    
    b {
      display: block;
      font-size: 20px;
      font-family: $ff-headers;
      font-weight: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
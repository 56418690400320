@import './custom-lab/choose-putter';
@import './custom-lab/steps';
@import './custom-lab/summary';

$top-bar-height: 110px;
$top-bar-height-mobile: 80px;

.custom-lab {
  height: $top-bar-height-mobile;
  position: relative;

  @include md {
    height: $top-bar-height;
  }

  &--without-btn {
    height: 0;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.opening &__wrapper,
  &.closing &__wrapper {
    z-index: 1000 !important;
  }

  &.opened &__wrapper {
    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__top {
    height: $top-bar-height-mobile;
    background-color: $accent;
    position: relative;
    z-index: 5;
    transition: all .8s ease;

    @include md {
      height: $top-bar-height;
    }

    >* {
      height: 100%;
    }
  }

  &--without-btn &__top {
    height: 0;
    opacity: 0;
  }

  &--without-btn.opening &__top,
  &--without-btn.opened &__top {
    height: $top-bar-height-mobile;
    opacity: 1;

    @include md {
      height: $top-bar-height;
    }
  }

  &__overlay {
    opacity: 0.4;
    background-color: #000000;
    position: fixed;
    z-index: 4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
  }

  &__top-closed {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &--without-btn &__top-closed {
    display: none !important;
  }

  &__top-opened {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &.stick:not(.opened) &__wrapper {
    top: auto;
    position: fixed;
    z-index: 10;
    // @include md-max {
    //   top: auto;
    //   position: fixed;
    //   z-index: 10;
    // }
  }

  &__inner {
    display: none;
    background-color: #111;
    overflow-y: auto;
    height: calc(100vh - #{$top-bar-height-mobile});

    @include md {
      height: calc(100vh - #{$top-bar-height});
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  &__top-text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    h6 {
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 8px;

      @include md {
        letter-spacing: 4.4px;
        font-size: 24px;
      }
    }

    p {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      @include md {
        font-size: 16px;
      }
    }
  }

  &__top-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;

    @include sm {
      width: 55px;
      height: 55px;
    }

    @include md {
      width: 70px;
      height: 70px;
    }
  }

  &__close-btn {
    font-size: 0;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;

    @include sm {
      font-size: 12px;
    }

    @include md {
      font-size: 16px;
    }

    &:hover {
      color: $dark;
    }

    span {
      margin-left: 13px;
      font-size: 16px;

      @include lg {
        font-size: 22px;
      }
    }
  }

  &__title {
    margin-top: 65px;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 4px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 7px;

    @include sm {
      font-size: 38px;
    }

    @include lg {
      font-size: 50px;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #9b9b9b;

    @include sm {
      font-size: 16px;
    }
  }

  &__next-step {
    font-family: $ff-headers;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3px;
    text-align: center;
    color: $accent;
    cursor: pointer;

    @include md {
      font-size: 30px;
    }

    &:hover:not(:disabled) {
      color: $accent-light;
    }

    &:disabled {
      cursor: default;
      color: #414141;
    }
  }

  &__steps-overview {
    margin-top: 44px;
    display: flex;
    justify-content: center;

    li {
      font-family: $ff-headers;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 3px;
      color: #414141;
      text-transform: uppercase;
      margin-bottom: 10px;

      @include md {
        font-size: 30px;
      }
    }
  }

  [data-lab-screen] {
    display: none;
    padding-bottom: 200px;

    @include md {
      padding-bottom: 50px;
    }
  }
}
.patents {
    padding-bottom: 90px;
    &__inner {
        border-bottom: 1px solid #d6d6d6;
        border-top: 1px solid #d6d6d6;
        padding: 57px 20px 59px;
        margin-bottom: 60px;
        @include sm {
            padding: 57px 47px 59px;
         }
        @include lg {
            padding: 52px 34px 62px;
         }
         @include xl {
            padding: 52px 115px 62px;
         }
    }
    &__item {
        margin-bottom: 60px;
        @include lg {
            margin-bottom: 52px;
         }
        &:last-child {
            margin-bottom: 0;
        }
        &-title {
            font-size: 26px;
            margin: 0 0 20px;
            @include lg {
                font-size: 30px;
             }
        }
        p {
            margin: 0 0 21px;
            font-size: 16px;
            font-weight: 500;
            @include lg {
                margin: 0 0 16px;
             }
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $accent;
                font-weight: normal;
                font-size: inherit;
                display: block;
                word-break: break-word;
                margin-top: 6px;
                @include lg {
                   display: inline;
                   margin: 0;
                   padding-left: 13px;
                 }
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}
.tabs-nav {
  margin-top: 10px;
  margin-bottom: 43px;
  display: flex;
  justify-content: center;

  @include lg {
    margin-top: 33px;
  }

  &__item {
    padding: 15px 5px;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    transition: $trs;

    @include rwd(414) {
      padding: 15px 10px;
    }

    @include sm {
      padding: 27px 25px 19px;
    }

    @include lg {
      padding: 19px 60px 16px;
    }

    @include xl {
      padding: 19px 75px 16px;
    }


    &:first-child {
      flex-grow: 1;
      text-align: right;
      padding-left: 0;
    }

    &:last-child {
      flex-grow: 1;
      padding-right: 0;
    }

    &:first-child.active {
      border-right: 1px solid #e6e6e6;
    }

    &:last-child.active {
      border-left: 1px solid #e6e6e6;
    }

    &.active:not(:first-child):not(:last-child) {
      border-right: 1px solid #e6e6e6;
      border-left: 1px solid #e6e6e6;
    }

    &.active {
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid transparent;
      color: #000000;
    }
  }

  &__item.active &__btn {
    color: #000000;
  }

  &__btn {
    font-family: $ff-headers;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.2px;
    color: $accent;
    cursor: pointer;
    transition: $trs;

    @include rwd(414) {
      font-size: 16px;
    }

    @include sm {
      font-size: 20px;
    }

    @include lg {
      font-size: 30px;
      letter-spacing: 3.3px;
    }

    &:hover {
      color: #000000;
    }
  }
}

.tab-content {
  display: none;

  margin-bottom: 70px;
}
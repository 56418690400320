.slider-prev-arrow--type-1,
.slider-next-arrow--type-1 {
  height: 50px;
  width: 50px;
  border-style: solid;
  border-color: #ccc;
  transform: rotate(45deg);
  transition: $trs;
  position: absolute;
  top: 50%;
  margin-top: -25px;

  &:hover {
    border-color: $accent-dark;
  }
}

.slider-prev-arrow--type-1 {
  border-width: 0 0 1px 1px;
  left: -35px;

  @include md {
    left: -50px;
  }

  @include lg {
    left: -70px;
  }
}

.slider-next-arrow--type-1 {
  border-width: 1px 1px 0 0;
  right: -35px;

  @include md {
    right: -50px;
  }

  @include lg {
    right: -70px;
  }
}

.slider-dots--type-1 {
  position: absolute;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 0;

  button {
    width: 8px;
    height: 8px;
    font-size: 0;
    border-radius: 50%;
    margin: 0 6px;
    background-color: #d8d8d8;
    opacity: 0.5;
    transition: $trs;
    cursor: pointer;
  }

  button:hover,
  .slick-active button {
    opacity: 1;
  }
}
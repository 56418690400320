.select2-container--custom-theme {
  height: 60px;
  border: solid 1px #676767;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  color: #000000;
  text-transform: uppercase;
  font-variant-numeric: lining-nums;

  @include sm {
    font-size: 14px;
  }

  .styled-select__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px 0 24px;
  }

  .select2-selection--single .select2-selection__rendered {
    padding: 0;
  }

  .select2-selection__rendered {
    padding-left: 0;
  }

  .styled-select__dropdown {
    border: solid 1px #676767;
  }

  .select2-results__option:hover,
  .select2-results__option--highlighted {
    color: $accent;
  }

  .select2-selection__arrow {
    background: url(../img/icons/select-arrows.svg) center/cover no-repeat;
    height: 24px;
    width: 15px;
  }
}

.select2-container--custom-theme-2 {
  height: 34px;
  border: solid 1px #676767;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  color: #676767;
  font-variant-numeric: lining-nums;

  @include sm {
    font-size: 14px;
  }

  &.select2-container--open .select2-dropdown--below {
    border: solid 1px #676767;
  }

  .styled-select__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 17px;
  }

  .select2-selection--single .select2-selection__rendered {
    padding: 0;
  }

  .select2-selection__rendered {
    padding-left: 0;
  }

  .select2-results__option:hover,
  .select2-results__option--highlighted {
    color: $accent;
  }

  .select2-selection__arrow {
    background: url(../img/icons/icon-down-arrow.svg) center/cover no-repeat;
    height: 7px;
    width: 14px;
  }

  &.select2-container--open {
    .select2-selection__arrow {
      transform: rotate(180deg);
    }
  }
}

.select2-container--custom-theme-option {
  min-width: 210px;
  height: 50px;
  border: solid 1px #676767;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  color: #676767;
  font-variant-numeric: lining-nums;

  @include sm {
    font-size: 14px;
  }

  @include md {
    height: 70px;
  }

  .styled-select__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 17px;

    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00000a;
  }

  .styled-select__dropdown {
    .select2-results__option:not([aria-selected=true]) {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #00000a;

      padding: 14px 18px;
      height: 50px;
      display: flex;
      align-items: center;

      @include md {
        height: 70px;
      }
    }
  }

  .select2-selection--single .select2-selection__rendered {
    padding: 0;
  }

  .select2-selection__rendered {
    padding-left: 0;
  }

  &.select2-container--open .select2-dropdown--above {
    border-top: none;
  }

  .select2-results__option:hover,
  .select2-results__option--highlighted {
    color: $accent;
  }

  .select2-selection__arrow {
    background: url(../img/icons/icon-down-arrow.svg) center/cover no-repeat;
    height: 7px;
    width: 14px;
  }

  &.select2-container--open {
    .select2-selection__arrow {
      transform: rotate(180deg);
    }
  }
}

.styled-select__dropdown.select2-dropdown--below {
  left: -1px;
  top: -2px;
}

.styled-select__dropdown.select2-dropdown--above {
  left: -1px;
  // top: -2px;
}

.styled-select__dropdown {
  border-radius: 0;
  border: solid 1px #676767;

  .select2-results__options {
    max-height: 300px;
    overflow-y: auto;
  }

  .select2-results__option {
    padding: 5px 17px;
  }

  .select2-search.select2-search--dropdown {
    padding: 5px 14px;
    border-bottom: 1px solid #ccc;
  }

  .select2-search__field {
    padding: 5px 10px;
    border: solid 1px #ccc;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    color: #676767;
    text-transform: uppercase;
    font: inherit
  }
}


.styled-select-option__color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 17px;
  flex-shrink: 0;

  @include md {
    width: 44px;
    height: 44px;
  }
}

.styled-select-option__wrapper {
  display: flex;
  align-items: center;
}

.select2-results__option[aria-selected=true] {
  display: none;
}

// .select2-container--open {
//   border-bottom: none;
// }

.styled-select__dropdown.select2-dropdown--below {
  left: -1px;
  top: -2px;
}

.styled-select__dropdown.select2-dropdown--above {
  left: -1px;
  // top: -2px;
}

.styled-select__dropdown {
  border-radius: 0;

  .select2-results__options {
    max-height: 300px;
    overflow-y: auto;
  }

  .select2-results__option {
    padding: 18px 24px;
  }

  .select2-search.select2-search--dropdown {
    padding: 18px 14px;
    border-bottom: 1px solid #ccc;
  }

  .select2-search__field {
    padding: 5px 10px;
    border: solid 1px #ccc;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    color: #000000;
    text-transform: uppercase;
    font: inherit
  }
}
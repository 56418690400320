.location-filter {
  margin-top: 46px;

  @include md {
    margin-top: 64px;
  }

  &__country-wrapper {
    @include lg {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  &__country-wrapper &__select-wrapper {
    display: none;
  }

  &__country-wrapper.active &__select-wrapper {
    display: block;
  }

  &__select-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;

    &--city {
      @include lg {
        display: none !important;
      }
    }
  }
}
.posr {
  position: relative;
}

.tac {
  text-align: center;
}

.ttu {
  text-transform: uppercase;
}

.ovh {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.w-100 {
  width: 100% !important;
}

.center-img {
  text-align: center;
  font-size: 0;
}

.border-wide {
  border-width: 7px;
  border-style: solid;
  padding-top: 29px;
  padding-left: 35px;
  padding-right: 35px;

  @include lg {
    border-width: 10px;
  }

  @include xl {
    padding-top: 40px;
  }

  &--grey {
    border-color: #FAFAFA;
  }
}

$border-margin: 33px;
$border-margin-lg: 39px;
$border-margin-xl: 50px;

.border-margin {
  @extend .border-margin-sm;
  @extend .border-margin-md;
  @extend .border-margin-lg;
  @extend .border-margin-xl;

  margin-top: $border-margin;
}

.border-margin-sm {
  @extend .border-margin-md;
  @extend .border-margin-lg;
  @extend .border-margin-xl;

  @include sm {
    margin-top: $border-margin;
  }
}

.border-margin-md {
  @extend .border-margin-lg;
  @extend .border-margin-xl;

  @include md {
    margin-top: $border-margin;
  }
}

.border-margin-lg {
  @extend .border-margin-xl;

  @include lg {
    margin-top: $border-margin-lg;
  }
}

.border-margin-xl {
  @include xl {
    margin-top: $border-margin-xl;
  }
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

// Flex rules
.fxdc {
  flex-direction: column;
}

.df {
  display: flex;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}



.order-first {
  order: -1;
}

.order-first-sm {
  @include sm {
    order: -1;
  }
}

.order-first-md {
  @include md {
    order: -1;
  }
}

.order-first-lg {
  @include lg {
    order: -1;
  }
}

.order-first-xl {
  @include xl {
    order: -1;
  }
}

.order-first-xxl {
  @include xxl {
    order: -1;
  }
}

.bg-accent {
  background-color: $accent;
}

.bg-black {
  background-color: #000;
}

.bg-grey {
  background-color: #fafafa;
}

$gap1-mobile: 20px;
$gap2-mobile: 40px;
$gap3-mobile: 50px;

$gap1-desktop: 30px;
$gap2-desktop: 60px;
$gap3-desktop: 80px;

.mb-1 {
  margin-bottom: $gap1-mobile;

  @include md {
    margin-bottom: $gap1-desktop;
  }
}

.mb-2 {
  margin-bottom: $gap2-mobile;

  @include md {
    margin-bottom: $gap2-desktop;
  }
}

.mb-3 {
  margin-bottom: $gap3-mobile;

  @include md {
    margin-bottom: $gap3-desktop;
  }
}

.mt-1 {
  margin-top: $gap1-mobile;

  @include md {
    margin-top: $gap1-desktop;
  }
}

.mt-2 {
  margin-top: $gap2-mobile;

  @include md {
    margin-top: $gap2-desktop;
  }
}

.mt-3 {
  margin-top: $gap3-mobile;

  @include md {
    margin-top: $gap3-desktop;
  }
}

.pb-1 {
  padding-bottom: $gap1-mobile;

  @include md {
    padding-bottom: $gap1-desktop;
  }
}

.pb-2 {
  padding-bottom: $gap2-mobile;

  @include md {
    padding-bottom: $gap2-desktop;
  }
}

.pb-3 {
  padding-bottom: $gap3-mobile;

  @include md {
    padding-bottom: $gap3-desktop;
  }
}

.pt-1 {
  padding-top: $gap1-mobile;

  @include md {
    padding-top: $gap1-desktop;
  }
}

.pt-2 {
  padding-top: $gap2-mobile;

  @include md {
    padding-top: $gap2-desktop;
  }
}

.pt-3 {
  padding-top: $gap3-mobile;

  @include md {
    padding-top: $gap3-desktop;
  }
}

.p-1 {
  padding: $gap1-mobile;

  @include md {
    padding: $gap1-desktop;
  }
}

.p-2 {
  padding: $gap2-mobile;

  @include md {
    padding: $gap2-desktop;
  }
}

.p-3 {
  padding: $gap3-mobile;

  @include md {
    padding: $gap3-desktop;
  }
}

.h-50 {
  height: 50px;
}

.h-40 {
  height: 40px;
}

.h-60 {
  height: 60px;
}
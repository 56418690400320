.preloader {
	position: fixed;
	z-index: 1000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;

	display: flex;
	align-items: center;
	justify-content: center;
}

.cssload-container * {
	box-sizing: border-box;
}

.cssload-container {
	max-width: 49px;
}

.cssload-flex-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
}

.cssload-flex-container li {
	padding: 10px;
	height: 97px;
	width: 97px;
	margin: 29px 19px;
	position: relative;
	text-align: center;
	list-style: none;
}

.cssload-loading {
	display: inline-block;
	position: relative;
	width: 73px;
	height: 73px;
	border-radius: 4px;
	border-bottom: 5px solid rgb(0, 0, 0);
	overflow: hidden;
}

.cssload-loading:after,
.cssload-loading:before {
	position: absolute;
	content: '';
	background: rgb(84, 186, 226);
	border-radius: 100%;
}

.cssload-loading:before {
	height: 34px;
	width: 34px;
	left: 26.5%;
	animation: cssload-bounce1 1.55s ease-in infinite;
	animation-direction: alternate;
}

.cssload-loading:after {
	height: 15px;
	width: 15px;
	left: 39.5%;
	animation: cssload-bounce2 1.55s ease-out infinite;
	animation-direction: alternate;
	transform: translateY(219px);
}


@keyframes cssload-bounce1 {
	from {
		transform: translateY(0px);
	}

	to {
		transform: translateY(249px);
	}
}

@keyframes cssload-bounce2 {
	from {
		transform: translateY(219px);
	}

	to {
		transform: translateY(0px);
	}
}
.page-title {
  text-align: center;
  font-size: 32px;
  letter-spacing: 4.2px;
  font-weight: bold;
  margin-bottom: 40px;

  @include sm {
    font-size: 38px;
    letter-spacing: 5.6px;
  }

  @include lg {
    font-size: 50px;
  }
}

.page-title--small {
  font-size: 26px;

  @include sm {
    font-size: 32px;
    letter-spacing: 4px;
  }

  @include lg {
    margin-top: 80px;
    margin-bottom: 15px;
    font-size: 36px;
  }
}

.page-subtitle {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  @include sm {
    font-size: 16px;
  }

  @include lg {
    margin-bottom: 60px;
  }
}

.page-title+.page-subtitle {
  margin-top: -20px;
}

h1.page-title--shop {
  margin-bottom: 0;

  @include lg {
    text-align: left;
  }
}

h2.page-title--shop {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: left;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 3.3px;
  margin-bottom: 10px;
}
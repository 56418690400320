.content {
  font-size: 14px;
  color: $text;

  ul {
    margin-bottom: 30px;

    @include xl {
      margin-bottom: 60px;
    }

    li {
      padding-left: 27px;
      position: relative;
      margin-bottom: 20px;
      line-height: 1.79;

      @include xl {
        margin-bottom: 25px;
      }

      &::before {
        content: '';
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
        border-top: solid 1px #4a4a4a;
        border-right: solid 1px #4a4a4a;
        position: absolute;
        left: 0;
        top: 7px;
      }
    }
  }

  ol {
    counter-reset: item;
    list-style-type: none;

    @include xl {
      margin-bottom: 60px;
    }

    li {
      padding-left: 33px;
      position: relative;
      line-height: 1.79;
      margin-bottom: 25px;
      font-weight: 500;

      &::before {
        content: counter(item, decimal-leading-zero) "  ";
        counter-increment: item;
        // font-family: $ff-headers;
        font-weight: 500;
        font-size: 16px;
        position: absolute;
        font-variant-numeric: lining-nums;
        line-height: 1.5;
        color: #777777;
        left: 0;
        letter-spacing: 1.3px;
      }

      b {
        font-weight: 600;
      }
    }
  }

  a {
    color: $accent;

    &:hover {
      color: $accent-dark;
    }
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #eaeaea;
    height: 1px;
  }

  b {
    font-weight: 600;
  }

  p {
    line-height: 1.79;
    margin-bottom: 25px;
  }

  .iframe-video-wrapper {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  p+.iframe-video-wrapper  {
    margin-top: 10px;

    @include lg {
      margin-top: 70px;
    }
  }
}

.iframe-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// .content + .content {
//   margin-top: 36px;

//   @include xl {
//     margin-top: 50px;
//   }
// }

.content + .content--remote-fitting {
  margin-top: 36px;

  @include xl {
    margin-top: 80px;
    padding-top: 50px;
    padding-bottom: 35px;
  }
}
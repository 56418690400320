.subscribe {
  padding: 50px 0;
  background-color: $accent;

  h3 {
    margin-bottom: 30px;
  }

  @include lg {
    padding: 75px 0 78px;
  }

  &__input {
    margin-bottom: 30px;
    width: 100%;
    height: 50px;
    padding: 0 24px;
    font: inherit;

    font-size: 14px;
    font-weight: 500;
    color: $text;

    @include md {
      margin-bottom: 0;
    }
  }
}
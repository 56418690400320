.banner {
    background-color: #fafafa;
    padding: 40px 0 65px;
    @include lg {
        padding: 40px 0 60px;
    }
    &__inner {
        background: white;
        padding: 70px 25px 65px;
        text-align: center;
        @include sm {
            padding: 70px 35px;
        }
    }
    &__image {
        margin: 0 0 50px;
        @include lg {
            margin: 0 0 40px;
        }
        &.banner__image-small {
            img {
                @include lg {
                    width: 80px;
                }
            }
         
        }
        img {
            width: 130px;
            @include sm {
                width: 190px;
            }
            @include lg {
                width: auto;;
            }
        }
    }
    &__info {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.79;
        max-width: 475px;
        margin: 0 auto;
        @include lg {
            font-size: 14px;
        }
        strong {
            font-weight: bold;
            text-transform: uppercase;
          
        }
        a {
            color: $accent;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
}
.testim-top {
    background-color: #fafafa;
    padding: 40px 0;
    overflow: hidden;
    @include lg {
        padding: 46px 0;
    }
    @include xl {
        padding: 48px 0 40px;
    }
    &__inner {
        background: white;
        position: relative;
        overflow: hidden;
        @include lg {
           overflow: inherit;
        }
    }
    img {
        position: absolute;
        left: -40px;
        bottom: 0;
        width: 120px;
        @include sm {
            left: -60px;
            width: 208px;
        }
        @include lg {
            left: -1px;
        }
        @include xl {
            width: 245px;
        }
    }
    &__desc {
        padding: 35px 22px 21px 34px;
        width: calc(100% - 245px);
        margin: 0 0 0 auto;
        width: calc(100% - 60px);
        @include sm {
            width: calc(100% - 149px);
        }
        @include lg {
            padding: 27px 28px 28px 40px;
            width: calc(100% - 208px);
        }
        @include xl {
            padding: 41px 52px 26px 48px;
            width: calc(100% - 245px);
        }
    } 
    &__title {
        margin: 0 0 19px;
        font-size: 22px;
        @include lg {
            font-size: 26px;
            margin: 0 0 10px;
        }
        @include xl {
            font-size: 30px;
            margin: 0 0 19px;
        }
    }
    p {
        font-size: 15px;
        font-weight: 500;
        margin: 0 0 17px;
        line-height: 1.56;
        @include sm {
            font-size: 16px;
        }
        @include lg {
            margin: 0 0 14px;
        }
        @include xl {
            line-height: 1.63;
            margin: 0 0 21px;
        }
    }
    &__link {
        font-size: 14px;
        font-weight: bold;
        color: $accent;
        text-transform: uppercase;
    }
}

.testim-main {
    padding: 12px 0 100px;
    &__item {
        padding: 40px 0;
        border-bottom: 1px solid #d6d6d6;
        @include sm {
            padding: 40px 46px;
        }
        @include lg {
            padding: 60px 80px;
        }
        @include xl {
            padding: 60px 0;
        }
        &:last-child {
            margin-bottom: 50px;
            @include lg {
                margin-bottom: 60px;
            }
            @include xl {
                margin-bottom: 74px;
            }
        }
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        @include lg {
            align-items: flex-start;
        }
    }
    &__image {
        width: 44%;
        text-align: center;
        @include sm {
            width: 50%;
        }
        @include lg {
            width: calc(30% - 15px);
        }
        img {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            @include sm {
                width: 150px;
                height: 150px;
            }
        }
    }
    &__desc {
        width: 56%;
        padding-left: 15px;
        @include sm {
            width: 50%;
        }
        @include lg {
            padding: 0 49px 0 0;
            width: calc(70% - 15px);
        }
        @include xl {
            padding: 0 72px 0 0;
        }
        p {
            display: none;
            @include lg {
                display: block;
            }
        }
    }
    &__mobile {
        padding-top: 35px;
        @include lg {
            display: none;
        }
    }
    &__title {
        font-size: 14px;
        font-weight: bold;
        font-family: "TTWellingtons", sans-serif;
        position: relative;
        padding-left: 35px;
        line-height: 20px;
        @include lg {
            padding-left: 49px;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            width: 25px;
            height: 23px;
            background: url(../img/icons/icon-cite.svg) center/cover no-repeat;
        }
    }
    &__subtitle {
        padding-left: 35px;
        color: #676767;
        font-size: 14px;
        margin: 0 0 14px;
        line-height: 20px;
        display: block;
        @include lg {
            padding-left: 49px;
        }
    }
    p {
        font-size: 16px;
        color: $text;
        line-height: 1.69;
        font-style: italic;
    }

}

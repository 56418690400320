.cta {
  font-family: $ff-primary;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  transition: $trs;

  svg {
    transition: $trs;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  @include sm {
    font-size: 16px;
  }
}

.cta--accent {
  background-color: $accent;
  color: #ffffff;

  &:hover:not(:disabled) {
    background-color: $accent-dark;
  }
}

.cta--white {
  background-color: #fff;
  color: $accent;
  font-weight: bold;
  border: solid 2px #fff;

  &:hover:not(:disabled) {
    background-color: $accent;
    color: #fff;

  }
}

.cta--black {
  background-color: #000;
  color: #ffffff;
  font-weight: 600;

  &:hover:not(:disabled) {
    font-weight: 800;
  }
}

.cta--border-accent {
  border: solid 2px $accent;
  color: $accent;

  &:hover:not(:disabled) {
    background-color: $accent;
    color: #fff;
  }
}

.cta--border-white {
  border: solid 2px #fff;
  color: #fff;

  &:hover:not(:disabled) {
    background-color: #fff;
    color: $accent;
  }
}

.cta--border-black {
  border: solid 2px #000;
  color: #000;

  svg {
    fill: #000;
  }

  &:hover:not(:disabled) {
    background-color: #000;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
}

.cta--add-cart  {
  border: solid 2px #000;
  color: #000;
  padding-left: 14px;
  padding-right: 12px;
  font-size: 12px;
  height: 40px;
  letter-spacing: normal;

  @include sm {
    font-size: 13px;
  }

  @include md {
    padding-left: 8px;
    padding-right: 8px;
  }

  svg {
    width: 12px;
    height: 12px;
    margin-right: 9px;
    fill: #000;
  }

  &:hover:not(:disabled) {
    background-color: $accent;
    border-color: $accent;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
}

.cta--add-cart-accent  {
  color: #fff;
  background-color: $accent;
  padding: 0 10px;
  font-size: 12px;
  height: 50px;
  letter-spacing: normal;
  width: 150px;
  
  @include sm {
    width: 200px;
    font-size: 14px;
  }

  svg {
    width: 14px;
    height: 14px;
    margin-right: 20px;
    fill: #fff;
  }

  &:hover:not(:disabled) {
    background-color: $accent-dark;

    svg {
      fill: #fff;
    }
  }
}

.cta--40 {
  font-size: 12px;
  height: 40px;
  padding: 0 16px;

  @include sm {
    font-size: 14px;
  }
}

.cta--50 {
  height: 50px;
}

.cta--60 {
  height: 60px;
}

.cta--w100 {
  width: 100%;
  padding: 0;
}

.cta--promo {
  @include lg {
    min-width: 200px;
  }
}

.cta--filter {
  svg {
    width: 14px;
    height: 18px;
    margin-right: 20px;
    margin-bottom: 2px;
  }
}

.row>.col,
.row>[class*="col-"] {
  &>.cta {
    display: flex;
    padding: 0;
    width: 100%;
  }
}
.video {
    &-full {
        background-color: #181818;
        &.video-full-row2 {
            padding: 35px 0;
            @include lg {
                padding: 60px 0;
            }
            .video:first-child {
                margin-bottom: 20px ;
                @include lg {
                    margin: 0;
                }
            }
        }
        &__height {
            width: 100%;
            height: 0;
            padding-bottom: 177.528%;
        }
    }
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }



    &-content {
        padding: 60px 0 45px;
        @include lg {
            padding: 70px 0 ;
        }
        &__title {
            font-size: 26px;
            margin: 0 0 25px;
            letter-spacing: 2.6px;
            @include lg {
                font-size: 30px;
                margin: 0 0 37px;
                letter-spacing: 3px;
            }
        }
        &__desc {
            padding: 30px 0 60px;
            font-size: 14px;
            line-height: 1.64;
            @include lg {
                padding: 27px 0 37px;
            }
            @include xl {
                padding: 35px 0 54px;
            }
        }
        img {
            width: 100%;
        }
        &__image {
            max-width: 322px;
            margin: 40px auto 0;
            display: block;
            @include lg {
                margin: 0 0 0 -160px;
                max-width: initial;
            }
            @include xl {
                margin-left: -60px;
            }
        }
        .tech-content__inner {
            padding-top: 34px;
            @include lg {
                padding-top: 64px;
            }
        }
        .tech-content__title {
            font-size: 26px;
            margin: 0 0 25px;
            @include lg {
                font-size: 30px;
                margin: 0 0 40px;
            }
        }
    }
}
.video-content-pages {
    padding: 60px 0 20px;
    @include lg {
        padding: 70px 0 ;
    }
    .video-content__desc {
        padding: 16px 0 39px;
        @include lg {
            padding: 19px 0 33px;
        }
        @include xl {
            padding: 18px 0 29px;
        }
    }
}

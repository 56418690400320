// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 992px;

// Large tablets and desktops
$screen-xl: 1200px;

// Large tablets and desktops
$screen-xxl: 1680px;

// Small devices
@mixin sm {
  @media only screen and (min-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media only screen and (min-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media only screen and (min-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media only screen and (min-width: #{$screen-xl}) {
    @content;
  }
}

// Extra extra large devices
@mixin xxl {
  @media only screen and (min-width: #{$screen-xxl}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

// // Desktop First

// // Small tablets and large smartphones (landscape view)
// $screen-sm-max: 575px;

// // Small tablets (portrait view)
// $screen-md-max: 767px;

// // Tablets and small desktops
// $screen-lg-max: 991px;

// // Large tablets and desktops
// $screen-xl-max: 1365px;

// // Large tablets and desktops
// $screen-xxl-max: 1679px;

// Small devices
@mixin sm-max {
  @media only screen and (max-width: #{$screen-sm - 1px}) {
    @content;
  }
}

// Medium devices
@mixin md-max {
  @media only screen and (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

// Large devices
@mixin lg-max {
  @media only screen and (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}

// Extra large devices
@mixin xl-max {
  @media only screen and (max-width: #{$screen-xl - 1px}) {
    @content;
  }
}

// Extra extra large devices
@mixin xxl-max {
  @media only screen and (max-width: #{$screen-xxl - 1px}) {
    @content;
  }
}

// Custom devices
@mixin rwd-d($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}

.form {
  &__input {
    font: inherit;
    width: 100%;
    border: solid 1px #676767;
    transition: $trs;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    padding-left: 18px;
    padding-right: 18px;
    color: $dark;
    margin-bottom: 20px;

    &:focus {
      border-color: $accent;
    }
  }

  &__note {
    color: #9b9b9b;
    text-align: center;
    margin-top: 30px;
    font-size: 12px;
    line-height: 1.58;
  }

  &__title {
    font-family: $ff-headers;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 4px;
    text-align: center;
    display: block;
    color: #00000a;
    margin-bottom: 27px;
  }

  &__textarea {
    font: inherit;
    width: 100%;
    border: solid 1px #676767;
    transition: $trs;
    height: 78px;
    font-size: 14px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    color: $dark;
    margin-bottom: 20px;
    line-height: 1.6;
    resize: none;

    &:focus {
      border-color: $accent;
    }
  }

  &__small-title {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #00000a;
    margin-bottom: 10px;

    @include md {
      margin-bottom: 16px;
      font-size: 16px;
    }
  }
}

.form--become-fitter {
  border: 7px solid #fafafa;
  padding-top: 39px;
  padding-bottom: 32px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;

  @include lg {
    border-width: 10px;
  }
}

.form--review {
  padding-top: 63px;
  padding-bottom: 65px;
  background-color: #fafafa;

  .form__input {
    height: 40px;
    line-height: 40px;
  }

  .form__input,
  .form__textarea {
    margin-bottom: 25px;
  }
}

.form--reply {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;

  .form__small-title {
    width: 100%;
  }

  .form__textarea {
    width: 100%;
    height: 80px;

    @include md {
      width: 70%;
      height: 50px;
      margin-right: 15px;
    }

    @include lg {
      width: 80%;
    }
  }

  .cta {
    width: 200px;

    @include md {
      width: calc(30% - 15px);
    }

    @include lg {
      width: calc(20% - 15px);
    }
  }
}
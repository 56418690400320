.search {
  padding: 10px;

  &__input {
    width: 100%;
    border: solid 1px #b8b8b8;
    transition: $trs;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding-left: 54px;
    padding-right: 18px;
    color: $dark;
    background-image: url(../img/icons/icon-search-999.svg);
    background-repeat: no-repeat;
    background-position: 21.6px 11px;
    transition: $trs;

    &:focus {
      border-color: $accent;
      background-image: url(../img/icons/icon-search-accent.svg);
    }
  }

  .cta {
    background-color: #fff;
  }
}

.search--shop {
  @include lg {
    margin-bottom: 25px;
  }

  @include xl {
    margin-bottom: 30px;
  }
}
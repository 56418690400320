.help {
  &__group {
    margin-top: 40px;

    @include md {
      margin-top: 50px;
    }

    &:last-child {
      margin-bottom: 60px;

      @include md {
        margin-bottom: 40px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  .cta {
    margin-top: 40px;
    margin-bottom: 60px;

    @include md {
      margin-top: 60px;
      margin-bottom: 100px;
    }
  }
}
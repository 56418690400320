.cart {
    background: rgba(#f9f9f9, 0.8);
    padding: 40px 0 100px;
    @include md {
        padding: 40px 0 130px;
    }
    &-product {
        background: white;
        padding: 18px 10px 24px;
        margin-bottom: 24px;
        @include sm {
            padding: 22px 25px 24px;
        }
        @include xl {
            padding: 22px 40px 24px;
        }
        &__item {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            @include md {
                flex-wrap: nowrap;
            }
            &-image {
                margin-bottom: 20px;
                width: 100%;
                @include md {
                    margin-bottom: 0;
                    width: auto;
                }
                img {
                    border: 1px solid #e8e8e8;
                    margin-bottom: 16px;
                 
                    @include sm {
                        width: 90px;
                        min-width: 90px;
                    }
                    @include md {
                        margin-bottom: 24px;
                    }
                    @include xl {
                        width: 110px;
                        min-width: 110px;
                    }
                }
            }
            a {
                font-size: 12px;
                font-weight: 500; 
                display: block;
                color: $accent;
                line-height: 1.92;
                &.cart-product__remove {
                    color: #4f4f4f; 
                }
            }
            &-desc {
                @include md {
                    padding: 0 0 0 16px;
                }
            }
            &-name {
                line-height: 1.36;
                margin: 0 0 5px;
                font-size: 14px;
                font-weight: 600;
            }
        }
        &__table {
            width: 100%;
            th {
                border-bottom: 1px solid rgba(#f9f9f9, 0.8);
                padding: 14px 7px;
                text-align: left;
                font-size: 12px;
                font-weight: 500;
                color: #9b9b9b;
                @include sm {
                    padding: 14px 13px;
                }
                @include xl {
                    padding: 14px 16px;
                }
                &:last-child {
                    text-align: right;
                    padding-right: 0;
                }
                &:first-child {
                    padding-left: 0;
                }
            }
            td {
                padding: 20px 7px 24px;
                border-bottom: 1px solid #eeeeee;
                @include sm {
                    padding: 20px 13px 24px;
                }
                @include xl {
                    padding: 20px 16px 24px;
                }
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                    text-align: right;
                }
            }
        }
        &__charact {
            border-radius: 6px;
            background-color: #fafafa;
            padding: 8px 12px 8px 17px;
            display: none;
            @include sm {
                min-width: 200px;
                display: table;
            }
            @include xl {
                min-width: 257px;
            }
            td {
                font-size: 11px;
                font-weight: 500;
                padding: 7px 10px 7px 0;
                text-align: left;
                border: 0;
                @include sm {
                    font-size: 12px;
                    padding: 8px 14px 8px 0;
                }
                @include xl {
                    padding: 8px 20px 8px 0;
                }
                &:first-child {
                    text-transform: uppercase;
                    font-weight: bold;
                    @include xl {
                        white-space: nowrap;
                    }
                }
                &:last-child {
                    text-align: left;
                }
            }
        }
    }
    .product-price  {
        padding-top: 28px;
        display: block;
        font-size: 14px;
        @include sm {
            font-size: 20px;
        }
    }
    .wrap-number {
        .form-elements__input {
            font-size: 14px;
            @include sm {
                font-size: 20px;
            }
        }
    }
}

.product-price {
    font-size: 20px;
    line-height: 0.95;
    letter-spacing: 2.5px;
    color: #00000a;
    font-weight: normal;
    font-family: $ff-headers;
}

.cart-row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    @include lg {
        flex-wrap: nowrap;
    }
    &--left {
        width: 100%;
        margin: 0 0 55px;
        @include lg {
            width: 68%;
            margin: 0 30px 0 0;
        }
    }
    &--right {
        width: 100%;
        max-width: 400px;
        margin:  auto;
        @include lg {
            width: 300px;
            margin: 0;
        }
    }
}

.continue-shopping {
    position: relative;
    padding: 0 19px 0 35px;
    &:before  {
        height: 10px;
        width: 10px;
        content: '';
        transform: rotate(-135deg) translateY(70%);
        border-top: solid 2px #4a4a4a;
        border-right: solid 2px #4a4a4a;
        position: absolute;
        left: 12px;
        top: 50%;
        transition: all 0.2s ease;
    }
    &:hover,
    &:focus {
        &:before {
            border-top: solid 2px white;
            border-right: solid 2px white;
        }
    }
}


.coupon {
    &__title {
        font-size: 12px;
        font-weight: 500;
        color: #9b9b9b;
        margin: 0 0 8px;
        display: block;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid  #eeeeee;
        border-bottom: 1px solid  #eeeeee;
        padding: 15px 0 30px;
        input[type="text"] {
            width: 71%;
            margin: 0;
            height: 40px;
            background: transparent;
            padding: 10px 13px;
            font-size: 12px;
            display: inline-flex;
            align-items: center;
        }
        input[type="submit"] {
            width: 26%;
            cursor: pointer;
            background: white;
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            font-weight: bold;
            &:hover,
            &:focus {
                background: $accent;
                color: white;
            }
        }
    }
}
.cart-total {
    &__info {
        padding: 20px 0 25px;
        border-bottom: 1px solid  #eeeeee;
        table {
            width: 100%;
        
        }
        tr:first-child {
            td {
                color: #00000a;
            }
        }
        td {
            line-height: 2.07;  
            font-size: 14px;
            font-weight: 500;
            color: #676767;
            &:last-child {
                line-height: 1.06;
                letter-spacing: 2.3px;
                font-family: $ff-headers;
                font-size: 18px;
                font-weight: bold;
                text-align: right;
            }
        }
    }
}
.order-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 25px 0 37px;
    &__title {
        font-size: 14px;
        font-weight: bold;
        line-height: 2.07;  
        color: #00000a;
        text-transform: uppercase;
    }
    &__price {
        font-size: 32px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 4px;
        font-family: $ff-headers;
    }
}
.proc-checkout {
    width: 100%;
}

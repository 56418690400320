.sort-block {
  background-color: #FAFAFA;
  padding: 22px 20px 24px;
  margin-bottom: 40px;

  @include sm {
    padding: 22px 40px 24px;
    margin-top: 24px;
  }

  &__go-back {
    height: 34px;
    margin-bottom: 20px;
    max-width: 135px;
    margin-left: auto;
    margin-right: auto;

    @include md {
      margin-bottom: 0;
    }
  }

  &__reset-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;

    @include md {
      justify-content: flex-start;
      text-align: left;
      margin-bottom: 0;
      height: 100%;
    }

    button {
      height: 34px;
      line-height: 34px;
      border-radius: 17px;
      border: solid 1px #676767;
      font-size: 14px;
      font-weight: 500;
      color: #676767;
      padding-left: 19px;
      padding-right: 19px;
      margin-right: 22px;
      margin-bottom: 10px;

      @include md {
        margin-bottom: 0;
      }

      &:hover {
        color: $accent;
        border-color: $accent;
      }

      &::after {
        content: '\2716';
        padding-left: 5px;
      }
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #676767;

      @include md {
        text-align: left;
        width: auto;
      }
    }
  }

  &__form {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include md {
      display: flex;
      align-items: center;
      max-width: none;
      margin-left: 0;
      margin-right: 0;
    }

    label {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #676767;
      margin-bottom: 5px;

      @include md {
        margin-bottom: 0;
      }
    }

    .select2 {
      margin-bottom: 10px;

      @include md {
        margin-left: 20px;
        margin-bottom: 0;
      }
    }
  }

  &__search {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;

    @include md {
      margin-left: 20px;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    }

    input {
      height: 34px;
      border-radius: 17px;
      border: solid 1px #676767;
      width: 100%;
      padding-left: 19px;
      line-height: 34px;

      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      color: #676767;

      &:focus {
        border-color: $accent;
      }
    }
  }
}
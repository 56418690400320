@include font-face('BebasNeue',
'../fonts/BebasNeue/BebasNeue-Bold',
700,
null,
woff2 woff);

@include font-face('BebasNeue',
'../fonts/BebasNeue/BebasNeue-Regular',
400,
null,
woff2 woff);

@include font-face('TTWellingtons',
'../fonts/TTWellingtons/TTWellingtons-Bold',
700,
null,
woff2 woff);

@include font-face('TTWellingtons',
'../fonts/TTWellingtons/TTWellingtons-Regular',
null,
null,
woff2 woff);

@include font-face('TTWellingtons',
'../fonts/TTWellingtons/TTWellingtons-Medium',
500,
null,
woff2 woff);

@include font-face('TTWellingtons',
'../fonts/TTWellingtons/TTWellingtons-ExtraBold',
800,
null,
woff2 woff);

@include font-face('TTWellingtons',
'../fonts/TTWellingtons/TTWellingtons-DemiBold',
600,
null,
woff2 woff);

@include font-face('TTWellingtons',
'../fonts/TTWellingtons/TTWellingtons-Italic',
null,
italic,
woff2 woff);
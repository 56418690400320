.promo {
  height: 400px;
  position: relative;

  @include sm {
    height: 477px;
  }

  @include lg {
    height: 640px;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 300px;
    
    @include rwd(414) {
      width: 370px;
    }

    @include sm {
      width: 540px;
    }

    @include lg {
      margin-top: 30px;
      width: 800px;
    }

    @include xl {
      margin-top: 22px;
    }
  }

  &__title {
    text-transform: uppercase;
    font-family: $ff-headers;
    margin-top: 0;
    color: #fff;
    font-size: 38px;
    line-height: 1.57;
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 35px;

    strong {
      font-size: 48px;
    }

    em {
      font-style: normal;
      color: $accent-light;
    }

    @include sm {
      letter-spacing: 5.9px;
      font-size: 58px;

      strong {
        font-size: 68px;
      }
    }

    @include lg {
      font-size: 70px;
      line-height: 1.3;
      margin-bottom: 60px;

      strong {
        font-size: 80px;
      }
    }
  }

  &__img {
    @include object-fit(cover, center);
    height: 100%;
    width: 100%;
  }
}
.admin-bar .main-header {
  top: 46px;

  @include md {
    top: 32px;
  }
}

.main-header {
  min-width: 320px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  padding-left: 15px;
  padding-right: 15px;

  transition: $trs;

  @include lg {
    display: flex;
    flex-direction: column;
  }

  @include xl {
    padding-left: 80px;
    padding-right: 80px;
  }

  .scrolled & {
    box-shadow: $nav-shadow;
  }

  &__mock {
    height: $nav-mobile;
    transition: $trs;

    .scrolled & {
      height: $nav-mobile-scrolled;
    }

    @include lg {
      order: 1;
    }
  }

  &__inner {
    align-items: center;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
  }

  &__logo {
    margin-right: auto;

    @include lg {
      order: 2;
      margin-right: 0;
    }
  }

  &__cart-btn {
    border: 2px solid #000;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

     @include lg {
      order: 4;
      margin-left: 92px;
    }

    svg {
      width: 12px;
      height: 12px;
    }

    &:hover {
      border-color: $accent;

      svg {
        fill: $accent;
      }
    }
  }

  &__cart-count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    border: solid 2px #54bae2;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    line-height: normal;
    color: #54bae2;
    border-radius: 50%;
    top: 4px;
    left: -13px;
    padding-top: 2px;
    font-variant-numeric: lining-nums;
  }
}

.mob-menu-btn {
  margin-left: 16px;

  position: relative;
  width: 32px;
  height: 32px;
  border: 2px solid #000;
  border-radius: 50%;

  @include sm {
    margin-left: 32px;
  }

  @include lg {
    display: none;
  }

  .sw-topper,
  .sw-footer,
  .sw-bottom {
    margin: 0 auto;
    display: block;
    position: relative;
    width: 14px;
    height: 2px;
    background-color: #000;
    border: none;
    border-radius: 4px 4px 4px 4px;
  }

  .sw-topper {
    top: -2px;
    transition: transform 0.5s, top 0.2s;
    transition-delay: 0.2s, 0s;
  }

  .sw-bottom {
    top: 0px;
    transition: transform 0.5s, top 0.2s;
    transition-delay: 0.2s, 0s;
  }

  .sw-footer {
    top: 2px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  &.active .sw-topper {
    top: 2px;
    transform: rotate(-45deg);
  }

  &.active .sw-bottom {
    transform: rotate(45deg);
  }

  &.active .sw-footer {
    opacity: 0;
    top: 0;
    transform: rotate(180deg);
  }
}
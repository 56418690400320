.secondary-nav {
  width: 100%;
  display: none;
  position: relative;
  padding: 16px 0;
  
  @include lg {
    transition: $trs;
    display: block !important;
    padding: 0;
    order: -1;

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: -100px;
      right: -100px;
      bottom: 0;
      height: 1px;
      background-color: #c6c6c6;
    }
  }

  >ul {
    height: 100%;

    @include lg {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  >ul>li {
    text-align: center;

    @include lg {
      text-align: left;
      margin-left: 49px;
    }
  }

  >ul>li>a {
    font-family: $ff-primary;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;

    display: inline-block;
    padding: 10px;
    
    &:hover {
      color: $accent;
    }

    @include sm {
      padding: 16px;
    }

    @include lg {
      padding: 0;
      color: #9b9b9b;
    }
  }
}

.secondary-nav {
  @include lg {
    height: $secondary-nav-tablet;
  }

  @include xl {
    height: $secondary-nav-desktop;
  }
}

.scrolled .secondary-nav {
  @include lg {
    height: $secondary-nav-tablet-scrolled;
  }

  @include xl {
    height: $secondary-nav-desktop-scrolled;
  }
}
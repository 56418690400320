.socials {
  display: flex;
  position: relative;

  &__add,
  a {
    display: inline-flex;
    background-color: $grey-light;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    transition: $trs;

    svg {
      fill: #000;
      transition: $trs;

      height: 18px;
      width: 18px;
    }

    &:hover {
      background-color: $accent;
      color: #fff;

      svg {
        fill: #fff;
      }
    }
  }

  &__add {
    font-size: 22px;
    font-weight: bold;
    color: #000000;

  }

  &.active-more &__add {
    color: #fff;
    background-color: #000;
  }

  &__more {
    position: absolute;
    border: solid 10px #fafafa;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 14px;
    padding-bottom: 10px;
    top: calc(100% + 10px);
    z-index: 10;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    
    @include lg {
      top: calc(100% + 18px);
      right: -34px;
      left: auto;
      transform: translateX(0);

      .socials li:first-child {
        margin-left: 0;
      }
    }
  }

  &.active-more &__more {
    display: block;
  }

  &__more-close {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: normal;
    color: $accent;
    margin-top: 15px;
    text-transform: uppercase;
  }
}

.socials--footer {
  margin-top: 20px;

  li {
    margin-right: 20px;
  }
}

.socials--product {
  justify-content: center;

  li {
    margin: 0 10px;
  }

  @include lg {
    justify-content: flex-start;

    li {
      margin: 0;
      margin-left: 20px;
    }
  }
}

.social-link {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;

  svg {
    width: 24px;
    height: 24px;
    border-width: 2px;
    border-style: solid;
    padding: 4px;
    border-radius: 50%;
    margin-right: 12px;
  }

  &--white {
    color: #ffffff;

    svg {
      border-color: #fff;
      fill: #fff;
      transition: $trs;
    }

    &:hover {
      svg {
        border-color: $accent-dark;
        background-color: $accent-dark;
        fill: #fff;
      }
    }
  }

  &--black {
    color: #000;

    svg {
      border-color: #000;
      background-color: #000;
      fill: #fff;
      transition: $trs;
    }

    &:hover {
      svg {
        border-color: $accent;
        background-color: $accent;
        fill: #fff;
      }
    }
  }
}

.share {
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: bold;
  color: #777777;

  .socials {
    a {
      background-color: transparent;
      margin-left: 5px;
      margin-right: 5px;

      &:hover svg {
        fill: $accent;
      }
    }

    svg {
      fill: #4a4a4a;
    }
  }

  &--post {
    margin-top: 26px;
    margin-bottom: 60px;
  }
}
.blog {
  &__item {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 60px;
    }

    @include sm {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__item-img {
    height: 175px;
    display: block;

    img {
      height: 100%;
      width: 100%;

      @include object-fit(cover, center);
    }
  }

  &__item-title {
    display: block;
    font-family: $ff-headers;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 3.3px;
    color: #00000a;
    margin-bottom: 6px;
    margin-top: 20px;

    @include sm {
      font-size: 20px;
      margin-top: 0px;
    }

    @include md {
      font-size: 22px;
    }

    @include lg {
      font-size: 26px;
    }

    @include xl {
      font-size: 30px;
    }

    &:visited {
      color: inherit;
    }

    &:hover {
      color: $accent;
    }
  }

  &__item-date {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.64;
    color: #777777;
    margin-bottom: 10px;
    display: block;

    @include sm {
      font-size: 14px;
    }
  }

  &__item-descr {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: #4a4a4a;
    margin-bottom: 7px;

    @include sm {
      font-size: 14px;
    }
  }

  &__item-footer {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: #777777;
    font-variant-numeric: lining-nums;

    @include sm {
      font-size: 14px;
    }

    a {
      color: inherit;
      &:visited {
        color: inherit;
      }

      &:hover {
        text-decoration: underline;
        color: $accent;
      }
    }
  }

  .cta {
    margin-top: 40px;
    margin-bottom: 40px;

    @include md {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
}
.small-tabs {
  border-bottom: $grey-border;
  margin-bottom: 50px;

  &__nav {
    display: flex;
    justify-content: space-around;

    @include sm {
      justify-content: flex-start;
    }
  }

  &__btn {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    line-height: 2.07;
    color: $accent;
    border-bottom: 3px solid transparent;
    transition: $trs;
    
    @include lg {
      padding-bottom: 5px;
    }

    &:not(:last-child) {
      @include sm {
        margin-right: 50px;
      }
    }

    &.active,
    &:hover {
      color: $text;
      border-color: #676767;
    }

    @include sm {
      font-size: 14px;
    }
  }
}
.login {
    background: rgba(#f9f9f9, 0.8);
    padding: 41px 0 100px;
    &__inner {
        background: white;
    }
    .tabs-nav {
        margin: 0 0 30px;
        &__item {
            width: 50%;
            text-align: center;
            padding: 0;
            &.active {
                border-top: 1px solid transparent;
            }
        }
        &__btn {
            padding: 20px 15px;
            font-size: 22px;
            letter-spacing: 4px;
            font-weight: 400;
           
        }
    }
    .form-elements__label {
        margin-bottom: 20px;
    }
    form {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        padding: 0 10px 40px;
    }
    .tab-content {
        margin: 0;
    }
    .form-customer {
        form {
            padding-bottom: 27px;
        }
        &__info {
           padding: 20px 15px 0;
           margin-bottom: -33px;
           line-height: 1.58;
           font-size: 12px;
           font-weight: 500;
           color: #9b9b9b;
           background: rgba(#f9f9f9, 0.8);
        }
    }
    &.my-account {
        padding-bottom: 139px;
        .form-customer__info {
            margin-bottom: -71px;
        }
        .tab-content[data-tab-id="ex-account"] form {
            padding-bottom: 52px;
        }
    }
}
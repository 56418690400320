.map {
  padding-bottom: 30px;
  margin-top: 30px;

  @include sm {
    padding-bottom: 120px;
  }

  @include md {
    margin-top: 64px;
  }

  &__search-box {
    margin-bottom: 20px;
    position: relative;

    @include md {
      margin-bottom: 40px;
    }

    @include lg {
      max-width: 610px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: 18px;
      width: 16px;
      height: 16px;
      background: url(../img/icons/icon-search.svg) center/cover no-repeat;
    }

    label {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: normal;
      color: $dark-light;
      text-transform: uppercase;
      display: block;
      margin-bottom: 10px;

      @include md {
        margin-bottom: 15px;
      }
    }

    input {
      font-family: inherit;
      height: 50px;
      border: solid 1px #676767;
      width: 100%;
      padding-left: 20px;
      font-size: 14px;
      color: $dark;

      &:focus {
        border-color: $accent;
      }
    }
  }

  &__map {
    height: 380px;
    margin-bottom: 20px;

    @include md {
      height: 450px;
    }

    @include xl {
      height: 520px;
    }
  }

  &__note {
    border-top: 1px solid #E8E8E8;
    padding-top: 16px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.79;
    color: $text;

    a,
    a:visited {
      color: $accent;
      display: block;

      &:hover {
        color: $accent-dark;
      }

      @include lg {
        display: inline;
      }
    }
  }
}
.post {

  &__img-wrapper {
    background-color: #fafafa;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    @include md {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    @include sm-max {

      .container,
      [class^=col] {
        padding: 0;
      }
    }

    img {
      height: 250px;
      width: 100%;

      @include object-fit(cover, center);
    }
  }

  &__info {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: #777777;
    font-variant-numeric: lining-nums;
    margin-bottom: 30px;

    @include sm {
      font-size: 14px;
    }

    b {
      display: block;
      margin-bottom: 8px;

      @include sm {
        margin-right: 20px;
        display: inline;
        margin-bottom: 0;
      }
    }


    a {
      color: inherit;

      &:hover {
        color: $accent;
      }
    }
  }

  &__content {
    font-size: 12px;
    line-height: 1.64;
    color: #4a4a4a;
    margin-bottom: 50px;

    @include sm {
      font-size: 14px;
    }

  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
  }

  &__categories-item {
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 15px;
    border: solid 1px #9b9b9b;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 2.3px;
    color: #9b9b9b;
    text-transform: uppercase;
    margin-right: 7px;
    margin-left: 7px;
    margin-bottom: 14px;

    &:hover {
      color: $accent;
      border-color: $accent;
    }

    @include sm {
      font-size: 14px;
      height: 40px;
    }

    @include md {
      margin-right: 15px;
      margin-left: 15px;
      margin-bottom: 30px;
      min-width: 150px;
      height: 50px;
    }

    @include lg {
      min-width: 170px;
    }
  }
}
.checkout {

  &-step {
    width: 230px;
    height: 3px;
    background: #9b9b9b;
    margin: -4px auto 77px;
    position: relative;

    @include sm {
      width: 315px;
    }

    &__item {
      width: 16px;
      height: 16px;
      background: white;
      display: block;
      position: absolute;
      background: #9b9b9b;
      border-radius: 50%;
      border: 1px solid white;
      top: -7px;

      &:first-child {
        left: -7px;
      }

      &:nth-child(2) {
        left: 50%;
        transform: translateX(-50%);
      }

      &:last-child {
        right: -7px;
      }

      &.checkout-step-active {
        background: #00000a;

        .checkout-step__name {
          color: #00000a;
        }
      }
    }

    &__name {
      position: absolute;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      top: 31px;
      left: 50%;
      transform: translateX(-50%);
      color: #9b9b9b;
    }

    &.active {
      &:before {
        display: block;
        position: absolute;
        content: "";
        height: 3px;
        width: 50%;
        background: #00000a;
        left: 0;
        top: 0;
      }
    }
  }

}

.bs-details {
  &.cart {
    padding-bottom: 70px;

    .cart-product {
      padding: 40px 20px;

      @include sm {
        padding: 40px 30px;
      }

      @include xl {
        padding: 40px;
      }
    }
  }

  .form-elements__input {
    @include md {
      max-width: 420px;
    }
  }

  .input-group-full {
    &:last-child {
      margin-bottom: 0;
    }

    .form-elements__input {
      max-width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__info {
    font-size: 12px;
    font-weight: 500;
    color: #9b9b9b;
    padding: 13px 0 7px;
  }
}

.order-products {
  &__label {
    font-size: 12px;
    font-weight: 500;
    color: #9b9b9b;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid #eeeeee;
  }

  &__edit {
    color: $accent;

    &:hover &:focus {
      text-decoration: underline;
    }
  }

  &__inner {
    padding: 24px 0 40px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
  }

  &__image {
    width: 80px;
    border: 1px solid #eeeeee;
    align-self: flex-start;
  }

  &__desc {
    width: calc(100% - 100px);
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: #00000a;
    line-height: 1.36;
    margin: 0 0 5px;
  }

  &__info {
    font-size: 12px;
    font-weight: 500;
    color: #00000a;
  }
}

.payment {
  &__label {
    text-transform: none;
    color: #9b9b9b;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin: 5px 0 0;

    @include sm {
      margin: 0 0 0 21px;
      display: inline-block;
    }
  }

  &__method {
    margin-bottom: 38px;

    input {
      display: none;
    }

    label {
      display: block;
      margin-bottom: 10px;

      @include md {
        margin-right: 60px;
        margin-bottom: 0;
        display: inline-block;
      }

      &:last-child {
        margin: 0;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: transparent;
      border-radius: 4px;
      border: solid 1px #f2f2f2;
      cursor: pointer;
      padding: 20px;
      color: #9b9b9b;
      font-size: 14px;
      font-weight: bold;

      @include md {
        width: 230px;
      }

      img {
        margin-right: 20px;
        opacity: 0.8;
      }
    }

    input[type="radio"]:checked+.payment__method-item {
      background-color: #f9f9f9;
      border: solid 1px #f9f9f9;
      color: #00000a;

      img {
        opacity: 1;
      }
    }
  }

  &__name {
    span {
      color: #9b9b9b;
    }
  }

  .input-group {
    margin-bottom: 35px;
  }

  .form-elements__input {
    max-width: 428px;
  }

  &__content {
    font-size: 12px;
    font-weight: 500;
    color: #9b9b9b;
    line-height: 1.58;
  }
}

.credit {
  border-radius: 14px;
  background-color: #f5f5f5;
  padding: 32px 16px 24px;
  position: relative;
  width: 100%;
  max-width: 428px;

  * {
    font-variant-numeric: lining-nums;
  }

  @include sm {
    padding: 32px 40px 24px;
  }

  &__image {
    position: absolute;
    right: 20px;
    top: 32px;
    width: 60px;

    @include sm {
      width: 80px;
      right: 40px;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: bold;
    color: #00000a;
    margin: 0 0 24px;
    text-transform: uppercase;
  }

  &__label {
    font-size: 12px;
    font-weight: normal;
    color: #777777;
    width: 100%;
    margin: 0 0 4px;
    display: block;
  }

  &__number {
    margin: 0 0 30px;

    input {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #00000a;
      border-bottom: solid 1px #d5d5d5;
      height: 28px;
      display: flex;
      align-items: center;
      max-width: 270px;
      width: 100%;
      background: transparent;
      letter-spacing: 0.5px;

      &::placeholder {
        color: #00000a;
      }

      @include sm {
        font-size: 19px;
      }
    }
  }

  &__expiration {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 18px;

    @include sm {
      margin-bottom: 9px;
    }

    &-item {
      @include sm {
        min-width: 90px;
      }

      input {
        width: 27px;
        font-size: 14px;
        font-weight: 500;
        color: #00000a;
        background: transparent;
        border-bottom: solid 1px #d5d5d5;
        height: 20px;

        &::placeholder {
          color: #00000a;
        }
      }

      select {
        background: transparent;
        border-bottom: solid 1px #d5d5d5;
        height: 20px;
        width: 27px;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }

  &__link {
    text-align: right;
    padding-right: 10px;

    a {
      color: $accent;
      font-size: 12px;
      font-weight: 500;
      line-height: 2.42;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &-wrap-select {
    position: relative;
    display: inline-block;
    margin-right: 15px;
    padding-right: 17px;

    @include sm {
      margin-right: 37px;
      padding-right: 25px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:before {
      display: block;
      position: absolute;
      background-image: url(../img/icons/select-arr.svg);
      background-repeat: no-repeat;
      background-size: 11px 13px;
      content: "";
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;

      @include sm {
        background-size: 14px 15px;
      }
    }
  }

  &.credit-data {
    input {
      border-bottom: 0;
    }

    select {
      pointer-events: none;
      border: 0;
    }

    .credit-wrap-select {
      &:before {
        display: none;
      }
    }
  }
}

.form-separate {
  margin: 44px 0 24px;
  height: 1px;
  background: #eeeeee;
}
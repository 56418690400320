.account {
    background: rgba(#f9f9f9, 0.8);
    padding: 24px 0 44px;
    @include lg {
        padding: 40px 0 80px;
    }
    &__title {
        .page-title {
            font-weight: bold;
            margin-bottom: 0;
            @include lg {
                margin-bottom: 4px;
            }
        }
    }
    &__subtitle {
        font-size: 14px;
        font-weight: 500;     
        color: #4a4a4a;
        line-height: 1.36;
        margin: 0 0 16px;
    }
    &-separate {
        margin: 40px 0 30px;
        height: 1px;
        background: #eeeeee;
        &.account-separate-40 {
            margin: 40px 0;
        }
        &.account-separate-mb-0 {
            margin-bottom: 0;
        }
    }
    .container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include lg {
            flex-wrap: nowrap;
        }
    }
    &-list {
        width: 100%;
        @include lg {
            max-width: 293px;
            padding-right: 20px;
        }
        &__mobile {
            display: flex;
            align-items: center;
            padding: 10px 0;
            justify-content: space-between;
            border-top: 1px solid #d6d6d6;
            border-bottom: 1px solid #d6d6d6;
            margin-bottom: 30px;
            @include lg {
                display: none;
            }
        }
        &__title {
            font-size: 24px;
            font-weight: normal;
            letter-spacing: 2px;
            color: #000000;
            margin: 0;
            width: calc(100% - 35px);
        }
        img {
            width: 30px;
            height: 30px;
        }
        &.active {
            img {
                transform: rotate(180deg);
            }
        }
    }
    &-menu {
        display: none;
        min-width: 100%;
        width: 100%;
        padding-bottom: 30px;
        @include lg {
            display: block;
            padding: 0;
        }
        &__item {
            width: 100%;
            margin: 0 0 24px;
            &.account-menu__item-current {
                .account-menu__link {
                    color: $accent;
                }
            }
        }
        &__link {
            font-size: 18px;
            font-weight: normal;
            font-family: "BebasNeue", sans-serif;
            letter-spacing: 2px;
            color: #00000a;
            &:hover {
                color: $accent;
            }
        }
    }
    &-submenu {
        margin: 19px 0 -3px;
        &__link {
            line-height: 1.33;
            display: block;
            color: #00000a;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            padding-left: 16px;
            position: relative;
            &:before {
                content: '';
                width: 7px;
                height: 7px;
                border-top: solid 1px #00000a;
                border-right: solid 1px #00000a;
                position: absolute;
                left: 0;
                top: 46%;
                transform: translateY(-50%) rotate(45deg);
            }
            &:hover {
                color: $accent;
                &:before {
                    border-top: solid 1px $accent;
                    border-right: solid 1px $accent;
                }
            }
        }
        &__item {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            &.account-submenu__item-current {
                .account-submenu__link {
                    color: $accent;
                    &:before {
                        border-top: solid 1px $accent;
                        border-right: solid 1px $accent;
                    }
                }
            }
        }
    }
    &-wrap {
        background: white;
        width: 100%;
        padding: 40px 20px;
        position: relative;
        @include sm {
            padding: 40px;
        }
        @include lg {
            max-width: 750px;
        }
        &__inner {
            @include md {
                min-height: 340px;
            }
        }
        &__btn  {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            .accout-cancel {
                width: 100%;
                margin-bottom: 15px;
                @include sm {
                    width: calc(41% - 5px);
                    margin: 0;
                }
                @include md {
                    width: auto;
                }
            }
            .accout-submit {
                width: 100%;
                @include sm {
                    margin-left: 9px;
                    width: calc(59% - 5px);
                }
                @include md {
                    margin-left: 30px;
                    width: auto;
                }
                &.cta--40 {
                    @include md {
                        margin-left: 20px;
                        width: auto;
                    }
                }
            }
          
        }
        .email {
            &-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                &.password-row {
                    align-items: flex-start;
                }
            }
           
            &-column {
                width: 100%;
                max-width: 357px;
                margin: 0 auto;
                padding-top: 10px;
                @include md {
                    width: calc(50% - 20px);
                    max-width: auto;
                    margin: 0;
                    padding: 0;
                }
            }
            &__varify {
                font-size: 14px;
                font-weight: 500;
                line-height: 2.07;
                color: $accent;
                display: inline-block;
                margin-bottom: 10px;
            }
            &__info {
                font-size: 12px;
                font-weight: 500;
                color: #9b9b9b;
            }
        }
        .password-row {
            .email__info {
                @include md {
                    padding-top: 27px;
                }
            }
            .payment__name {
                padding-top: 12px;
            }
        }
        &.account-wrap-min {
            @include md {
                min-height: 571px;
            }
        }
        &__buttons {
            position: absolute;
            top: 30px;
            right: 40px;
            text-align: right;
            @include lg {
                top: 40px;
            }
            &-item {
                font-size: 16px;
                font-weight: 500;
                color: $accent;
                letter-spacing: 1px;
                align-items: center;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-bottom: 12px;
                @include xl {
                    margin-bottom: 18px;
                }
                img {
                    margin-right: 7px; 
                    transform: rotate(-45deg);  
                }
                &:hover {
                    color: $accent-dark;
                }
            }
        }
        &__remove {
            font-size: 16px;
            font-weight: 500;
            color: $accent;
            letter-spacing: 1px;
            display: none;
            align-items: center;
            text-transform: uppercase;
            img {
                margin-right: 16px;   
            }
            &.active {
                display: inline-flex;
            }
        }
    }
}
.commun {
    &__title {
        color: #00000a;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0 0 16px;
    }
    &__info {
        font-size: 14px;
        font-weight: 500;
        color: #676767;
        line-height: 1.5;
        margin: 0 0 23px;
    }
}

.accout-review {
    &__title {
        font-size: 12px;
        font-weight: bold;
        color: #00000a;
        text-transform: uppercase;
        margin: 0 0 24px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        td {
            border-top: 1px solid #eeeeee;
            border-bottom: 1px solid #eeeeee;
            padding: 17px 8px;
            color: #00000a;
            @include sm {
                padding: 17px 18px;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                text-align: right;
            }
            a {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
                color: $accent;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

        }
    }
    &__num {
        font-size: 12px;
        font-weight: 500;
    }
    &__name {
        text-transform: uppercase;
        line-height: 1.58;
        font-size: 12px;
        font-weight: bold;
    }
    &__text {
        font-style: italic;
        font-size: 14px;
        font-weight: normal;
    }
    .account-separate {
        margin: 6px 0;  
        font-size: 12px;
        font-weight: bold;
    }
}
.address-wrap {
    padding-top: 70px;
    @include xl {
        padding-top: 40px;
    }
    .form-elements__input {
        @include md {
            max-width: 420px;
        }
    }
    .checkbox-wrap {
        padding: 10px 0;
    }
}
.payment-wrap {
    padding-top: 100px;
    @include xl {
        padding-top: 40px;
    }
    .form-elements__input {
        @include md {
            max-width: 420px;
        }
    }
    .checkbox-wrap {
        padding: 35px 0 0;
    }
}

.payment__info {
    width: 100%;
    &-title {
        font-size: 12px;
        font-weight: bold;
        color: #777777;
        margin: 0 0 26px;
        text-transform: uppercase;
    }
    table {
        margin-bottom: 5px;
        width: 100%;
        max-width: 600px;
    }
    td {
        font-size: 14px;
        font-weight: 500; 
        line-height: 2.07;
        padding: 2px 5px;
        color: #00000a;
        &:first-child {
            padding-left: 0;
            color: #9b9b9b;
        }
    }
}

.gift {
    @include md {
        min-height: 433px;
    }
    &__title {
        text-transform: uppercase;
        margin: 0 0 13px;
        font-size: 12px;
        font-weight: bold;
        color: #00000a;
    }
    &__inner {
        border-radius: 6px;
        background-color: #f5f5f5;
        padding: 14px 19px 19px;
        display: inline-block;
        min-width: 250px;
        margin-bottom: 42px;
        
        p {
            font-size: 12px;
            color: #777777;
            margin: 0 0 8px;
            line-height: 17px;
        }
    }
    &__balance {
        font-size: 20px;
        font-weight: bold;
        color: #00000a;
        margin: 0;
        line-height: 28px;
    }
    &__desc {
        &-title {
            font-size: 12px;
            font-weight: bold;
            color: #777777;
            text-transform: uppercase;
            margin: 0 0 16px;
        }
        &-info {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            color: #676767;
            margin: 0 0 29px;
            max-width: 653px;
        }
        input {
            max-width: 250px;
        }
    }
}

.wish-list {
    &__title {
        font-size: 12px;
        font-weight: bold;
        color: #00000a;
        margin: 0 0 29px;
        text-transform: uppercase;
    }
    &__table {
        width: 100%;
        border-collapse: collapse;
        th {
            border-bottom: 1px solid #eeeeee;
            font-size: 11px;
            font-weight: 500;
            color: #9b9b9b;
            padding: 0 4px 14px;
            text-align: left;
            @include sm {
                padding: 0 7px 14px;
                font-size: 12px;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                text-align: right;
            }
        }
        td {
            border-bottom: 1px solid #eeeeee;
            padding: 20px 4px;
            font-size: 12px;
            font-weight: 500;
            color: #00000a;
            @include sm {
                padding: 20px 7px;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                text-align: right;
            }
        }
    }
    &__prod {
        display: flex;
        flex-wrap: wrap;
        @include sm {
            flex-wrap: nowrap;
        }
    }
    &__image {
        margin: 0 0 15px;
        @include sm {
            margin: 0 10px 0 0;
        }
        @include md {
            margin: 0 20px 0 0;
        }
        img {
            border: 1px solid #eeeeee;
            width: 65px;
            @include sm {
                width: 80px;
            }
            @include md {
                width: 110px;
            }
        }
      
    }
    &__desc {
        width: 100%;
        @include md {
            width: auto;
        }
        a {
            color: $accent;
            &:hover,
            &:focus {
                text-emphasis: underline;
            }
        }
    }
    &__name {
        font-size: 13px;
        font-weight: 600;
        line-height: 1.36;
        color: #00000a;
        margin: 0 0 5px;
        @include sm {
            font-size: 14px;
        }
    }
    &__subname {
        font-size: 12px;
        line-height: 1.58;
        font-weight: 500;
        margin: 0 0 8px;
    }
    &__price {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.19;
        letter-spacing: 1px;
        color: #00000a;
        @include sm {
            font-size: 16px;
        }
    }
}


.order-history {
    .wish-list__title {
        margin-bottom: 12px;
    }
    &__inner {
        background: rgba(#f9f9f9, 0.8);
        display: flex;
        padding: 15px 17px;
        align-items: center;
        margin-bottom: 39px;
        flex-wrap: wrap;
        @include sm {
            padding: 13px 29px;
            flex-wrap: nowrap;
         }
        &-title {
            font-size: 14px;
            font-weight: 500;
            display: inline-block;
            color: #676767;
            margin: 0 0 10px;
            width: 100%;
            @include sm {
               width: auto;
               margin: 0 20px 0 0;
            }
        }
    }
    &__sort {
        height: 34px;
        background-color: transparent;
        border: solid 1px #676767;
        margin: 0 0 10px 0;
        font-size: 14px;
        font-weight: 500;
        color: #676767;
        display: inline-flex;
        padding: 4px 17px ;
        width: 100%;
        background-image: url(../img/icons/select-arr.svg);
        background-repeat: no-repeat;
        background-size: 11px 13px;
        background-position: 90% 10px;
        &:last-child {
            margin: 0;
        }
        @include sm {
            max-width: 151px;
            margin: 0 20px 0 0;
         }
    }
    &__buttons {
        display: flex;
        padding: 23px 0 24px;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        flex-wrap: wrap;
        justify-content: space-between;
        @include sm {
           flex-wrap: nowrap;
           justify-content: flex-start;
        }
        .cta {
            letter-spacing: 0.8px;
            font-size: 12px;
            font-weight: 500;
            width: calc(50% - 5px);
            margin: 0 0 10px 0;
            @include sm {
                width: auto;
                margin: 0 15px 0 0;
            }
            @include md {
                margin: 0 20px 0 0;
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    &-separate {
        height: 1px;
        background: #eeeeee;
        margin: 4px 0 40px;
        @include xl {
            margin: 4px 0 154px;
        }
    }
}
.order-cards {
    .wish-list__title {
        margin-bottom: 12px;
    }
    &__inner {
        border-top: 1px solid #eeeeee;
        padding: 30px 0 12px;
    }
    &__number {
        font-size: 12px;
        font-weight: bold;
        color: $accent;
        display: inline-block;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    .wish-list__table {
        td {
            border: 0;
        }
    }
    .tabs-nav {
        margin: 0 0 20px;
        border-bottom: 1px solid #eeeeee;
        &__item {
            margin: 0 15px 0 0;
            padding: 0;
            border: 0;
            @include sm {
                margin: 0 30px 0 0;
            }
            @include xl {
                margin: 0 50px 0 0;
            }
            &:last-child {
                margin: 0;
            }
            &:first-child {
                text-align: left;
                flex-grow: inherit;
            }
            &.active {
                border: 0;
                .tabs-nav__btn {
                    border-bottom: 3px solid $accent;
                    color: $accent;
                    
                }
            }
        }
        &__btn {
            padding: 8px 0 12px;
            position: relative;
            border: 0;
            border-bottom: 3px solid transparent;
            font-size: 11px;
            font-weight: 500;
            font-family: "TTWellingtons", sans-serif;
            color:  #00000a;
            letter-spacing: inherit;
            transition: all 0.2s ease;
            @include sm {
                font-size: 12px;
            }
            &:hover {
                border-bottom: 3px solid $accent;
                color: $accent;
            }
        }
    }
    .tabs-nav__item.active:not(:first-child):not(:last-child) {
        border: 0;
    }
    .tab-content {
        padding-bottom: 30px;
        margin: 0;
        p {
            font-size: 12px;
            font-weight: 500;
            color: #676767;
            line-height: 1.58;
        }
        
    }

}

.footer {
  margin-top: auto;
  padding-top: 25px;
  padding-bottom: 40px;
  background-color: #181818;

  &__col-title {
    margin-top: 27px;
    display: block;
    font-family: $ff-headers;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 4px;
    color: #ffffff;
    margin-bottom: 11px;

    @include sm {
      font-size: 18px;
    }
  }

  &__nav-list {
    a {
      color: $dark-light;
      line-height: 2.6;
      font-size: 12px;
      font-weight: bold;

      &:hover {
        color: $accent;
      }
    }
  }

  &__nav-list + &__col-title {
    margin-top: 47px;
  }

  &__register {
    margin-top: 20px;
    max-width: 174px;
  }

  &__logo {
    display: block;
    margin-top: 61px;
  }

  &__copyright {
    font-size: 12px;
    text-align: center;
    color: #797979;
    margin-bottom: 20px;
    line-height: 1.5;

    @include lg {
      text-align: left;
      margin-bottom: 0;
    }

    a {
      color: #797979;
      text-decoration: underline;

      &:hover {
        color: $accent;
      }
    }
  }

  &__bottom-links {
    text-align: center;
    font-size: 12px;
    color: #797979;
    line-height: 1.5;

    @include lg {
      text-align: right;
    }
    
    a {
      color: #797979;
      margin: 0 3px;

      &:hover {
        color: $accent;
      }
    }
  }

  hr {
    margin-bottom: 25px;
    margin-top: 50px;

    height: 1px;
    background-color: #2d2d2d;

    @include lg {
      margin-top: 60px;
    }
  }
}
.lab-preview {
  padding: 80px 0;
  position: relative;
  overflow: hidden;

  @include sm {
    padding: 104px 0;
  }

  @include lg {
    padding: 95px 0 140px;
  }

  @include xl {
    padding-top: 150px;
    padding-bottom: 167px;
    margin-left: -15px;
  }

  &__inner {
    max-width: 320px;

    @include sm {
      width: 510px;
      max-width: none;
      margin: 0 auto;
    }

    @include lg {
      width: 790px;
      padding-right: 290px;
    }

    @include xl {
      width: auto;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 10px;

    @include sm {
      margin-bottom: 14px;
    }
  }

  &__sub-title {
    color: #bbb;
    margin-bottom: 50px;

    @include sm {
      margin-bottom: 60px;
    }
  }

  &__img {
    position: absolute;
    left: 190px;
    bottom: 50px;
    z-index: -1;
    height: 150px;

    @include rwd(414) {
      left: 220px;
      height: 230px;
    }

    @include sm {
      top: 155px;
      bottom: auto;
      left: auto;
      height: 270px;
      right: -140px;
    }

    @include rwd(660) {
      right: -190px;
      height: 330px;
      top: 100px;
    }

    @include md {
      right: -210px;
      height: 400px;
      top: 60px;
    }

    @include lg {
      top: 42px;
      right: -190px;
      height: 434px;
    }

    @include xl {
      top: 48px;
      right: -40px;
    }
    
    @include rwd(1440) {
      right: 0;
    }
  }

  .cta {
    padding-left: 40px;
    padding-right: 40px;

    @include xl {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}
.events-list {
  padding-top: 30px;
  padding-bottom: 60px;

  @include md {
    padding-top: 60px;
  }

  &__grid {
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 60px;
  }

  &__item {
    max-width: 414px;
    margin: 0 auto 50px;

    @include sm {
      max-width: none;
      width: 100%;
    }

    @include md {
      margin-bottom: 60px;
    }
  }

  &__item-img {
    height: 238px;
    width: 100%;

    @include object-fit(cover, center);

    @include md {
      height: 145px;
    }

    @include xl {
      height: 175px;
    }
  }

  &__item-content {
    padding-top: 30px;

    @include md {
      padding-top: 0;
    }
  }

  &__item-title {
    font-family: $ff-headers;
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 2.9px;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 8px;
    display: block;

    @include sm {
      font-size: 26px;
    }

    @include lg {
      font-size: 30px;
      letter-spacing: 3.3px;
    }

    &:hover {
      color: $accent;
    }
  }

  &__item-date {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.64;
    color: #777777;
    display: block;
    font-variant-numeric: lining-nums;
    margin-bottom: 10px;
  }

  &__item-descr {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.64;
    color: $text;
    margin-bottom: 20px;
  }
}
.press {
  &__item {
    margin-bottom: 25px;

    @include md {
      margin-bottom: 48px;
    }
  }

  &__item-img {
    height: 240px;
    display: block;

    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover, center);
    }
  }

  &__item-title {
    display: block;
    font-family: $ff-headers;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 3.3px;
    color: #00000a;
    margin-bottom: 6px;
    margin-top: 20px;

    @include sm {
      font-size: 20px;
    }

    @include md {
      font-size: 22px;
    }

    @include lg {
      font-size: 26px;
    }

    @include xl {
      font-size: 30px;
    }

    &:visited {
      color: inherit;
    }

    &:hover {
      color: $accent;
    }
  }

  &__item-date {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.64;
    color: #777777;
    margin-bottom: 10px;
    display: block;

    @include sm {
      font-size: 14px;
    }
  }

  &__item-descr {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: #4a4a4a;
    margin-bottom: 7px;

    @include sm {
      font-size: 14px;
    }
  }

  &__share {
    display: flex;
  }

  .cta {
    margin-top: 40px;
    margin-bottom: 40px;

    @include md {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
}
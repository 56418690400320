html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

button {
  background-color: transparent;
  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  position: relative;
  font-family: $ff-primary;
  background: #fff;
  min-width: 320px;
  color: $text;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-top: $nav-mobile;
  transition: padding-top 0.2s ease;

  &.scrolled {
    padding-top: $nav-mobile-scrolled;
  }

  @include lg {
    padding-top: $nav-tablet + $secondary-nav-tablet;

    &.scrolled {
      padding-top: $nav-tablet-scrolled + $secondary-nav-tablet-scrolled;
    }
  }

  @include xl {
    padding-top: $nav-desktop + $secondary-nav-desktop;

    &.scrolled {
      padding-top: $nav-desktop-scrolled + $secondary-nav-desktop-scrolled;
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border: 2px solid #fff;
    border-radius: 8px;
  }
}

input,
textarea {
  box-shadow: none;
}

::placeholder {
  opacity: 1;
  color: #777777;
  line-height: inherit;
}

:-ms-input-placeholder {
  color: #777777;
  line-height: inherit;
}

::ms-input-placeholder {
  color: #777777;
  line-height: inherit;
}
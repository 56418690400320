.input-group {
  display: block;
  margin: 0 0 20px;
}

.form-elements__label {
  font-size: 12px;
  color: #777777;
  font-weight: bold;
  display: block;
  margin: 0 0 10px;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
}

.input-group .form-elements__input:last-child {
  margin: 0;
}

.form-elements__input,
.form-elements__textarea,
.form-elements__select {
  width: 100%;
  display: block;
  border: 1px solid #676767;
  padding: 15px 18px;
  color: #000000;
  font-size: 14px;
  font-weight: normal;

  &::placeholder {
    color: #777777;
  }

  &:disabled {
    opacity: 0.3;
  }

  &:focus {
    border: 1px solid #54bae2;
  }
}

.form-elements__select {
  appearance: none;
  -webkit-appearance: textfield;
  font-weight: 500;
  background-image: url(../img/icons/select-arr.svg);
  background-repeat: no-repeat;
  background-size: 14px 15px;
  background-position: 92% 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 18px;
  margin-bottom: 20px;

  &::placeholder {
    color: #00000a;
    font-weight: 500;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.form-elements__textarea {
  resize: none;
  height: 100px;
}

body .input-group-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .form-elements__input,
  .form-elements__select {
    width: 100%;

    @include md {
      max-width: 35%;
      margin-right: 20px;
      margin-bottom: 0;
    }

    @include xl {
      max-width: 36%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

body .input-group-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .form-elements__input,
  .form-elements__select {
    width: 100%;
    margin-bottom: 20px;

    @include md {
      max-width: 35%;
    }

    @include xl {
      max-width: 36%;
    }

    &:last-child {
      margin-right: 0;

      @include md {
        max-width: 22%;
      }
    }
  }
}






.checkbox-wrap {
  cursor: pointer;

  input[type="checkbox"] {
    display: none;
  }
}

.checkbox__inner {
  display: inline-flex;
  align-items: center;
  height: 21px;
  position: relative;
  padding: 0 0 0 39px;
  font-size: 14px;
  color: #00000a;

  &:before {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
    content: "";
    border: solid 1px #676767;
  }
}

.checkbox-wrap input[type="checkbox"]:checked+.checkbox__inner:after {
  display: block;
  position: absolute;
  content: "";
  left: 7px;
  top: 44%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  width: 7px;
  height: 13px;
  border-bottom: 2px solid #676767;
  border-right: 2px solid #676767;
}

.form-submit__full {
  margin-bottom: 20px;

  .form-elements__submit {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.forgot-password {
  margin: 0 0 20px;

  a {
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: $accent;
    transition: 0.3s ease;
    line-height: 2.42;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  p {
    font-size: 12px;
    color: #777777;
    margin: 0;
    padding: 5px 0;
  }
}

.input-group--password {
  position: relative;

  .show-password {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
    font-weight: 500;
    color: $accent;
    transition: 0.3s ease;
    text-transform: uppercase;
    right: 22px;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $accent-dark;
    }
  }
}

.wrap-number {
  // width: 58px;
  margin: 23px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
    font-size: 20px;
    letter-spacing: 1.3px;
    color: #00000a;
    border: 0;
    width: 36px;
    padding: 0;
    margin-bottom: 0;
    text-align: center;
    height: auto;
    line-height: 1;
    font-family: $ff-headers;
    font-weight: normal;
    user-select: none;
  }

  .number-minus,
  .number-plus {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    text-align: center;
    line-height: 15px;
    cursor: pointer;
    border: solid 1px #676767;
    font-size: 16px;
    color: #676767;
    border-radius: 50%;
    user-select: none;

    &:hover {
      border-color: $accent;
      color: $accent;
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="submit"],
button[type="submit"] {
  cursor: pointer;
}

select {
  appearance: textfield;
}

.input-group.input-group-full {
  input {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
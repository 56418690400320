hr.accent {
  height: 10px;
  background-color: $accent;
}

hr.grey {
  height: 10px;
  background-color: #fafafa;
}

.section-text {
  font-family: $ff-primary;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: $text;

  @include lg {
    font-size: 16px;
    line-height: 1.56;
  }
}
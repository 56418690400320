.shop-putter-preview {
  text-align: center;
  padding-bottom: 50px;
  border: 1px solid #f3f3f3;
  margin-bottom: 11px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: calc(100% - 11px);

  @include sm {
    max-width: none;
  }

  @include lg {
    margin-bottom: 30px;
    height: calc(100% - 30px);
  }

  @include xl {
    padding-left: 30px;
    padding-right: 30px;
  }

  &__img {
    height: 200px;
    width: 100%;
    margin-bottom: 40px;

    @include object-fit(cover, center);

    @include xl {
      height: 228px;
      margin-bottom: 50px;
    }
  }

  &__title {
    font-family: $ff-headers;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: 4px;
    color: #000000;
    padding: 0 10px;
    display: block;

    @include xl {
      padding: 0;
    }
  }

  &__descr {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    text-align: center;
    color: $text;
    padding: 0 15px;
    margin-bottom: 12px;
    max-width: 100%;

    @include lg {
      margin-bottom: 30px;
      padding: 0 10px;
    }

    @include xl {
      padding: 0;
    }
  }

  .cta {
    margin-top: auto;
    width: 150px;
  }
}

.shop-category-item {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  height: 150px;
  padding-top: 30px;
  align-items: center;
  text-align: center;

  font-size: 12px;
  font-weight: 500;
  color: #000000;
  transition: $trs;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

  @include sm {
    padding-top: 53px;
    height: 240px;
    font-size: 16px;
  }

  @include md {
    margin-bottom: 30px;
  }

  svg {
    height: 50px;
    margin-bottom: 20px;
    transition: $trs;
    max-width: 100%;
    fill: #000;

    @include sm {
      margin-bottom: 38px;
      height: 75px;
    }
  }

  &:hover {
    background-color: #000000;
    color: $accent;

    svg {
      fill: #fff;
    }
  }
}

.shop-subcategory-item {
  margin-top: 25px;
  display: flex;
  flex-direction: column;

  @include md {
    margin-top: 30px;
  }

  &__img {
    height: 200px;
    text-align: center;
    flex-shrink: 0;

    img {
      height: 100%;
    }
  }

  &__descr {
    padding: 16px 5px;
    background-color: #FAFAFA;
    text-align: center;
    transition: $trs;
    flex-grow: 1;
    min-height: 110px;

    @include xl {
      padding: 20px 5px;
    }
  }

  &:hover &__descr {
    background-color: #000;
  }

  &__title {
    font-family: $ff-headers;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 4px;
    color: #000000;
    margin-bottom: 16px;
    display: block;
    transition: $trs;
  }

  &:hover &__title {
    color: $accent;
  }

  &__text {
    font-size: 12px;
    font-weight: 500;
    color: $text;
    transition: $trs;
  }

  &:hover &__text {
    color: #fff;
  }
}

.shop-section-header {
  margin-top: 50px;
  margin-bottom: 30px;

  h3 {
    font-size: 20px;

    @include sm {
      font-size: 36px;
    }
  }
}

.shop-item {
  margin-bottom: 50px;
  margin-top: 10px;

  &__img {
    height: 200px;
    background-color: #fdfdfd;
    text-align: center;
    margin-bottom: 30px;
    display: block;

    img {
      width: 200px;
      height: 100%;

      @include object-fit(cover, center);
    }

    @include sm {
      height: 262px;

      img {
        width: 262px;
      }
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 11px;
    display: block;

    &:hover {
      color: $accent;
    }

    @include sm {
      font-size: 18px;
    }
  }

  &__descr {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.56;
    color: #000;
    font-variant-numeric: lining-nums;
    margin-bottom: 30px;

    @include sm {
      font-size: 16px;
    }

    @include lg {
      margin-bottom: 40px;
    }

    small {
      font-size: 16px;
      color: #9b9b9b;
    }
  }

  &__footer {
    display: flex;
  }

  &__price-block {
    background-color: #FAFAFA;
    flex-grow: 1;
    text-align: center;
    position: relative;
  }

  &__current-price {
    font-family: $ff-headers;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 3.3px;
    color: #000000;
    line-height: 40px;

    @include sm {
      font-size: 22px;
    }
  }

  &__old-price {
    font-family: $ff-headers;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 2.5px;
    color: #9b9b9b;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 150px;

    @include sm {
      font-size: 18px;
    }

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: #9b9b9b;
      width: 72px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include sm {
        width: 146px;
      }

      @include md {
        width: 72px;
      }
    }
  }
}

.shop-items {
  border-bottom: $grey-border;
  margin-bottom: 50px;

  @include xl {
    border-bottom: none;
    margin-bottom: 0;

    [class*="col-"] {
      border-bottom: $grey-border;
      margin-bottom: 50px;
    }
  }

  &--last-section {
    margin-bottom: 100px;
  }
}

.shop-items--search-results {
  border-top: $grey-border;
  padding-top: 30px;

  .shop-item {
    margin-bottom: 40px;

    &__img {
      height: 200px;
      margin-bottom: 25px;
    }

    &__descr {
      margin-bottom: 8px;
    }

    &__title {
      text-transform: capitalize;
      font-size: 16px;
      margin-bottom: 0;
      text-align: center;

      @include lg {
        text-align: left;
      }
    }

    &__price-block {
      width: 40%;
      flex-grow: 0;
      margin: 0 auto;

      @include lg {
        margin: 0;
      }
    }
  }
}

.shop-load-more {
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 150px;
  background-color: #FAFAFA;

  font-size: 18px;
  font-weight: normal;
  letter-spacing: 2.7px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
  font-family: $ff-headers;
  margin-bottom: 50px;
  // transition: .4s ease-out;

  @include md {
    height: 262px;
    font-size: 24px;
  }

  svg {
    height: 40px;
    width: 50px;
    fill: #000;
    margin-bottom: 28px;
    transition: .4s ease-out;

    @include lg {
      height: 80px;
      width: 100px;
    }
  }

  &:hover {
    background-color: #000;
    color: #fff;

    svg {
      fill: $accent;
      transform: rotate(-360deg);
    }
  }
}
.contact-section {
  text-align: center;
  background-color: $accent;
  padding-top: 40px;
  padding-bottom: 47px;
  color: #ffffff;

  &__type {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.44;
    display: block;
    margin-bottom: 20px;
    
    @include md {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }

  &__icon {
    width: 89px;
    margin-bottom: 26px;
  }

  &__name {
    display: block;
    font-family: $ff-headers;
    font-weight: normal;
    letter-spacing: 5.5px;
    font-size: 24px;
    margin-bottom: 4px;
    
    @include md {
      font-size: 30px;
      margin-bottom: 40px;
    }
  }

  &__link {
    font-size:14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    color: #fff;
    
    @include md {
      font-size: 16px;
    }
    
    &:hover {
      text-decoration: underline;
    }
  }
}
.contact {
  background: rgba(#f9f9f9, 0.8);
  padding: 40px 0 83px;

  @include lg {
    padding: 37px 0 110px;
  }

  .col-lg-6 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;

    @include lg {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 20px;
  }

  &__inner {
    background: white;
    padding: 30px 20px 40px;
    display: flex;
    width: 100%;
    flex-direction: column;

    @include sm {
      padding: 32px 46px 40px;
    }

    @include lg {
      padding: 31px 53px 54px;
      height: calc(100% - 45px);
    }

    @include xl {
      padding: 40px 67px 45px;
    }
  }

  form {
    width: 100%;

    .form-elements__input {
      @include lg {
        max-width: 300px;
      }
    }
  }

  .form-wrap-submit {
    padding-top: 11px;
  }

  .form-elements__submit {
    margin: 0;
    width: 100%;

    @include lg {
      width: 200px;
      margin: 0 0 0 auto;
    }
  }

  &__info {
    &-item {
      margin-bottom: 40px;

      &:last-child {
        margin: 0;
      }

      &-inner {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(#676767, 0.3);
        border-bottom: 1px solid rgba(#676767, 0.3);
        padding: 30px 0 22px;
      }
    }

    &-title {
      font-size: 12px;
      line-height: 17px;
      margin: 0 0 7px;
      color: #777777;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-image {
      width: 70px;
      text-align: center;
      padding-top: 9px;

      @include sm {
        width: 125px;
      }

      img {
        width: 40px;

        @include sm {
          width: auto;
        }
      }
    }

    &-desc {
      width: calc(100% - 70px);

      @include sm {
        width: calc(100% - 125px);
      }
    }

    &-phone {
      font-family: $ff-headers;
      font-size: 26px;
      font-weight: normal;
      letter-spacing: 3.5px;
      color: #00000a;
      display: block;
      margin: 0 0 11px;

      @include sm {
        font-size: 30px;
        letter-spacing: 5.5px;
      }
      @include md {
        letter-spacing: 4px;
      }
      @include xl {
        letter-spacing: 5.5px;
      }
    }

    &-mail {
      line-height: 1.69;
      color: $accent;
      font-size: 15px;
      font-weight: 500;

      @include sm {
        font-size: 16px;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    &-date {
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 16px;
        line-height: 1.69;
        font-weight: normal;

        &.contact__info-time {
          font-weight: bold;
        }
      }
    }
  }

  &--socials {
    padding-top: 16px;

    li {
      margin-right: 20px;
    }
  }
}
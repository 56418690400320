.testimonials-small {
  padding: 100px 0;

  h2 {
    text-align: center;
  }

  &__item {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__item-header {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
  }

  &__item-pic {
    margin-right: 20px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__item-info {
    font-size: 14px;

    b {
      display: block;
      margin-bottom: 3px;
      text-transform: uppercase;
    }
  }

  &__item-descr {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.56;
    position: relative;

    @include sm {
      font-size: 16px;
    }


    &::before {
      content: '';
      position: absolute;
      left: -40px;
      top: 20px;
      width: 18px;
      height: 18px;
      background: url(../img/icons/icon-cite.svg) center/cover no-repeat;
    }
  }
}
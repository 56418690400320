h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $ff-headers;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
}

h1 {
  font-size: 48px;
  letter-spacing: 3.8px;

  @include sm {
    font-size: 58px;
    letter-spacing: 5.8px;
  }

  @include lg {
    font-size: 70px;
    letter-spacing: 7px;
  }
}

h2 {
  font-size: 28px;
  letter-spacing: 3.9px;

  @include sm {
    font-size: 38px;
    letter-spacing: 5.9px;
  }

  @include lg {
    font-size: 50px;
    letter-spacing: 7.8px;
  }
}

h3 {
  font-size: 20px;
  letter-spacing: 2.7px;

  @include sm {
    font-size: 24px;
  }

  @include lg {
    font-size: 36px;
    letter-spacing: 4px;
  }
}

h4 {
  font-size: 18px;
  letter-spacing: 2.7px;

  @include sm {
    font-size: 22px;
  }

  @include lg {
    font-size: 30px;
    letter-spacing: 4px;
  }
}

h5 {
  font-size: 18px;
  letter-spacing: 3px;
  margin-bottom: 21px;

  @include sm {
    font-size: 22px;
  }
}

.bg-accent {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}

hr {
  background-color: #d6d6d6;
  height: 1px;

  &.with-margin {
    margin-top: 40px;
    margin-bottom: 40px;
    
    @include lg {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
}
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@font-face {
  font-family: "BebasNeue";
  font-weight: 700;
  src: url("../fonts/BebasNeue/BebasNeue-Bold.woff2") format("woff2"), url("../fonts/BebasNeue/BebasNeue-Bold.woff") format("woff");
}

@font-face {
  font-family: "BebasNeue";
  font-weight: 400;
  src: url("../fonts/BebasNeue/BebasNeue-Regular.woff2") format("woff2"), url("../fonts/BebasNeue/BebasNeue-Regular.woff") format("woff");
}

@font-face {
  font-family: "TTWellingtons";
  font-weight: 700;
  src: url("../fonts/TTWellingtons/TTWellingtons-Bold.woff2") format("woff2"), url("../fonts/TTWellingtons/TTWellingtons-Bold.woff") format("woff");
}

@font-face {
  font-family: "TTWellingtons";
  src: url("../fonts/TTWellingtons/TTWellingtons-Regular.woff2") format("woff2"), url("../fonts/TTWellingtons/TTWellingtons-Regular.woff") format("woff");
}

@font-face {
  font-family: "TTWellingtons";
  font-weight: 500;
  src: url("../fonts/TTWellingtons/TTWellingtons-Medium.woff2") format("woff2"), url("../fonts/TTWellingtons/TTWellingtons-Medium.woff") format("woff");
}

@font-face {
  font-family: "TTWellingtons";
  font-weight: 800;
  src: url("../fonts/TTWellingtons/TTWellingtons-ExtraBold.woff2") format("woff2"), url("../fonts/TTWellingtons/TTWellingtons-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "TTWellingtons";
  font-weight: 600;
  src: url("../fonts/TTWellingtons/TTWellingtons-DemiBold.woff2") format("woff2"), url("../fonts/TTWellingtons/TTWellingtons-DemiBold.woff") format("woff");
}

@font-face {
  font-family: "TTWellingtons";
  font-style: italic;
  src: url("../fonts/TTWellingtons/TTWellingtons-Italic.woff2") format("woff2"), url("../fonts/TTWellingtons/TTWellingtons-Italic.woff") format("woff");
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

button {
  background-color: transparent;
}

button:not(:disabled) {
  cursor: pointer;
}

button:focus {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  font-family: "TTWellingtons", sans-serif;
  background: #fff;
  min-width: 320px;
  color: #4a4a4a;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 100px;
  transition: padding-top 0.2s ease;
}

body.scrolled {
  padding-top: 50px;
}

@media only screen and (min-width: 992px) {
  body {
    padding-top: 152px;
  }
  body.scrolled {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1200px) {
  body {
    padding-top: 152px;
  }
  body.scrolled {
    padding-top: 80px;
  }
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:horizontal {
  height: 11px;
}

body::-webkit-scrollbar:vertical {
  width: 11px;
}

body::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border: 2px solid #fff;
  border-radius: 8px;
}

input,
textarea {
  box-shadow: none;
}

::placeholder {
  opacity: 1;
  color: #777777;
  line-height: inherit;
}

:-ms-input-placeholder {
  color: #777777;
  line-height: inherit;
}

::ms-input-placeholder {
  color: #777777;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BebasNeue", sans-serif;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
}

h1 {
  font-size: 48px;
  letter-spacing: 3.8px;
}

@media only screen and (min-width: 576px) {
  h1 {
    font-size: 58px;
    letter-spacing: 5.8px;
  }
}

@media only screen and (min-width: 992px) {
  h1 {
    font-size: 70px;
    letter-spacing: 7px;
  }
}

h2 {
  font-size: 28px;
  letter-spacing: 3.9px;
}

@media only screen and (min-width: 576px) {
  h2 {
    font-size: 38px;
    letter-spacing: 5.9px;
  }
}

@media only screen and (min-width: 992px) {
  h2 {
    font-size: 50px;
    letter-spacing: 7.8px;
  }
}

h3 {
  font-size: 20px;
  letter-spacing: 2.7px;
}

@media only screen and (min-width: 576px) {
  h3 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 992px) {
  h3 {
    font-size: 36px;
    letter-spacing: 4px;
  }
}

h4 {
  font-size: 18px;
  letter-spacing: 2.7px;
}

@media only screen and (min-width: 576px) {
  h4 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) {
  h4 {
    font-size: 30px;
    letter-spacing: 4px;
  }
}

h5 {
  font-size: 18px;
  letter-spacing: 3px;
  margin-bottom: 21px;
}

@media only screen and (min-width: 576px) {
  h5 {
    font-size: 22px;
  }
}

.bg-accent h1,
.bg-accent h2,
.bg-accent h3,
.bg-accent h4,
.bg-accent h5,
.bg-accent h6 {
  color: #fff;
}

hr {
  background-color: #d6d6d6;
  height: 1px;
}

hr.with-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 992px) {
  hr.with-margin {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.posr {
  position: relative;
}

.tac {
  text-align: center;
}

.ttu {
  text-transform: uppercase;
}

.ovh {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.w-100 {
  width: 100% !important;
}

.center-img {
  text-align: center;
  font-size: 0;
}

.border-wide {
  border-width: 7px;
  border-style: solid;
  padding-top: 29px;
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 992px) {
  .border-wide {
    border-width: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .border-wide {
    padding-top: 40px;
  }
}

.border-wide--grey {
  border-color: #FAFAFA;
}

.border-margin {
  margin-top: 33px;
}

@media only screen and (min-width: 576px) {
  .border-margin-sm, .border-margin {
    margin-top: 33px;
  }
}

@media only screen and (min-width: 768px) {
  .border-margin-md, .border-margin, .border-margin-sm {
    margin-top: 33px;
  }
}

@media only screen and (min-width: 992px) {
  .border-margin-lg, .border-margin, .border-margin-sm, .border-margin-md {
    margin-top: 39px;
  }
}

@media only screen and (min-width: 1200px) {
  .border-margin-xl, .border-margin, .border-margin-sm, .border-margin-md, .border-margin-lg {
    margin-top: 50px;
  }
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.fxdc {
  flex-direction: column;
}

.df {
  display: flex;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-first {
  order: -1;
}

@media only screen and (min-width: 576px) {
  .order-first-sm {
    order: -1;
  }
}

@media only screen and (min-width: 768px) {
  .order-first-md {
    order: -1;
  }
}

@media only screen and (min-width: 992px) {
  .order-first-lg {
    order: -1;
  }
}

@media only screen and (min-width: 1200px) {
  .order-first-xl {
    order: -1;
  }
}

@media only screen and (min-width: 1680px) {
  .order-first-xxl {
    order: -1;
  }
}

.bg-accent {
  background-color: #54bae2;
}

.bg-black {
  background-color: #000;
}

.bg-grey {
  background-color: #fafafa;
}

.mb-1 {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .mb-1 {
    margin-bottom: 30px;
  }
}

.mb-2 {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .mb-2 {
    margin-bottom: 60px;
  }
}

.mb-3 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) {
  .mb-3 {
    margin-bottom: 80px;
  }
}

.mt-1 {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .mt-1 {
    margin-top: 30px;
  }
}

.mt-2 {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .mt-2 {
    margin-top: 60px;
  }
}

.mt-3 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) {
  .mt-3 {
    margin-top: 80px;
  }
}

.pb-1 {
  padding-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .pb-1 {
    padding-bottom: 30px;
  }
}

.pb-2 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .pb-2 {
    padding-bottom: 60px;
  }
}

.pb-3 {
  padding-bottom: 50px;
}

@media only screen and (min-width: 768px) {
  .pb-3 {
    padding-bottom: 80px;
  }
}

.pt-1 {
  padding-top: 20px;
}

@media only screen and (min-width: 768px) {
  .pt-1 {
    padding-top: 30px;
  }
}

.pt-2 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) {
  .pt-2 {
    padding-top: 60px;
  }
}

.pt-3 {
  padding-top: 50px;
}

@media only screen and (min-width: 768px) {
  .pt-3 {
    padding-top: 80px;
  }
}

.p-1 {
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .p-1 {
    padding: 30px;
  }
}

.p-2 {
  padding: 40px;
}

@media only screen and (min-width: 768px) {
  .p-2 {
    padding: 60px;
  }
}

.p-3 {
  padding: 50px;
}

@media only screen and (min-width: 768px) {
  .p-3 {
    padding: 80px;
  }
}

.h-50 {
  height: 50px;
}

.h-40 {
  height: 40px;
}

.h-60 {
  height: 60px;
}

.accordeon__item:not(:last-child) {
  margin-bottom: 10px;
}

.accordeon__item-header {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 13px;
  justify-content: space-between;
  background-color: #f9f9f9;
  cursor: pointer;
  user-select: none;
}

.accordeon__item-header::after {
  content: '+';
  height: 26px;
  width: 26px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid #676767;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #676767;
  font-size: 26px;
  padding-top: 2px;
  font-weight: 500;
}

.accordeon__item.active .accordeon__item-header {
  background-color: #181818;
}

.accordeon__item.active .accordeon__item-header::after {
  content: '-';
  border: 2px solid #fff;
  color: #fff;
}

.accordeon__item-name {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

.accordeon__item-name small {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #9b9b9b;
  text-transform: none;
}

.accordeon__item.active .accordeon__item-name {
  background-color: #181818;
  color: #fff;
}

.accordeon__item-content {
  display: none;
}

@media only screen and (max-width: 991px) {
  .accordeon--locations .accordeon__item {
    display: none;
  }
  .accordeon--locations .accordeon__item.active {
    margin-top: -20px;
    display: block !important;
  }
  .accordeon--locations .accordeon__item.active .accordeon__item-content {
    display: block !important;
  }
  .accordeon--locations .accordeon__item-header {
    display: none;
  }
}

.accordeon--help .accordeon__item-header {
  justify-content: flex-start;
}

@media only screen and (min-width: 992px) {
  .accordeon--help .accordeon__item-header {
    height: 60px;
  }
}

.accordeon--help .accordeon__item-header::after {
  order: 1;
  margin-right: 20px;
  background-color: #D8D8D8;
  border: none;
}

@media only screen and (min-width: 992px) {
  .accordeon--help .accordeon__item-header::after {
    margin-right: 42px;
    width: 40px;
    height: 40px;
  }
}

.accordeon--help .accordeon__item.active .accordeon__item-header::after {
  background: none;
}

.accordeon--help .accordeon__item-name {
  order: 2;
  font-size: 12px;
}

.accordeon--help .accordeon__item-content {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  font-size: 14px;
  line-height: 1.79;
  letter-spacing: normal;
  color: #00000a;
}

@media only screen and (min-width: 576px) {
  .accordeon--help .accordeon__item-content {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media only screen and (min-width: 992px) {
  .accordeon--help .accordeon__item-content {
    padding-left: 107px;
    padding-top: 20px;
    padding-right: 100px;
    padding-bottom: 15px;
  }
}

.account {
  background: rgba(249, 249, 249, 0.8);
  padding: 24px 0 44px;
}

@media only screen and (min-width: 992px) {
  .account {
    padding: 40px 0 80px;
  }
}

.account__title .page-title {
  font-weight: bold;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) {
  .account__title .page-title {
    margin-bottom: 4px;
  }
}

.account__subtitle {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  line-height: 1.36;
  margin: 0 0 16px;
}

.account-separate {
  margin: 40px 0 30px;
  height: 1px;
  background: #eeeeee;
}

.account-separate.account-separate-40 {
  margin: 40px 0;
}

.account-separate.account-separate-mb-0 {
  margin-bottom: 0;
}

.account .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) {
  .account .container {
    flex-wrap: nowrap;
  }
}

.account-list {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .account-list {
    max-width: 293px;
    padding-right: 20px;
  }
}

.account-list__mobile {
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) {
  .account-list__mobile {
    display: none;
  }
}

.account-list__title {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 2px;
  color: #000000;
  margin: 0;
  width: calc(100% - 35px);
}

.account-list img {
  width: 30px;
  height: 30px;
}

.account-list.active img {
  transform: rotate(180deg);
}

.account-menu {
  display: none;
  min-width: 100%;
  width: 100%;
  padding-bottom: 30px;
}

@media only screen and (min-width: 992px) {
  .account-menu {
    display: block;
    padding: 0;
  }
}

.account-menu__item {
  width: 100%;
  margin: 0 0 24px;
}

.account-menu__item.account-menu__item-current .account-menu__link {
  color: #54bae2;
}

.account-menu__link {
  font-size: 18px;
  font-weight: normal;
  font-family: "BebasNeue", sans-serif;
  letter-spacing: 2px;
  color: #00000a;
}

.account-menu__link:hover {
  color: #54bae2;
}

.account-submenu {
  margin: 19px 0 -3px;
}

.account-submenu__link {
  line-height: 1.33;
  display: block;
  color: #00000a;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 16px;
  position: relative;
}

.account-submenu__link:before {
  content: '';
  width: 7px;
  height: 7px;
  border-top: solid 1px #00000a;
  border-right: solid 1px #00000a;
  position: absolute;
  left: 0;
  top: 46%;
  transform: translateY(-50%) rotate(45deg);
}

.account-submenu__link:hover {
  color: #54bae2;
}

.account-submenu__link:hover:before {
  border-top: solid 1px #54bae2;
  border-right: solid 1px #54bae2;
}

.account-submenu__item {
  margin-bottom: 5px;
}

.account-submenu__item:last-child {
  margin-bottom: 0;
}

.account-submenu__item.account-submenu__item-current .account-submenu__link {
  color: #54bae2;
}

.account-submenu__item.account-submenu__item-current .account-submenu__link:before {
  border-top: solid 1px #54bae2;
  border-right: solid 1px #54bae2;
}

.account-wrap {
  background: white;
  width: 100%;
  padding: 40px 20px;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .account-wrap {
    padding: 40px;
  }
}

@media only screen and (min-width: 992px) {
  .account-wrap {
    max-width: 750px;
  }
}

@media only screen and (min-width: 768px) {
  .account-wrap__inner {
    min-height: 340px;
  }
}

.account-wrap__btn {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.account-wrap__btn .accout-cancel {
  width: 100%;
  margin-bottom: 15px;
}

@media only screen and (min-width: 576px) {
  .account-wrap__btn .accout-cancel {
    width: calc(41% - 5px);
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .account-wrap__btn .accout-cancel {
    width: auto;
  }
}

.account-wrap__btn .accout-submit {
  width: 100%;
}

@media only screen and (min-width: 576px) {
  .account-wrap__btn .accout-submit {
    margin-left: 9px;
    width: calc(59% - 5px);
  }
}

@media only screen and (min-width: 768px) {
  .account-wrap__btn .accout-submit {
    margin-left: 30px;
    width: auto;
  }
}

@media only screen and (min-width: 768px) {
  .account-wrap__btn .accout-submit.cta--40 {
    margin-left: 20px;
    width: auto;
  }
}

.account-wrap .email-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.account-wrap .email-row.password-row {
  align-items: flex-start;
}

.account-wrap .email-column {
  width: 100%;
  max-width: 357px;
  margin: 0 auto;
  padding-top: 10px;
}

@media only screen and (min-width: 768px) {
  .account-wrap .email-column {
    width: calc(50% - 20px);
    max-width: auto;
    margin: 0;
    padding: 0;
  }
}

.account-wrap .email__varify {
  font-size: 14px;
  font-weight: 500;
  line-height: 2.07;
  color: #54bae2;
  display: inline-block;
  margin-bottom: 10px;
}

.account-wrap .email__info {
  font-size: 12px;
  font-weight: 500;
  color: #9b9b9b;
}

@media only screen and (min-width: 768px) {
  .account-wrap .password-row .email__info {
    padding-top: 27px;
  }
}

.account-wrap .password-row .payment__name {
  padding-top: 12px;
}

@media only screen and (min-width: 768px) {
  .account-wrap.account-wrap-min {
    min-height: 571px;
  }
}

.account-wrap__buttons {
  position: absolute;
  top: 30px;
  right: 40px;
  text-align: right;
}

@media only screen and (min-width: 992px) {
  .account-wrap__buttons {
    top: 40px;
  }
}

.account-wrap__buttons-item {
  font-size: 16px;
  font-weight: 500;
  color: #54bae2;
  letter-spacing: 1px;
  align-items: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
}

@media only screen and (min-width: 1200px) {
  .account-wrap__buttons-item {
    margin-bottom: 18px;
  }
}

.account-wrap__buttons-item img {
  margin-right: 7px;
  transform: rotate(-45deg);
}

.account-wrap__buttons-item:hover {
  color: #549ce2;
}

.account-wrap__remove {
  font-size: 16px;
  font-weight: 500;
  color: #54bae2;
  letter-spacing: 1px;
  display: none;
  align-items: center;
  text-transform: uppercase;
}

.account-wrap__remove img {
  margin-right: 16px;
}

.account-wrap__remove.active {
  display: inline-flex;
}

.commun__title {
  color: #00000a;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 16px;
}

.commun__info {
  font-size: 14px;
  font-weight: 500;
  color: #676767;
  line-height: 1.5;
  margin: 0 0 23px;
}

.accout-review__title {
  font-size: 12px;
  font-weight: bold;
  color: #00000a;
  text-transform: uppercase;
  margin: 0 0 24px;
}

.accout-review table {
  width: 100%;
  border-collapse: collapse;
}

.accout-review table td {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 17px 8px;
  color: #00000a;
}

@media only screen and (min-width: 576px) {
  .accout-review table td {
    padding: 17px 18px;
  }
}

.accout-review table td:first-child {
  padding-left: 0;
}

.accout-review table td:last-child {
  padding-right: 0;
  text-align: right;
}

.accout-review table td a {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: #54bae2;
}

.accout-review table td a:hover, .accout-review table td a:focus {
  text-decoration: underline;
}

.accout-review__num {
  font-size: 12px;
  font-weight: 500;
}

.accout-review__name {
  text-transform: uppercase;
  line-height: 1.58;
  font-size: 12px;
  font-weight: bold;
}

.accout-review__text {
  font-style: italic;
  font-size: 14px;
  font-weight: normal;
}

.accout-review .account-separate {
  margin: 6px 0;
  font-size: 12px;
  font-weight: bold;
}

.address-wrap {
  padding-top: 70px;
}

@media only screen and (min-width: 1200px) {
  .address-wrap {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .address-wrap .form-elements__input {
    max-width: 420px;
  }
}

.address-wrap .checkbox-wrap {
  padding: 10px 0;
}

.payment-wrap {
  padding-top: 100px;
}

@media only screen and (min-width: 1200px) {
  .payment-wrap {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .payment-wrap .form-elements__input {
    max-width: 420px;
  }
}

.payment-wrap .checkbox-wrap {
  padding: 35px 0 0;
}

.payment__info {
  width: 100%;
}

.payment__info-title {
  font-size: 12px;
  font-weight: bold;
  color: #777777;
  margin: 0 0 26px;
  text-transform: uppercase;
}

.payment__info table {
  margin-bottom: 5px;
  width: 100%;
  max-width: 600px;
}

.payment__info td {
  font-size: 14px;
  font-weight: 500;
  line-height: 2.07;
  padding: 2px 5px;
  color: #00000a;
}

.payment__info td:first-child {
  padding-left: 0;
  color: #9b9b9b;
}

@media only screen and (min-width: 768px) {
  .gift {
    min-height: 433px;
  }
}

.gift__title {
  text-transform: uppercase;
  margin: 0 0 13px;
  font-size: 12px;
  font-weight: bold;
  color: #00000a;
}

.gift__inner {
  border-radius: 6px;
  background-color: #f5f5f5;
  padding: 14px 19px 19px;
  display: inline-block;
  min-width: 250px;
  margin-bottom: 42px;
}

.gift__inner p {
  font-size: 12px;
  color: #777777;
  margin: 0 0 8px;
  line-height: 17px;
}

.gift__balance {
  font-size: 20px;
  font-weight: bold;
  color: #00000a;
  margin: 0;
  line-height: 28px;
}

.gift__desc-title {
  font-size: 12px;
  font-weight: bold;
  color: #777777;
  text-transform: uppercase;
  margin: 0 0 16px;
}

.gift__desc-info {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #676767;
  margin: 0 0 29px;
  max-width: 653px;
}

.gift__desc input {
  max-width: 250px;
}

.wish-list__title {
  font-size: 12px;
  font-weight: bold;
  color: #00000a;
  margin: 0 0 29px;
  text-transform: uppercase;
}

.wish-list__table {
  width: 100%;
  border-collapse: collapse;
}

.wish-list__table th {
  border-bottom: 1px solid #eeeeee;
  font-size: 11px;
  font-weight: 500;
  color: #9b9b9b;
  padding: 0 4px 14px;
  text-align: left;
}

@media only screen and (min-width: 576px) {
  .wish-list__table th {
    padding: 0 7px 14px;
    font-size: 12px;
  }
}

.wish-list__table th:first-child {
  padding-left: 0;
}

.wish-list__table th:last-child {
  padding-right: 0;
  text-align: right;
}

.wish-list__table td {
  border-bottom: 1px solid #eeeeee;
  padding: 20px 4px;
  font-size: 12px;
  font-weight: 500;
  color: #00000a;
}

@media only screen and (min-width: 576px) {
  .wish-list__table td {
    padding: 20px 7px;
  }
}

.wish-list__table td:first-child {
  padding-left: 0;
}

.wish-list__table td:last-child {
  padding-right: 0;
  text-align: right;
}

.wish-list__prod {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 576px) {
  .wish-list__prod {
    flex-wrap: nowrap;
  }
}

.wish-list__image {
  margin: 0 0 15px;
}

@media only screen and (min-width: 576px) {
  .wish-list__image {
    margin: 0 10px 0 0;
  }
}

@media only screen and (min-width: 768px) {
  .wish-list__image {
    margin: 0 20px 0 0;
  }
}

.wish-list__image img {
  border: 1px solid #eeeeee;
  width: 65px;
}

@media only screen and (min-width: 576px) {
  .wish-list__image img {
    width: 80px;
  }
}

@media only screen and (min-width: 768px) {
  .wish-list__image img {
    width: 110px;
  }
}

.wish-list__desc {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .wish-list__desc {
    width: auto;
  }
}

.wish-list__desc a {
  color: #54bae2;
}

.wish-list__desc a:hover, .wish-list__desc a:focus {
  text-emphasis: underline;
}

.wish-list__name {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.36;
  color: #00000a;
  margin: 0 0 5px;
}

@media only screen and (min-width: 576px) {
  .wish-list__name {
    font-size: 14px;
  }
}

.wish-list__subname {
  font-size: 12px;
  line-height: 1.58;
  font-weight: 500;
  margin: 0 0 8px;
}

.wish-list__price {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: 1px;
  color: #00000a;
}

@media only screen and (min-width: 576px) {
  .wish-list__price {
    font-size: 16px;
  }
}

.order-history .wish-list__title {
  margin-bottom: 12px;
}

.order-history__inner {
  background: rgba(249, 249, 249, 0.8);
  display: flex;
  padding: 15px 17px;
  align-items: center;
  margin-bottom: 39px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 576px) {
  .order-history__inner {
    padding: 13px 29px;
    flex-wrap: nowrap;
  }
}

.order-history__inner-title {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  color: #676767;
  margin: 0 0 10px;
  width: 100%;
}

@media only screen and (min-width: 576px) {
  .order-history__inner-title {
    width: auto;
    margin: 0 20px 0 0;
  }
}

.order-history__sort {
  height: 34px;
  background-color: transparent;
  border: solid 1px #676767;
  margin: 0 0 10px 0;
  font-size: 14px;
  font-weight: 500;
  color: #676767;
  display: inline-flex;
  padding: 4px 17px;
  width: 100%;
  background-image: url(../img/icons/select-arr.svg);
  background-repeat: no-repeat;
  background-size: 11px 13px;
  background-position: 90% 10px;
}

.order-history__sort:last-child {
  margin: 0;
}

@media only screen and (min-width: 576px) {
  .order-history__sort {
    max-width: 151px;
    margin: 0 20px 0 0;
  }
}

.order-history__buttons {
  display: flex;
  padding: 23px 0 24px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (min-width: 576px) {
  .order-history__buttons {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.order-history__buttons .cta {
  letter-spacing: 0.8px;
  font-size: 12px;
  font-weight: 500;
  width: calc(50% - 5px);
  margin: 0 0 10px 0;
}

@media only screen and (min-width: 576px) {
  .order-history__buttons .cta {
    width: auto;
    margin: 0 15px 0 0;
  }
}

@media only screen and (min-width: 768px) {
  .order-history__buttons .cta {
    margin: 0 20px 0 0;
  }
}

.order-history__buttons .cta:last-child {
  margin: 0;
}

.order-history-separate {
  height: 1px;
  background: #eeeeee;
  margin: 4px 0 40px;
}

@media only screen and (min-width: 1200px) {
  .order-history-separate {
    margin: 4px 0 154px;
  }
}

.order-cards .wish-list__title {
  margin-bottom: 12px;
}

.order-cards__inner {
  border-top: 1px solid #eeeeee;
  padding: 30px 0 12px;
}

.order-cards__number {
  font-size: 12px;
  font-weight: bold;
  color: #54bae2;
  display: inline-block;
}

.order-cards__number:hover, .order-cards__number:focus {
  text-decoration: underline;
}

.order-cards .wish-list__table td {
  border: 0;
}

.order-cards .tabs-nav {
  margin: 0 0 20px;
  border-bottom: 1px solid #eeeeee;
}

.order-cards .tabs-nav__item {
  margin: 0 15px 0 0;
  padding: 0;
  border: 0;
}

@media only screen and (min-width: 576px) {
  .order-cards .tabs-nav__item {
    margin: 0 30px 0 0;
  }
}

@media only screen and (min-width: 1200px) {
  .order-cards .tabs-nav__item {
    margin: 0 50px 0 0;
  }
}

.order-cards .tabs-nav__item:last-child {
  margin: 0;
}

.order-cards .tabs-nav__item:first-child {
  text-align: left;
  flex-grow: inherit;
}

.order-cards .tabs-nav__item.active {
  border: 0;
}

.order-cards .tabs-nav__item.active .tabs-nav__btn {
  border-bottom: 3px solid #54bae2;
  color: #54bae2;
}

.order-cards .tabs-nav__btn {
  padding: 8px 0 12px;
  position: relative;
  border: 0;
  border-bottom: 3px solid transparent;
  font-size: 11px;
  font-weight: 500;
  font-family: "TTWellingtons", sans-serif;
  color: #00000a;
  letter-spacing: inherit;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 576px) {
  .order-cards .tabs-nav__btn {
    font-size: 12px;
  }
}

.order-cards .tabs-nav__btn:hover {
  border-bottom: 3px solid #54bae2;
  color: #54bae2;
}

.order-cards .tabs-nav__item.active:not(:first-child):not(:last-child) {
  border: 0;
}

.order-cards .tab-content {
  padding-bottom: 30px;
  margin: 0;
}

.order-cards .tab-content p {
  font-size: 12px;
  font-weight: 500;
  color: #676767;
  line-height: 1.58;
}

.banner {
  background-color: #fafafa;
  padding: 40px 0 65px;
}

@media only screen and (min-width: 992px) {
  .banner {
    padding: 40px 0 60px;
  }
}

.banner__inner {
  background: white;
  padding: 70px 25px 65px;
  text-align: center;
}

@media only screen and (min-width: 576px) {
  .banner__inner {
    padding: 70px 35px;
  }
}

.banner__image {
  margin: 0 0 50px;
}

@media only screen and (min-width: 992px) {
  .banner__image {
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 992px) {
  .banner__image.banner__image-small img {
    width: 80px;
  }
}

.banner__image img {
  width: 130px;
}

@media only screen and (min-width: 576px) {
  .banner__image img {
    width: 190px;
  }
}

@media only screen and (min-width: 992px) {
  .banner__image img {
    width: auto;
  }
}

.banner__info {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.79;
  max-width: 475px;
  margin: 0 auto;
}

@media only screen and (min-width: 992px) {
  .banner__info {
    font-size: 14px;
  }
}

.banner__info strong {
  font-weight: bold;
  text-transform: uppercase;
}

.banner__info a {
  color: #54bae2;
}

.banner__info a:hover, .banner__info a:focus {
  text-decoration: underline;
}

.blog__item {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.blog__item:last-child {
  margin-bottom: 60px;
}

@media only screen and (min-width: 576px) {
  .blog__item {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}

.blog__item-img {
  height: 175px;
  display: block;
}

.blog__item-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

.blog__item-title {
  display: block;
  font-family: "BebasNeue", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 3.3px;
  color: #00000a;
  margin-bottom: 6px;
  margin-top: 20px;
}

@media only screen and (min-width: 576px) {
  .blog__item-title {
    font-size: 20px;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .blog__item-title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) {
  .blog__item-title {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1200px) {
  .blog__item-title {
    font-size: 30px;
  }
}

.blog__item-title:visited {
  color: inherit;
}

.blog__item-title:hover {
  color: #54bae2;
}

.blog__item-date {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.64;
  color: #777777;
  margin-bottom: 10px;
  display: block;
}

@media only screen and (min-width: 576px) {
  .blog__item-date {
    font-size: 14px;
  }
}

.blog__item-descr {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 7px;
}

@media only screen and (min-width: 576px) {
  .blog__item-descr {
    font-size: 14px;
  }
}

.blog__item-footer {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #777777;
  font-variant-numeric: lining-nums;
}

@media only screen and (min-width: 576px) {
  .blog__item-footer {
    font-size: 14px;
  }
}

.blog__item-footer a {
  color: inherit;
}

.blog__item-footer a:visited {
  color: inherit;
}

.blog__item-footer a:hover {
  text-decoration: underline;
  color: #54bae2;
}

.blog .cta {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .blog .cta {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.breadcrumbs {
  padding-top: 26px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (min-width: 576px) {
  .breadcrumbs {
    padding-top: 40px;
    margin-bottom: 52px;
  }
}

@media only screen and (min-width: 992px) {
  .breadcrumbs {
    padding-top: 26px;
    margin-bottom: 60px;
  }
}

.breadcrumbs li {
  font-size: 10px;
  color: #9b9b9b;
  line-height: 1;
  font-weight: 500;
}

@media only screen and (min-width: 576px) {
  .breadcrumbs li {
    font-size: 12px;
  }
}

.breadcrumbs li:not(:last-child)::after {
  content: '>';
  margin: 0 4px;
}

.breadcrumbs a {
  color: inherit;
  text-transform: uppercase;
  display: inline-block;
  font-variant-numeric: lining-nums;
}

.breadcrumbs a[href]:hover, .breadcrumbs a[href]:focus {
  color: #54bae2;
}

.btn-favorite {
  position: relative;
  width: 70px;
  text-align: center;
}

.btn-favorite::before {
  content: '';
  display: inline-block;
  width: 40px;
  height: 38px;
  background: url(../img/icons/icon-heart.svg) center/cover no-repeat;
  margin-bottom: 12px;
}

.btn-favorite::after {
  content: 'Add to favorite';
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #00000a;
  display: block;
}

.btn-favorite:hover::before {
  background-image: url(../img/icons/icon-heart-accent-filled.svg);
}

.btn-favorite--added::before {
  background-image: url(../img/icons/icon-heart-accent-filled.svg);
}

.btn-favorite--added::after {
  content: 'Added to favorite';
}

.cart {
  background: rgba(249, 249, 249, 0.8);
  padding: 40px 0 100px;
}

@media only screen and (min-width: 768px) {
  .cart {
    padding: 40px 0 130px;
  }
}

.cart-product {
  background: white;
  padding: 18px 10px 24px;
  margin-bottom: 24px;
}

@media only screen and (min-width: 576px) {
  .cart-product {
    padding: 22px 25px 24px;
  }
}

@media only screen and (min-width: 1200px) {
  .cart-product {
    padding: 22px 40px 24px;
  }
}

.cart-product__item {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .cart-product__item {
    flex-wrap: nowrap;
  }
}

.cart-product__item-image {
  margin-bottom: 20px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .cart-product__item-image {
    margin-bottom: 0;
    width: auto;
  }
}

.cart-product__item-image img {
  border: 1px solid #e8e8e8;
  margin-bottom: 16px;
}

@media only screen and (min-width: 576px) {
  .cart-product__item-image img {
    width: 90px;
    min-width: 90px;
  }
}

@media only screen and (min-width: 768px) {
  .cart-product__item-image img {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1200px) {
  .cart-product__item-image img {
    width: 110px;
    min-width: 110px;
  }
}

.cart-product__item a {
  font-size: 12px;
  font-weight: 500;
  display: block;
  color: #54bae2;
  line-height: 1.92;
}

.cart-product__item a.cart-product__remove {
  color: #4f4f4f;
}

@media only screen and (min-width: 768px) {
  .cart-product__item-desc {
    padding: 0 0 0 16px;
  }
}

.cart-product__item-name {
  line-height: 1.36;
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 600;
}

.cart-product__table {
  width: 100%;
}

.cart-product__table th {
  border-bottom: 1px solid rgba(249, 249, 249, 0.8);
  padding: 14px 7px;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #9b9b9b;
}

@media only screen and (min-width: 576px) {
  .cart-product__table th {
    padding: 14px 13px;
  }
}

@media only screen and (min-width: 1200px) {
  .cart-product__table th {
    padding: 14px 16px;
  }
}

.cart-product__table th:last-child {
  text-align: right;
  padding-right: 0;
}

.cart-product__table th:first-child {
  padding-left: 0;
}

.cart-product__table td {
  padding: 20px 7px 24px;
  border-bottom: 1px solid #eeeeee;
}

@media only screen and (min-width: 576px) {
  .cart-product__table td {
    padding: 20px 13px 24px;
  }
}

@media only screen and (min-width: 1200px) {
  .cart-product__table td {
    padding: 20px 16px 24px;
  }
}

.cart-product__table td:first-child {
  padding-left: 0;
}

.cart-product__table td:last-child {
  padding-right: 0;
  text-align: right;
}

.cart-product__charact {
  border-radius: 6px;
  background-color: #fafafa;
  padding: 8px 12px 8px 17px;
  display: none;
}

@media only screen and (min-width: 576px) {
  .cart-product__charact {
    min-width: 200px;
    display: table;
  }
}

@media only screen and (min-width: 1200px) {
  .cart-product__charact {
    min-width: 257px;
  }
}

.cart-product__charact td {
  font-size: 11px;
  font-weight: 500;
  padding: 7px 10px 7px 0;
  text-align: left;
  border: 0;
}

@media only screen and (min-width: 576px) {
  .cart-product__charact td {
    font-size: 12px;
    padding: 8px 14px 8px 0;
  }
}

@media only screen and (min-width: 1200px) {
  .cart-product__charact td {
    padding: 8px 20px 8px 0;
  }
}

.cart-product__charact td:first-child {
  text-transform: uppercase;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .cart-product__charact td:first-child {
    white-space: nowrap;
  }
}

.cart-product__charact td:last-child {
  text-align: left;
}

.cart .product-price {
  padding-top: 28px;
  display: block;
  font-size: 14px;
}

@media only screen and (min-width: 576px) {
  .cart .product-price {
    font-size: 20px;
  }
}

.cart .wrap-number .form-elements__input {
  font-size: 14px;
}

@media only screen and (min-width: 576px) {
  .cart .wrap-number .form-elements__input {
    font-size: 20px;
  }
}

.product-price {
  font-size: 20px;
  line-height: 0.95;
  letter-spacing: 2.5px;
  color: #00000a;
  font-weight: normal;
  font-family: "BebasNeue", sans-serif;
}

.cart-row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) {
  .cart-row {
    flex-wrap: nowrap;
  }
}

.cart-row--left {
  width: 100%;
  margin: 0 0 55px;
}

@media only screen and (min-width: 992px) {
  .cart-row--left {
    width: 68%;
    margin: 0 30px 0 0;
  }
}

.cart-row--right {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

@media only screen and (min-width: 992px) {
  .cart-row--right {
    width: 300px;
    margin: 0;
  }
}

.continue-shopping {
  position: relative;
  padding: 0 19px 0 35px;
}

.continue-shopping:before {
  height: 10px;
  width: 10px;
  content: '';
  transform: rotate(-135deg) translateY(70%);
  border-top: solid 2px #4a4a4a;
  border-right: solid 2px #4a4a4a;
  position: absolute;
  left: 12px;
  top: 50%;
  transition: all 0.2s ease;
}

.continue-shopping:hover:before, .continue-shopping:focus:before {
  border-top: solid 2px white;
  border-right: solid 2px white;
}

.coupon__title {
  font-size: 12px;
  font-weight: 500;
  color: #9b9b9b;
  margin: 0 0 8px;
  display: block;
}

.coupon__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid  #eeeeee;
  border-bottom: 1px solid  #eeeeee;
  padding: 15px 0 30px;
}

.coupon__inner input[type="text"] {
  width: 71%;
  margin: 0;
  height: 40px;
  background: transparent;
  padding: 10px 13px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
}

.coupon__inner input[type="submit"] {
  width: 26%;
  cursor: pointer;
  background: white;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.coupon__inner input[type="submit"]:hover, .coupon__inner input[type="submit"]:focus {
  background: #54bae2;
  color: white;
}

.cart-total__info {
  padding: 20px 0 25px;
  border-bottom: 1px solid  #eeeeee;
}

.cart-total__info table {
  width: 100%;
}

.cart-total__info tr:first-child td {
  color: #00000a;
}

.cart-total__info td {
  line-height: 2.07;
  font-size: 14px;
  font-weight: 500;
  color: #676767;
}

.cart-total__info td:last-child {
  line-height: 1.06;
  letter-spacing: 2.3px;
  font-family: "BebasNeue", sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}

.order-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0 37px;
}

.order-total__title {
  font-size: 14px;
  font-weight: bold;
  line-height: 2.07;
  color: #00000a;
  text-transform: uppercase;
}

.order-total__price {
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 4px;
  font-family: "BebasNeue", sans-serif;
}

.proc-checkout {
  width: 100%;
}

.checkout-step {
  width: 230px;
  height: 3px;
  background: #9b9b9b;
  margin: -4px auto 77px;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .checkout-step {
    width: 315px;
  }
}

.checkout-step__item {
  width: 16px;
  height: 16px;
  background: white;
  display: block;
  position: absolute;
  background: #9b9b9b;
  border-radius: 50%;
  border: 1px solid white;
  top: -7px;
}

.checkout-step__item:first-child {
  left: -7px;
}

.checkout-step__item:nth-child(2) {
  left: 50%;
  transform: translateX(-50%);
}

.checkout-step__item:last-child {
  right: -7px;
}

.checkout-step__item.checkout-step-active {
  background: #00000a;
}

.checkout-step__item.checkout-step-active .checkout-step__name {
  color: #00000a;
}

.checkout-step__name {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  top: 31px;
  left: 50%;
  transform: translateX(-50%);
  color: #9b9b9b;
}

.checkout-step.active:before {
  display: block;
  position: absolute;
  content: "";
  height: 3px;
  width: 50%;
  background: #00000a;
  left: 0;
  top: 0;
}

.bs-details.cart {
  padding-bottom: 70px;
}

.bs-details.cart .cart-product {
  padding: 40px 20px;
}

@media only screen and (min-width: 576px) {
  .bs-details.cart .cart-product {
    padding: 40px 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .bs-details.cart .cart-product {
    padding: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .bs-details .form-elements__input {
    max-width: 420px;
  }
}

.bs-details .input-group-full:last-child {
  margin-bottom: 0;
}

.bs-details .input-group-full .form-elements__input {
  max-width: 100%;
}

.bs-details .input-group-full .form-elements__input:last-child {
  margin-bottom: 0;
}

.bs-details__info {
  font-size: 12px;
  font-weight: 500;
  color: #9b9b9b;
  padding: 13px 0 7px;
}

.order-products__label {
  font-size: 12px;
  font-weight: 500;
  color: #9b9b9b;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #eeeeee;
}

.order-products__edit {
  color: #54bae2;
}

.order-products__edit:hover .order-products__edit:focus {
  text-decoration: underline;
}

.order-products__inner {
  padding: 24px 0 40px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
}

.order-products__image {
  width: 80px;
  border: 1px solid #eeeeee;
  align-self: flex-start;
}

.order-products__desc {
  width: calc(100% - 100px);
}

.order-products__title {
  font-size: 14px;
  font-weight: 600;
  color: #00000a;
  line-height: 1.36;
  margin: 0 0 5px;
}

.order-products__info {
  font-size: 12px;
  font-weight: 500;
  color: #00000a;
}

.payment__label {
  text-transform: none;
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin: 5px 0 0;
}

@media only screen and (min-width: 576px) {
  .payment__label {
    margin: 0 0 0 21px;
    display: inline-block;
  }
}

.payment__method {
  margin-bottom: 38px;
}

.payment__method input {
  display: none;
}

.payment__method label {
  display: block;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .payment__method label {
    margin-right: 60px;
    margin-bottom: 0;
    display: inline-block;
  }
}

.payment__method label:last-child {
  margin: 0;
}

.payment__method-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background: transparent;
  border-radius: 4px;
  border: solid 1px #f2f2f2;
  cursor: pointer;
  padding: 20px;
  color: #9b9b9b;
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .payment__method-item {
    width: 230px;
  }
}

.payment__method-item img {
  margin-right: 20px;
  opacity: 0.8;
}

.payment__method input[type="radio"]:checked + .payment__method-item {
  background-color: #f9f9f9;
  border: solid 1px #f9f9f9;
  color: #00000a;
}

.payment__method input[type="radio"]:checked + .payment__method-item img {
  opacity: 1;
}

.payment__name span {
  color: #9b9b9b;
}

.payment .input-group {
  margin-bottom: 35px;
}

.payment .form-elements__input {
  max-width: 428px;
}

.payment__content {
  font-size: 12px;
  font-weight: 500;
  color: #9b9b9b;
  line-height: 1.58;
}

.credit {
  border-radius: 14px;
  background-color: #f5f5f5;
  padding: 32px 16px 24px;
  position: relative;
  width: 100%;
  max-width: 428px;
}

.credit * {
  font-variant-numeric: lining-nums;
}

@media only screen and (min-width: 576px) {
  .credit {
    padding: 32px 40px 24px;
  }
}

.credit__image {
  position: absolute;
  right: 20px;
  top: 32px;
  width: 60px;
}

@media only screen and (min-width: 576px) {
  .credit__image {
    width: 80px;
    right: 40px;
  }
}

.credit__title {
  font-size: 12px;
  font-weight: bold;
  color: #00000a;
  margin: 0 0 24px;
  text-transform: uppercase;
}

.credit__label {
  font-size: 12px;
  font-weight: normal;
  color: #777777;
  width: 100%;
  margin: 0 0 4px;
  display: block;
}

.credit__number {
  margin: 0 0 30px;
}

.credit__number input {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #00000a;
  border-bottom: solid 1px #d5d5d5;
  height: 28px;
  display: flex;
  align-items: center;
  max-width: 270px;
  width: 100%;
  background: transparent;
  letter-spacing: 0.5px;
}

.credit__number input::placeholder {
  color: #00000a;
}

@media only screen and (min-width: 576px) {
  .credit__number input {
    font-size: 19px;
  }
}

.credit__expiration {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
}

@media only screen and (min-width: 576px) {
  .credit__expiration {
    margin-bottom: 9px;
  }
}

@media only screen and (min-width: 576px) {
  .credit__expiration-item {
    min-width: 90px;
  }
}

.credit__expiration-item input {
  width: 27px;
  font-size: 14px;
  font-weight: 500;
  color: #00000a;
  background: transparent;
  border-bottom: solid 1px #d5d5d5;
  height: 20px;
}

.credit__expiration-item input::placeholder {
  color: #00000a;
}

.credit__expiration-item select {
  background: transparent;
  border-bottom: solid 1px #d5d5d5;
  height: 20px;
  width: 27px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
}

.credit__link {
  text-align: right;
  padding-right: 10px;
}

.credit__link a {
  color: #54bae2;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.42;
}

.credit__link a:hover, .credit__link a:focus {
  text-decoration: underline;
}

.credit-wrap-select {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  padding-right: 17px;
}

@media only screen and (min-width: 576px) {
  .credit-wrap-select {
    margin-right: 37px;
    padding-right: 25px;
  }
}

.credit-wrap-select:last-child {
  margin-right: 0;
}

.credit-wrap-select:before {
  display: block;
  position: absolute;
  background-image: url(../img/icons/select-arr.svg);
  background-repeat: no-repeat;
  background-size: 11px 13px;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}

@media only screen and (min-width: 576px) {
  .credit-wrap-select:before {
    background-size: 14px 15px;
  }
}

.credit.credit-data input {
  border-bottom: 0;
}

.credit.credit-data select {
  pointer-events: none;
  border: 0;
}

.credit.credit-data .credit-wrap-select:before {
  display: none;
}

.form-separate {
  margin: 44px 0 24px;
  height: 1px;
  background: #eeeeee;
}

hr.accent {
  height: 10px;
  background-color: #54bae2;
}

hr.grey {
  height: 10px;
  background-color: #fafafa;
}

.section-text {
  font-family: "TTWellingtons", sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

@media only screen and (min-width: 992px) {
  .section-text {
    font-size: 16px;
    line-height: 1.56;
  }
}

.contact-section {
  text-align: center;
  background-color: #54bae2;
  padding-top: 40px;
  padding-bottom: 47px;
  color: #ffffff;
}

.contact-section__type {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.44;
  display: block;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .contact-section__type {
    font-size: 16px;
    margin-bottom: 40px;
  }
}

.contact-section__icon {
  width: 89px;
  margin-bottom: 26px;
}

.contact-section__name {
  display: block;
  font-family: "BebasNeue", sans-serif;
  font-weight: normal;
  letter-spacing: 5.5px;
  font-size: 24px;
  margin-bottom: 4px;
}

@media only screen and (min-width: 768px) {
  .contact-section__name {
    font-size: 30px;
    margin-bottom: 40px;
  }
}

.contact-section__link {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .contact-section__link {
    font-size: 16px;
  }
}

.contact-section__link:hover {
  text-decoration: underline;
}

.contact {
  background: rgba(249, 249, 249, 0.8);
  padding: 40px 0 83px;
}

@media only screen and (min-width: 992px) {
  .contact {
    padding: 37px 0 110px;
  }
}

.contact .col-lg-6 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

@media only screen and (min-width: 992px) {
  .contact .col-lg-6 {
    margin-bottom: 0;
  }
}

.contact .col-lg-6:last-child {
  margin-bottom: 0;
}

.contact__title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.contact__inner {
  background: white;
  padding: 30px 20px 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media only screen and (min-width: 576px) {
  .contact__inner {
    padding: 32px 46px 40px;
  }
}

@media only screen and (min-width: 992px) {
  .contact__inner {
    padding: 31px 53px 54px;
    height: calc(100% - 45px);
  }
}

@media only screen and (min-width: 1200px) {
  .contact__inner {
    padding: 40px 67px 45px;
  }
}

.contact form {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .contact form .form-elements__input {
    max-width: 300px;
  }
}

.contact .form-wrap-submit {
  padding-top: 11px;
}

.contact .form-elements__submit {
  margin: 0;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .contact .form-elements__submit {
    width: 200px;
    margin: 0 0 0 auto;
  }
}

.contact__info-item {
  margin-bottom: 40px;
}

.contact__info-item:last-child {
  margin: 0;
}

.contact__info-item-inner {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(103, 103, 103, 0.3);
  border-bottom: 1px solid rgba(103, 103, 103, 0.3);
  padding: 30px 0 22px;
}

.contact__info-title {
  font-size: 12px;
  line-height: 17px;
  margin: 0 0 7px;
  color: #777777;
  font-weight: bold;
  text-transform: uppercase;
}

.contact__info-image {
  width: 70px;
  text-align: center;
  padding-top: 9px;
}

@media only screen and (min-width: 576px) {
  .contact__info-image {
    width: 125px;
  }
}

.contact__info-image img {
  width: 40px;
}

@media only screen and (min-width: 576px) {
  .contact__info-image img {
    width: auto;
  }
}

.contact__info-desc {
  width: calc(100% - 70px);
}

@media only screen and (min-width: 576px) {
  .contact__info-desc {
    width: calc(100% - 125px);
  }
}

.contact__info-phone {
  font-family: "BebasNeue", sans-serif;
  font-size: 26px;
  font-weight: normal;
  letter-spacing: 3.5px;
  color: #00000a;
  display: block;
  margin: 0 0 11px;
}

@media only screen and (min-width: 576px) {
  .contact__info-phone {
    font-size: 30px;
    letter-spacing: 5.5px;
  }
}

@media only screen and (min-width: 768px) {
  .contact__info-phone {
    letter-spacing: 4px;
  }
}

@media only screen and (min-width: 1200px) {
  .contact__info-phone {
    letter-spacing: 5.5px;
  }
}

.contact__info-mail {
  line-height: 1.69;
  color: #54bae2;
  font-size: 15px;
  font-weight: 500;
}

@media only screen and (min-width: 576px) {
  .contact__info-mail {
    font-size: 16px;
  }
}

.contact__info-mail:hover, .contact__info-mail:focus {
  text-decoration: underline;
}

.contact__info-date {
  margin-bottom: 20px;
}

.contact__info-date:last-child {
  margin: 0;
}

.contact__info-date p {
  margin: 0;
  font-size: 16px;
  line-height: 1.69;
  font-weight: normal;
}

.contact__info-date p.contact__info-time {
  font-weight: bold;
}

.contact--socials {
  padding-top: 16px;
}

.contact--socials li {
  margin-right: 20px;
}

.content {
  font-size: 14px;
  color: #4a4a4a;
}

.content ul {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) {
  .content ul {
    margin-bottom: 60px;
  }
}

.content ul li {
  padding-left: 27px;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.79;
}

@media only screen and (min-width: 1200px) {
  .content ul li {
    margin-bottom: 25px;
  }
}

.content ul li::before {
  content: '';
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-top: solid 1px #4a4a4a;
  border-right: solid 1px #4a4a4a;
  position: absolute;
  left: 0;
  top: 7px;
}

.content ol {
  counter-reset: item;
  list-style-type: none;
}

@media only screen and (min-width: 1200px) {
  .content ol {
    margin-bottom: 60px;
  }
}

.content ol li {
  padding-left: 33px;
  position: relative;
  line-height: 1.79;
  margin-bottom: 25px;
  font-weight: 500;
}

.content ol li::before {
  content: counter(item, decimal-leading-zero) "  ";
  counter-increment: item;
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  font-variant-numeric: lining-nums;
  line-height: 1.5;
  color: #777777;
  left: 0;
  letter-spacing: 1.3px;
}

.content ol li b {
  font-weight: 600;
}

.content a {
  color: #54bae2;
}

.content a:hover {
  color: #549ce2;
}

.content hr {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #eaeaea;
  height: 1px;
}

.content b {
  font-weight: 600;
}

.content p {
  line-height: 1.79;
  margin-bottom: 25px;
}

.content .iframe-video-wrapper {
  margin-top: 35px;
  margin-bottom: 35px;
}

.content p + .iframe-video-wrapper {
  margin-top: 10px;
}

@media only screen and (min-width: 992px) {
  .content p + .iframe-video-wrapper {
    margin-top: 70px;
  }
}

.iframe-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.iframe-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content + .content--remote-fitting {
  margin-top: 36px;
}

@media only screen and (min-width: 1200px) {
  .content + .content--remote-fitting {
    margin-top: 80px;
    padding-top: 50px;
    padding-bottom: 35px;
  }
}

.cta {
  font-family: "TTWellingtons", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  transition: all 0.2s ease;
}

.cta svg {
  transition: all 0.2s ease;
}

.cta:disabled {
  opacity: 0.3;
  cursor: default;
}

@media only screen and (min-width: 576px) {
  .cta {
    font-size: 16px;
  }
}

.cta--accent {
  background-color: #54bae2;
  color: #ffffff;
}

.cta--accent:hover:not(:disabled) {
  background-color: #549ce2;
}

.cta--white {
  background-color: #fff;
  color: #54bae2;
  font-weight: bold;
  border: solid 2px #fff;
}

.cta--white:hover:not(:disabled) {
  background-color: #54bae2;
  color: #fff;
}

.cta--black {
  background-color: #000;
  color: #ffffff;
  font-weight: 600;
}

.cta--black:hover:not(:disabled) {
  font-weight: 800;
}

.cta--border-accent {
  border: solid 2px #54bae2;
  color: #54bae2;
}

.cta--border-accent:hover:not(:disabled) {
  background-color: #54bae2;
  color: #fff;
}

.cta--border-white {
  border: solid 2px #fff;
  color: #fff;
}

.cta--border-white:hover:not(:disabled) {
  background-color: #fff;
  color: #54bae2;
}

.cta--border-black {
  border: solid 2px #000;
  color: #000;
}

.cta--border-black svg {
  fill: #000;
}

.cta--border-black:hover:not(:disabled) {
  background-color: #000;
  color: #fff;
}

.cta--border-black:hover:not(:disabled) svg {
  fill: #fff;
}

.cta--add-cart {
  border: solid 2px #000;
  color: #000;
  padding-left: 14px;
  padding-right: 12px;
  font-size: 12px;
  height: 40px;
  letter-spacing: normal;
}

@media only screen and (min-width: 576px) {
  .cta--add-cart {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) {
  .cta--add-cart {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.cta--add-cart svg {
  width: 12px;
  height: 12px;
  margin-right: 9px;
  fill: #000;
}

.cta--add-cart:hover:not(:disabled) {
  background-color: #54bae2;
  border-color: #54bae2;
  color: #fff;
}

.cta--add-cart:hover:not(:disabled) svg {
  fill: #fff;
}

.cta--add-cart-accent {
  color: #fff;
  background-color: #54bae2;
  padding: 0 10px;
  font-size: 12px;
  height: 50px;
  letter-spacing: normal;
  width: 150px;
}

@media only screen and (min-width: 576px) {
  .cta--add-cart-accent {
    width: 200px;
    font-size: 14px;
  }
}

.cta--add-cart-accent svg {
  width: 14px;
  height: 14px;
  margin-right: 20px;
  fill: #fff;
}

.cta--add-cart-accent:hover:not(:disabled) {
  background-color: #549ce2;
}

.cta--add-cart-accent:hover:not(:disabled) svg {
  fill: #fff;
}

.cta--40 {
  font-size: 12px;
  height: 40px;
  padding: 0 16px;
}

@media only screen and (min-width: 576px) {
  .cta--40 {
    font-size: 14px;
  }
}

.cta--50 {
  height: 50px;
}

.cta--60 {
  height: 60px;
}

.cta--w100 {
  width: 100%;
  padding: 0;
}

@media only screen and (min-width: 992px) {
  .cta--promo {
    min-width: 200px;
  }
}

.cta--filter svg {
  width: 14px;
  height: 18px;
  margin-right: 20px;
  margin-bottom: 2px;
}

.row > .col > .cta,
.row > [class*="col-"] > .cta {
  display: flex;
  padding: 0;
  width: 100%;
}

.custom-lab__choose-putter {
  margin-top: 30px;
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 34px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 576px) {
  .custom-lab__choose-putter {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .custom-lab__choose-putter {
    margin-top: 50px;
  }
}

.custom-lab__choose-putter-item {
  position: relative;
  height: 31vw;
}

@media only screen and (min-width: 576px) {
  .custom-lab__choose-putter-item {
    height: 180px;
  }
}

@media only screen and (min-width: 768px) {
  .custom-lab__choose-putter-item {
    height: 240px;
  }
}

@media only screen and (min-width: 992px) {
  .custom-lab__choose-putter-item {
    height: 300px;
  }
}

@media only screen and (min-width: 1200px) {
  .custom-lab__choose-putter-item {
    height: 380px;
  }
}

.custom-lab__choose-putter-item input[type=radio] {
  display: none;
}

.custom-lab__choose-putter-label {
  margin-left: 5px;
  margin-right: 5px;
  border: 10px solid transparent;
  display: block;
  height: 100%;
  transition: all 0.2s ease;
  cursor: pointer;
}

.custom-lab__choose-putter-label img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

input:checked + .custom-lab__choose-putter-label {
  border-color: #2F5C6E;
}

.custom-lab__choose-putter-title {
  position: absolute;
  top: 105%;
  left: 20px;
  right: 20px;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-family: "BebasNeue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 3px;
  transition: all 0.2s ease;
  user-select: none;
}

@media only screen and (min-width: 576px) {
  .custom-lab__choose-putter-title {
    top: auto;
    color: #00000a;
    bottom: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .custom-lab__choose-putter-title {
    font-size: 24px;
    bottom: 30px;
  }
}

@media only screen and (min-width: 992px) {
  .custom-lab__choose-putter-title {
    font-size: 30px;
    bottom: 46px;
  }
}

.custom-lab__choose-putter-title svg {
  width: 16px;
  height: 13px;
  fill: transparent;
  stroke: #fff;
  margin-right: 5px;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

@media only screen and (min-width: 576px) {
  .custom-lab__choose-putter-title svg {
    stroke: #000;
  }
}

@media only screen and (min-width: 768px) {
  .custom-lab__choose-putter-title svg {
    width: 24px;
    height: 24px;
    margin-right: 14px;
    margin-top: -1px;
  }
}

@media only screen and (min-width: 992px) {
  .custom-lab__choose-putter-title svg {
    margin-top: 2px;
  }
}

input:checked + .custom-lab__choose-putter-label .custom-lab__choose-putter-title {
  color: #54bae2;
}

input:checked + .custom-lab__choose-putter-label .custom-lab__choose-putter-title svg {
  stroke: #54bae2;
}

.custom-lab__steps-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__steps-wrapper {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 160px;
  }
}

@media only screen and (min-width: 1200px) {
  .custom-lab__steps-wrapper {
    margin-top: 13px;
  }
}

.custom-lab__step-img-wrapper {
  margin-bottom: 40px;
  height: 55vw;
  text-align: center;
  position: relative;
}

.custom-lab__step-img-wrapper img {
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .custom-lab__step-img-wrapper {
    height: auto;
    order: 2;
    width: 45%;
    margin-bottom: 0;
  }
  .custom-lab__step-img-wrapper img {
    height: auto;
    max-height: 100%;
  }
}

.custom-lab__step-img-inner {
  height: 55vw;
  cursor: zoom-in;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .custom-lab__step-img-inner {
    height: auto;
  }
}

.custom-lab__step-img-inner::after {
  content: '';
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background: url(../img/icons/icn-zoom-in-white.svg) center/contain no-repeat;
}

@media only screen and (max-width: 767px) {
  .custom-lab__step-img-wrapper.stick .custom-lab__step-img-inner {
    position: fixed;
    top: 100px;
    right: 18px;
    z-index: 1;
    width: 128px;
    height: 140px;
    border: solid 1px #979797;
    background-color: #000000;
  }
  .custom-lab__step-img-wrapper.stick .custom-lab__step-img-inner img {
    height: 100px;
  }
}

.custom-lab__step-img-wrapper.zoomed .custom-lab__step-img-inner {
  position: fixed;
  right: auto;
  cursor: zoom-out;
  width: 90vw;
  height: 80vh;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: solid 1px #979797;
  background-color: #000000;
}

.custom-lab__step-img-wrapper.zoomed .custom-lab__step-img-inner::after {
  background-image: url(../img/icons/icn-zoom-out-white.svg);
}

.custom-lab__step-img-wrapper.zoomed .custom-lab__step-img-inner img {
  height: auto;
  max-height: 90%;
  max-width: 80%;
}

@media only screen and (min-width: 768px) {
  .custom-lab__steps-list-wrapper {
    order: 1;
    width: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .custom-lab__steps-list-wrapper {
    margin-top: 140px;
  }
}

.custom-lab__steps-bottom {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) {
  .custom-lab__steps-bottom {
    margin-top: 30px;
  }
}

.custom-lab__step-item {
  margin-bottom: 10px;
  display: none;
}

.custom-lab__steps-bottom-status, .custom-lab__step-title {
  font-family: "BebasNeue", sans-serif;
  font-size: 18px;
  padding-left: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #414141;
  position: relative;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .custom-lab__steps-bottom-status, .custom-lab__step-title {
    font-size: 30px;
    padding-left: 40px;
  }
}

.custom-lab__steps-bottom-status::before, .custom-lab__step-title::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: solid 2px #414141;
  border-radius: 50%;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 768px) {
  .custom-lab__steps-bottom-status::before, .custom-lab__step-title::before {
    top: 5px;
    width: 24px;
    height: 24px;
  }
}

.custom-lab__step-item.completed .custom-lab__step-title {
  cursor: pointer;
}

.custom-lab__step-item.completed .custom-lab__step-title:hover {
  color: #54bae2;
}

.custom-lab__step-content {
  display: none;
  padding: 25px 10px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__step-content {
    padding: 40px 10px;
  }
}

.custom-lab__step-content-inner {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 0 0 10px #1F343C;
  border: solid 2px #54bae2;
  background-color: #272727;
  padding: 15px;
}

@media only screen and (min-width: 992px) {
  .custom-lab__step-content-inner {
    padding: 40px;
  }
}

.custom-lab__step-item.completed .custom-lab__step-title::after, .custom-lab__steps-bottom-status.completed::after {
  content: "\2713";
  font-size: 10px;
  position: absolute;
  left: 4px;
  top: 1px;
  transform: rotate(5deg);
}

@media only screen and (min-width: 768px) {
  .custom-lab__step-item.completed .custom-lab__step-title::after, .custom-lab__steps-bottom-status.completed::after {
    left: 6px;
    top: 7px;
    font-size: 15px;
  }
}

.custom-lab__step-item.active .custom-lab__step-title {
  color: #54bae2;
}

.custom-lab__step-item.active .custom-lab__step-title::before {
  border-color: #54bae2;
}

.custom-lab__step-item.active .custom-lab__step-title::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #54bae2;
  border-radius: 50%;
  left: 5px;
  top: 5px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__step-item.active .custom-lab__step-title::after {
    left: 8px;
    top: 13px;
    width: 8px;
    height: 8px;
  }
}

.custom-lab__step-lead {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__step-lead {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.custom-lab__step-descr {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #676767;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__step-descr {
    font-size: 14px;
    margin-bottom: 40px;
  }
}

.custom-lab__radio-buttons {
  margin-bottom: -15px;
}

.custom-lab__radio-buttons > * {
  width: 100%;
  margin-bottom: 15px;
  display: block;
}

@media only screen and (min-width: 576px) {
  .custom-lab__radio-buttons {
    display: flex;
    margin-left: -7px;
    margin-right: -7px;
  }
  .custom-lab__radio-buttons > * {
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .custom-lab__radio-buttons {
    margin-bottom: -30px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .custom-lab__radio-buttons > * {
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) {
  .custom-lab__radio-buttons--wrap {
    flex-wrap: wrap;
  }
  .custom-lab__radio-buttons--wrap > * {
    width: calc(50% - 15px);
  }
}

@media only screen and (min-width: 1200px) {
  .custom-lab__radio-buttons--wrap > * {
    width: calc(50% - 30px);
  }
}

.custom-lab__radio-btn input[type=radio] {
  display: none;
}

.custom-lab__radio-btn label {
  height: 50px;
  border: solid 1px #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 768px) {
  .custom-lab__radio-btn label {
    height: 90px;
  }
}

@media only screen and (min-width: 992px) {
  .custom-lab__radio-btn label {
    height: 70px;
  }
}

.custom-lab__radio-btn label small {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9b9b9b;
  font-variant-numeric: lining-nums;
}

@media only screen and (min-width: 768px) {
  .custom-lab__radio-btn label small {
    font-size: 12px;
  }
}

.custom-lab__radio-btn label span {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .custom-lab__radio-btn label span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) {
  .custom-lab__radio-btn label span {
    font-size: 16px;
  }
}

.custom-lab__radio-btn label:hover,
.custom-lab__radio-btn input[type=radio]:checked + label {
  background-color: #fff;
}

.custom-lab__radio-btn label:hover span,
.custom-lab__radio-btn input[type=radio]:checked + label span {
  color: #00000a;
}

.custom-lab__radio-btn--color label {
  height: 90px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__radio-btn--color label {
    height: 130px;
  }
}

.custom-lab__radio-btn--color i {
  display: block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-bottom: 13px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__radio-btn--color i {
    height: 45px;
    width: 45px;
  }
}

.custom-lab__step-label {
  color: #838383;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-bottom: 3px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__step-label {
    font-size: 14px;
  }
}

.custom-lab__step-range-wrapper {
  position: relative;
}

.custom-lab__step-range-next, .custom-lab__step-range-prev {
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: #fff;
  display: inline-block;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 20px;
}

.custom-lab__step-range-prev {
  left: 10px;
  transform: rotate(-135deg);
}

.custom-lab__step-range-next {
  right: 10px;
  transform: rotate(45deg);
}

.custom-lab__step-range-outer {
  padding-top: 25px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.custom-lab__step-range-inner {
  height: 60px;
}

.custom-lab__step-info {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #676767;
  font-variant-numeric: lining-nums;
  margin-top: 30px;
  padding-left: 25px;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .custom-lab__step-info {
    padding-left: 38px;
    font-size: 14px;
  }
}

.custom-lab__step-info::before {
  content: '';
  position: absolute;
  left: 0px;
  top: -1px;
  width: 16px;
  height: 16px;
  background: url(../img/icons/icn-info.svg) center/cover no-repeat;
}

@media only screen and (min-width: 576px) {
  .custom-lab__step-info::before {
    width: 24px;
    height: 24px;
    top: -3px;
  }
}

.custom-lab__step-info > * {
  display: block;
  margin-right: 12%;
}

@media only screen and (min-width: 576px) {
  .custom-lab__step-info > * {
    display: inline-block;
  }
}

.custom-lab__img-overlay {
  z-index: 10;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.custom-lab .noUi-pips-horizontal {
  height: 60px;
}

.custom-lab .noUi-target {
  height: 60px;
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.custom-lab .noUi-target::after {
  content: '';
  width: 100%;
  height: 3px;
  top: 0;
  background-color: #979797;
  position: absolute;
  left: 0;
}

.custom-lab .noUi-pips-horizontal {
  padding: 0;
  top: 0;
}

.custom-lab .noUi-handle {
  cursor: pointer;
  top: -18px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.15);
}

.custom-lab .noUi-handle::after, .custom-lab .noUi-handle::before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: #D7D7D7;
  background-color: transparent;
  content: '';
  display: inline-block;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 15px;
}

.custom-lab .noUi-handle::before {
  left: 9px;
  right: auto;
  transform: rotate(-135deg);
}

.custom-lab .noUi-handle::after {
  right: 9px;
  left: auto;
  transform: rotate(45deg);
}

.custom-lab .noUi-marker-horizontal.noUi-marker-sub {
  height: 15px;
  background-color: #979797;
}

.custom-lab .noUi-value {
  top: 26px;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  font-variant-numeric: lining-nums;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 768px) {
  .custom-lab .noUi-value {
    font-size: 16px;
  }
}

.custom-lab .noUi-value.active {
  color: #fff;
  font-size: 12px;
  top: 20px;
}

@media only screen and (min-width: 768px) {
  .custom-lab .noUi-value.active {
    top: 18px;
    font-size: 20px;
  }
}

.custom-lab .noUi-value-large {
  display: none;
}

.custom-lab .noUi-marker-large,
.custom-lab .noUi-marker-small,
.custom-lab .noUi-value-large,
.custom-lab .noUi-value-small {
  display: none;
}

.custom-lab .noUi-background {
  background: transparent;
  box-shadow: none;
}

.price {
  position: fixed;
  top: 100px;
  right: 100px;
  color: #fff;
}

.custom-lab__summary-wrapper {
  font-variant-numeric: lining-nums;
  background-color: #272727;
  padding-top: 16px;
  padding-bottom: 45px;
  transform: translateY(100%);
  position: fixed;
  z-index: 5;
  bottom: 60px;
  left: 0;
  right: 0;
  transition: all .4s ease;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-wrapper {
    padding-top: 40px;
    bottom: 100px;
  }
}

.custom-lab__summary-wrapper.opened {
  padding-top: 30px;
  bottom: 0;
  transform: translateY(0);
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-wrapper.opened {
    padding-top: 40px;
  }
}

.custom-lab__summary {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary {
    display: flex;
    justify-content: space-between;
  }
}

.custom-lab__summary button[type=submit] {
  display: block;
  margin-top: 24px;
  width: 100%;
}

.custom-lab__summary-lead {
  font-family: "TTWellingtons", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-lead {
    font-size: 16px;
  }
}

.custom-lab__summary-lead b {
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-steps {
    width: 65%;
  }
}

.custom-lab__summary-steps-list {
  padding-top: 15px;
  display: none;
  padding-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-steps-list {
    padding-bottom: 0;
    padding-top: 32px;
    columns: 2;
  }
}

.custom-lab__summary-steps-list li {
  font-family: "TTWellingtons", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #7d7d7d;
  padding-left: 30px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-steps-list li {
    padding-left: 40px;
    font-size: 16px;
  }
}

.custom-lab__summary-steps-list li::before {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 59%;
  background-color: #7d7d7d;
  position: absolute;
  left: 14px;
  top: 7px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-steps-list li::before {
    top: 12px;
  }
}

.custom-lab__summary-steps-list li.completed {
  color: #fff;
}

.custom-lab__summary-steps-list li.completed::before {
  display: none;
}

.custom-lab__summary-steps-list li.completed::after {
  content: "\2713";
  position: absolute;
  left: 10px;
  top: 0;
  transform: rotate(10deg);
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-cart-block {
    width: 30%;
  }
}

.custom-lab__summary-toggle {
  position: absolute;
  z-index: 5;
  right: 0;
  top: -7px;
  width: 44px;
  height: 44px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__summary-toggle {
    top: -13px;
    right: -13px;
  }
}

.custom-lab__summary-toggle svg {
  fill: transparent;
  stroke: #54bae2;
  width: 100%;
  height: 100%;
  transition: all .4s ease;
}

.custom-lab__summary-toggle:hover svg {
  stroke: #549ce2;
}

.custom-lab__summary-wrapper.opened .custom-lab__summary-toggle svg {
  transform: rotate(180deg);
}

.custom-lab__price-block, .custom-lab__quantity-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-lab__price-block span, .custom-lab__quantity-block span {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
}

.custom-lab__summary-price {
  user-select: none;
  font-family: "BebasNeue", sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 4.1px;
  text-align: right;
  color: #ffffff;
}

.custom-lab__quantity-block {
  margin-top: 50px;
  margin-bottom: 16px;
}

.custom-lab__quantity-block .wrap-number {
  margin-top: 0;
}

.custom-lab__quantity-block .wrap-number input {
  text-align: center;
  color: #ffffff !important;
  background-color: transparent;
}

.custom-lab__quantity-block .wrap-number .number-minus,
.custom-lab__quantity-block .wrap-number .number-plus {
  width: 22px;
  line-height: 22px;
  height: 22px;
  font-size: 22px;
}

.custom-lab__add-to-cart-block {
  display: none;
}

.custom-lab {
  height: 80px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .custom-lab {
    height: 110px;
  }
}

.custom-lab--without-btn {
  height: 0;
}

.custom-lab__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.custom-lab.opening .custom-lab__wrapper,
.custom-lab.closing .custom-lab__wrapper {
  z-index: 1000 !important;
}

.custom-lab.opened .custom-lab__wrapper {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.custom-lab__top {
  height: 80px;
  background-color: #54bae2;
  position: relative;
  z-index: 5;
  transition: all .8s ease;
}

@media only screen and (min-width: 768px) {
  .custom-lab__top {
    height: 110px;
  }
}

.custom-lab__top > * {
  height: 100%;
}

.custom-lab--without-btn .custom-lab__top {
  height: 0;
  opacity: 0;
}

.custom-lab--without-btn.opening .custom-lab__top,
.custom-lab--without-btn.opened .custom-lab__top {
  height: 80px;
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .custom-lab--without-btn.opening .custom-lab__top,
  .custom-lab--without-btn.opened .custom-lab__top {
    height: 110px;
  }
}

.custom-lab__overlay {
  opacity: 0.4;
  background-color: #000000;
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
}

.custom-lab__top-closed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.custom-lab--without-btn .custom-lab__top-closed {
  display: none !important;
}

.custom-lab__top-opened {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.custom-lab.stick:not(.opened) .custom-lab__wrapper {
  top: auto;
  position: fixed;
  z-index: 10;
}

.custom-lab__inner {
  display: none;
  background-color: #111;
  overflow-y: auto;
  height: calc(100vh - 80px);
}

@media only screen and (min-width: 768px) {
  .custom-lab__inner {
    height: calc(100vh - 110px);
  }
}

.custom-lab__inner::-webkit-scrollbar {
  -webkit-appearance: none;
}

.custom-lab__inner::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.custom-lab__top-text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.custom-lab__top-text h6 {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__top-text h6 {
    letter-spacing: 4.4px;
    font-size: 24px;
  }
}

.custom-lab__top-text p {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .custom-lab__top-text p {
    font-size: 16px;
  }
}

.custom-lab__top-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

@media only screen and (min-width: 576px) {
  .custom-lab__top-logo {
    width: 55px;
    height: 55px;
  }
}

@media only screen and (min-width: 768px) {
  .custom-lab__top-logo {
    width: 70px;
    height: 70px;
  }
}

.custom-lab__close-btn {
  font-size: 0;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

@media only screen and (min-width: 576px) {
  .custom-lab__close-btn {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .custom-lab__close-btn {
    font-size: 16px;
  }
}

.custom-lab__close-btn:hover {
  color: #181818;
}

.custom-lab__close-btn span {
  margin-left: 13px;
  font-size: 16px;
}

@media only screen and (min-width: 992px) {
  .custom-lab__close-btn span {
    font-size: 22px;
  }
}

.custom-lab__title {
  margin-top: 65px;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 4px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 7px;
}

@media only screen and (min-width: 576px) {
  .custom-lab__title {
    font-size: 38px;
  }
}

@media only screen and (min-width: 992px) {
  .custom-lab__title {
    font-size: 50px;
  }
}

.custom-lab__subtitle {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #9b9b9b;
}

@media only screen and (min-width: 576px) {
  .custom-lab__subtitle {
    font-size: 16px;
  }
}

.custom-lab__next-step {
  font-family: "BebasNeue", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
  text-align: center;
  color: #54bae2;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .custom-lab__next-step {
    font-size: 30px;
  }
}

.custom-lab__next-step:hover:not(:disabled) {
  color: #e1f7ff;
}

.custom-lab__next-step:disabled {
  cursor: default;
  color: #414141;
}

.custom-lab__steps-overview {
  margin-top: 44px;
  display: flex;
  justify-content: center;
}

.custom-lab__steps-overview li {
  font-family: "BebasNeue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: #414141;
  text-transform: uppercase;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .custom-lab__steps-overview li {
    font-size: 30px;
  }
}

.custom-lab [data-lab-screen] {
  display: none;
  padding-bottom: 200px;
}

@media only screen and (min-width: 768px) {
  .custom-lab [data-lab-screen] {
    padding-bottom: 50px;
  }
}

.events-list {
  padding-top: 30px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) {
  .events-list {
    padding-top: 60px;
  }
}

.events-list__grid {
  border-bottom: 1px solid #E4E4E4;
  margin-bottom: 60px;
}

.events-list__item {
  max-width: 414px;
  margin: 0 auto 50px;
}

@media only screen and (min-width: 576px) {
  .events-list__item {
    max-width: none;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .events-list__item {
    margin-bottom: 60px;
  }
}

.events-list__item-img {
  height: 238px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

@media only screen and (min-width: 768px) {
  .events-list__item-img {
    height: 145px;
  }
}

@media only screen and (min-width: 1200px) {
  .events-list__item-img {
    height: 175px;
  }
}

.events-list__item-content {
  padding-top: 30px;
}

@media only screen and (min-width: 768px) {
  .events-list__item-content {
    padding-top: 0;
  }
}

.events-list__item-title {
  font-family: "BebasNeue", sans-serif;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 2.9px;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
}

@media only screen and (min-width: 576px) {
  .events-list__item-title {
    font-size: 26px;
  }
}

@media only screen and (min-width: 992px) {
  .events-list__item-title {
    font-size: 30px;
    letter-spacing: 3.3px;
  }
}

.events-list__item-title:hover {
  color: #54bae2;
}

.events-list__item-date {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.64;
  color: #777777;
  display: block;
  font-variant-numeric: lining-nums;
  margin-bottom: 10px;
}

.events-list__item-descr {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.64;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.events-slider {
  background-color: #fafafa;
  padding: 30px 0;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .events-slider {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .events-slider {
    padding: 50px 0;
  }
}

.events-slider__item {
  height: 250px;
  background-color: #709F2B;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.events-slider__item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.events-slider__item-content {
  position: absolute;
  z-index: 1;
  bottom: 28px;
  right: 15px;
  left: 32px;
  color: #fff;
}

@media only screen and (min-width: 576px) {
  .events-slider__item-content {
    left: 64px;
  }
}

.events-slider__item-type {
  font-family: "BebasNeue", sans-serif;
  font-size: 24px;
  letter-spacing: 3.3px;
  font-weight: normal;
  display: block;
  margin-bottom: 2px;
  text-transform: uppercase;
}

@media only screen and (min-width: 576px) {
  .events-slider__item-type {
    font-size: 30px;
  }
}

.events-slider__item-title {
  font-family: "BebasNeue", sans-serif;
  font-size: 34px;
  font-weight: normal;
  letter-spacing: 4.4px;
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
}

@media only screen and (min-width: 576px) {
  .events-slider__item-title {
    font-size: 40px;
  }
}

.events-slider__item-date {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.44;
  text-transform: uppercase;
  margin-bottom: 30px;
  display: block;
  font-variant-numeric: lining-nums;
}

@media only screen and (min-width: 576px) {
  .events-slider__item-date {
    font-size: 16px;
  }
}

.events-slider .slider-dots {
  bottom: 21px;
}

.footer {
  margin-top: auto;
  padding-top: 25px;
  padding-bottom: 40px;
  background-color: #181818;
}

.footer__col-title {
  margin-top: 27px;
  display: block;
  font-family: "BebasNeue", sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 4px;
  color: #ffffff;
  margin-bottom: 11px;
}

@media only screen and (min-width: 576px) {
  .footer__col-title {
    font-size: 18px;
  }
}

.footer__nav-list a {
  color: #797979;
  line-height: 2.6;
  font-size: 12px;
  font-weight: bold;
}

.footer__nav-list a:hover {
  color: #54bae2;
}

.footer__nav-list + .footer__col-title {
  margin-top: 47px;
}

.footer__register {
  margin-top: 20px;
  max-width: 174px;
}

.footer__logo {
  display: block;
  margin-top: 61px;
}

.footer__copyright {
  font-size: 12px;
  text-align: center;
  color: #797979;
  margin-bottom: 20px;
  line-height: 1.5;
}

@media only screen and (min-width: 992px) {
  .footer__copyright {
    text-align: left;
    margin-bottom: 0;
  }
}

.footer__copyright a {
  color: #797979;
  text-decoration: underline;
}

.footer__copyright a:hover {
  color: #54bae2;
}

.footer__bottom-links {
  text-align: center;
  font-size: 12px;
  color: #797979;
  line-height: 1.5;
}

@media only screen and (min-width: 992px) {
  .footer__bottom-links {
    text-align: right;
  }
}

.footer__bottom-links a {
  color: #797979;
  margin: 0 3px;
}

.footer__bottom-links a:hover {
  color: #54bae2;
}

.footer hr {
  margin-bottom: 25px;
  margin-top: 50px;
  height: 1px;
  background-color: #2d2d2d;
}

@media only screen and (min-width: 992px) {
  .footer hr {
    margin-top: 60px;
  }
}

.input-group {
  display: block;
  margin: 0 0 20px;
}

.form-elements__label {
  font-size: 12px;
  color: #777777;
  font-weight: bold;
  display: block;
  margin: 0 0 10px;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
}

.input-group .form-elements__input:last-child {
  margin: 0;
}

.form-elements__input,
.form-elements__textarea,
.form-elements__select {
  width: 100%;
  display: block;
  border: 1px solid #676767;
  padding: 15px 18px;
  color: #000000;
  font-size: 14px;
  font-weight: normal;
}

.form-elements__input::placeholder,
.form-elements__textarea::placeholder,
.form-elements__select::placeholder {
  color: #777777;
}

.form-elements__input:disabled,
.form-elements__textarea:disabled,
.form-elements__select:disabled {
  opacity: 0.3;
}

.form-elements__input:focus,
.form-elements__textarea:focus,
.form-elements__select:focus {
  border: 1px solid #54bae2;
}

.form-elements__select {
  appearance: none;
  -webkit-appearance: textfield;
  font-weight: 500;
  background-image: url(../img/icons/select-arr.svg);
  background-repeat: no-repeat;
  background-size: 14px 15px;
  background-position: 92% 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 18px;
  margin-bottom: 20px;
}

.form-elements__select::placeholder {
  color: #00000a;
  font-weight: 500;
}

.form-elements__select:last-child {
  margin-bottom: 0;
}

.form-elements__textarea {
  resize: none;
  height: 100px;
}

body .input-group-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

body .input-group-2 .form-elements__input,
body .input-group-2 .form-elements__select {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  body .input-group-2 .form-elements__input,
  body .input-group-2 .form-elements__select {
    max-width: 35%;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1200px) {
  body .input-group-2 .form-elements__input,
  body .input-group-2 .form-elements__select {
    max-width: 36%;
  }
}

body .input-group-2 .form-elements__input:last-child,
body .input-group-2 .form-elements__select:last-child {
  margin-right: 0;
}

body .input-group-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

body .input-group-3 .form-elements__input,
body .input-group-3 .form-elements__select {
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  body .input-group-3 .form-elements__input,
  body .input-group-3 .form-elements__select {
    max-width: 35%;
  }
}

@media only screen and (min-width: 1200px) {
  body .input-group-3 .form-elements__input,
  body .input-group-3 .form-elements__select {
    max-width: 36%;
  }
}

body .input-group-3 .form-elements__input:last-child,
body .input-group-3 .form-elements__select:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 768px) {
  body .input-group-3 .form-elements__input:last-child,
  body .input-group-3 .form-elements__select:last-child {
    max-width: 22%;
  }
}

.checkbox-wrap {
  cursor: pointer;
}

.checkbox-wrap input[type="checkbox"] {
  display: none;
}

.checkbox__inner {
  display: inline-flex;
  align-items: center;
  height: 21px;
  position: relative;
  padding: 0 0 0 39px;
  font-size: 14px;
  color: #00000a;
}

.checkbox__inner:before {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 21px;
  height: 21px;
  content: "";
  border: solid 1px #676767;
}

.checkbox-wrap input[type="checkbox"]:checked + .checkbox__inner:after {
  display: block;
  position: absolute;
  content: "";
  left: 7px;
  top: 44%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  width: 7px;
  height: 13px;
  border-bottom: 2px solid #676767;
  border-right: 2px solid #676767;
}

.form-submit__full {
  margin-bottom: 20px;
}

.form-submit__full .form-elements__submit {
  width: 100%;
}

.form-submit__full:last-child {
  margin-bottom: 0;
}

.forgot-password {
  margin: 0 0 20px;
}

.forgot-password a {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #54bae2;
  transition: 0.3s ease;
  line-height: 2.42;
}

.forgot-password a:hover, .forgot-password a:focus {
  text-decoration: underline;
}

.forgot-password p {
  font-size: 12px;
  color: #777777;
  margin: 0;
  padding: 5px 0;
}

.input-group--password {
  position: relative;
}

.input-group--password .show-password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  font-weight: 500;
  color: #54bae2;
  transition: 0.3s ease;
  text-transform: uppercase;
  right: 22px;
  cursor: pointer;
}

.input-group--password .show-password:hover, .input-group--password .show-password:focus {
  color: #549ce2;
}

.wrap-number {
  margin: 23px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wrap-number input[type="number"] {
  appearance: none;
  -moz-appearance: textfield;
  font-size: 20px;
  letter-spacing: 1.3px;
  color: #00000a;
  border: 0;
  width: 36px;
  padding: 0;
  margin-bottom: 0;
  text-align: center;
  height: auto;
  line-height: 1;
  font-family: "BebasNeue", sans-serif;
  font-weight: normal;
  user-select: none;
}

.wrap-number .number-minus,
.wrap-number .number-plus {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  text-align: center;
  line-height: 15px;
  cursor: pointer;
  border: solid 1px #676767;
  font-size: 16px;
  color: #676767;
  border-radius: 50%;
  user-select: none;
}

.wrap-number .number-minus:hover,
.wrap-number .number-plus:hover {
  border-color: #54bae2;
  color: #54bae2;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="submit"],
button[type="submit"] {
  cursor: pointer;
}

select {
  appearance: textfield;
}

.input-group.input-group-full input {
  max-width: 100%;
  margin-bottom: 20px;
}

.form__input {
  font: inherit;
  width: 100%;
  border: solid 1px #676767;
  transition: all 0.2s ease;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  padding-left: 18px;
  padding-right: 18px;
  color: #181818;
  margin-bottom: 20px;
}

.form__input:focus {
  border-color: #54bae2;
}

.form__note {
  color: #9b9b9b;
  text-align: center;
  margin-top: 30px;
  font-size: 12px;
  line-height: 1.58;
}

.form__title {
  font-family: "BebasNeue", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;
  display: block;
  color: #00000a;
  margin-bottom: 27px;
}

.form__textarea {
  font: inherit;
  width: 100%;
  border: solid 1px #676767;
  transition: all 0.2s ease;
  height: 78px;
  font-size: 14px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #181818;
  margin-bottom: 20px;
  line-height: 1.6;
  resize: none;
}

.form__textarea:focus {
  border-color: #54bae2;
}

.form__small-title {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #00000a;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .form__small-title {
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.form--become-fitter {
  border: 7px solid #fafafa;
  padding-top: 39px;
  padding-bottom: 32px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) {
  .form--become-fitter {
    border-width: 10px;
  }
}

.form--review {
  padding-top: 63px;
  padding-bottom: 65px;
  background-color: #fafafa;
}

.form--review .form__input {
  height: 40px;
  line-height: 40px;
}

.form--review .form__input,
.form--review .form__textarea {
  margin-bottom: 25px;
}

.form--reply {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.form--reply .form__small-title {
  width: 100%;
}

.form--reply .form__textarea {
  width: 100%;
  height: 80px;
}

@media only screen and (min-width: 768px) {
  .form--reply .form__textarea {
    width: 70%;
    height: 50px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 992px) {
  .form--reply .form__textarea {
    width: 80%;
  }
}

.form--reply .cta {
  width: 200px;
}

@media only screen and (min-width: 768px) {
  .form--reply .cta {
    width: calc(30% - 15px);
  }
}

@media only screen and (min-width: 992px) {
  .form--reply .cta {
    width: calc(20% - 15px);
  }
}

.help__group {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .help__group {
    margin-top: 50px;
  }
}

.help__group:last-child {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) {
  .help__group:last-child {
    margin-bottom: 40px;
  }
}

.help__title {
  margin-bottom: 20px;
}

.help .cta {
  margin-top: 40px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) {
  .help .cta {
    margin-top: 60px;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) {
  .inquire-cards__list {
    margin: 0 -15px;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) {
  .inquire-cards__item {
    margin: 0 15px;
    max-width: 705px;
  }
}

@media only screen and (min-width: 1200px) {
  .inquire-cards__item:first-child .inquire-cards__item-header,
  .inquire-cards__item:first-child .inquire-cards__item-descr {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1440px) {
  .inquire-cards__item:first-child .inquire-cards__item-header,
  .inquire-cards__item:first-child .inquire-cards__item-descr {
    padding-left: 150px;
    padding-right: 98px;
  }
}

@media only screen and (min-width: 1200px) {
  .inquire-cards__item:last-child .inquire-cards__item-header,
  .inquire-cards__item:last-child .inquire-cards__item-descr {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1440px) {
  .inquire-cards__item:last-child .inquire-cards__item-header,
  .inquire-cards__item:last-child .inquire-cards__item-descr {
    padding-right: 150px;
    padding-left: 98px;
  }
}

.inquire-cards__item-img {
  height: 250px;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .inquire-cards__item-img {
    height: 433px;
  }
}

.inquire-cards__item-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center top;
  object-position: center top;
  font-family: "object-fit: cover; object-position: center top";
}

.inquire-cards__item-header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 15px;
}

.inquire-cards__item-header-inner {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 576px) {
  .inquire-cards__item-header-inner {
    height: 95px;
  }
}

@media only screen and (min-width: 768px) {
  .inquire-cards__item-header-inner {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .inquire-cards__item-header-inner {
    max-width: 792px;
  }
}

.inquire-cards__item-title {
  margin-top: 4px;
}

.inquire-cards__item-descr {
  padding: 32px 15px;
  font-size: 14px;
  line-height: 1.64;
  background-color: #fafafa;
}

@media only screen and (min-width: 768px) {
  .inquire-cards__item-descr p {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .inquire-cards__item-descr p {
    max-width: 792px;
  }
}

@media only screen and (min-width: 1200px) {
  .inquire-cards .cta {
    width: 165px;
  }
}

.lab-preview {
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 576px) {
  .lab-preview {
    padding: 104px 0;
  }
}

@media only screen and (min-width: 992px) {
  .lab-preview {
    padding: 95px 0 140px;
  }
}

@media only screen and (min-width: 1200px) {
  .lab-preview {
    padding-top: 150px;
    padding-bottom: 167px;
    margin-left: -15px;
  }
}

.lab-preview__inner {
  max-width: 320px;
}

@media only screen and (min-width: 576px) {
  .lab-preview__inner {
    width: 510px;
    max-width: none;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .lab-preview__inner {
    width: 790px;
    padding-right: 290px;
  }
}

@media only screen and (min-width: 1200px) {
  .lab-preview__inner {
    width: auto;
  }
}

.lab-preview__title {
  margin-top: 0;
  margin-bottom: 10px;
}

@media only screen and (min-width: 576px) {
  .lab-preview__title {
    margin-bottom: 14px;
  }
}

.lab-preview__sub-title {
  color: #bbb;
  margin-bottom: 50px;
}

@media only screen and (min-width: 576px) {
  .lab-preview__sub-title {
    margin-bottom: 60px;
  }
}

.lab-preview__img {
  position: absolute;
  left: 190px;
  bottom: 50px;
  z-index: -1;
  height: 150px;
}

@media (min-width: 414px) {
  .lab-preview__img {
    left: 220px;
    height: 230px;
  }
}

@media only screen and (min-width: 576px) {
  .lab-preview__img {
    top: 155px;
    bottom: auto;
    left: auto;
    height: 270px;
    right: -140px;
  }
}

@media (min-width: 660px) {
  .lab-preview__img {
    right: -190px;
    height: 330px;
    top: 100px;
  }
}

@media only screen and (min-width: 768px) {
  .lab-preview__img {
    right: -210px;
    height: 400px;
    top: 60px;
  }
}

@media only screen and (min-width: 992px) {
  .lab-preview__img {
    top: 42px;
    right: -190px;
    height: 434px;
  }
}

@media only screen and (min-width: 1200px) {
  .lab-preview__img {
    top: 48px;
    right: -40px;
  }
}

@media (min-width: 1440px) {
  .lab-preview__img {
    right: 0;
  }
}

.lab-preview .cta {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) {
  .lab-preview .cta {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.location-filter {
  margin-top: 46px;
}

@media only screen and (min-width: 768px) {
  .location-filter {
    margin-top: 64px;
  }
}

@media only screen and (min-width: 992px) {
  .location-filter__country-wrapper {
    display: none;
  }
  .location-filter__country-wrapper.active {
    display: block;
  }
}

.location-filter__country-wrapper .location-filter__select-wrapper {
  display: none;
}

.location-filter__country-wrapper.active .location-filter__select-wrapper {
  display: block;
}

.location-filter__select-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) {
  .location-filter__select-wrapper--city {
    display: none !important;
  }
}

.locations-list__item {
  padding-left: 24px;
  padding-top: 21px;
  padding-bottom: 9px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  transition: background-color .3s ease;
}

@media only screen and (min-width: 1200px) {
  .locations-list__item {
    padding-bottom: 20px;
  }
}

.locations-list__item.highlighted {
  background-color: rgba(84, 186, 226, 0.2);
}

.locations-list__item:not(:last-child) {
  border-bottom: solid 1px #e6e6e6;
}

.locations-list__item-text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  color: #4a4a4a;
  flex-grow: 1;
}

@media only screen and (min-width: 1200px) {
  .locations-list__item-text {
    font-size: 14px;
    line-height: 1.5;
  }
}

.locations-list__item-text b {
  font-weight: 500;
}

.locations-list__item-text a,
.locations-list__item-text a:visited {
  color: #54bae2;
}

.locations-list__item-text a:hover {
  color: #549ce2;
}

.locations-list__item-side {
  margin-left: 15px;
}

.locations-list__item-btn {
  display: block;
  margin-bottom: 15px;
}

.locations-list__item-btn svg {
  width: 30px;
  height: 36px;
  fill: #9b9b9b;
  transition: all 0.2s ease;
}

.locations-list__item-btn:hover svg {
  fill: #54bae2;
}

.locations-list__item-distance {
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  color: #000000;
  font-weight: bold;
  text-align: center;
}

.locations-list__item-distance b {
  display: block;
  font-size: 20px;
  font-family: "BebasNeue", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
}

.admin-bar .main-header {
  top: 46px;
}

@media only screen and (min-width: 768px) {
  .admin-bar .main-header {
    top: 32px;
  }
}

.main-header {
  min-width: 320px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  padding-left: 15px;
  padding-right: 15px;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 992px) {
  .main-header {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1200px) {
  .main-header {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.scrolled .main-header {
  box-shadow: 0 8px 16px -5px rgba(0, 0, 0, 0.54);
}

.main-header__mock {
  height: 100px;
  transition: all 0.2s ease;
}

.scrolled .main-header__mock {
  height: 50px;
}

@media only screen and (min-width: 992px) {
  .main-header__mock {
    order: 1;
  }
}

.main-header__inner {
  align-items: center;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
}

.main-header__logo {
  margin-right: auto;
}

@media only screen and (min-width: 992px) {
  .main-header__logo {
    order: 2;
    margin-right: 0;
  }
}

.main-header__cart-btn {
  border: 2px solid #000;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media only screen and (min-width: 992px) {
  .main-header__cart-btn {
    order: 4;
    margin-left: 92px;
  }
}

.main-header__cart-btn svg {
  width: 12px;
  height: 12px;
}

.main-header__cart-btn:hover {
  border-color: #54bae2;
}

.main-header__cart-btn:hover svg {
  fill: #54bae2;
}

.main-header__cart-count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  border: solid 2px #54bae2;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: normal;
  color: #54bae2;
  border-radius: 50%;
  top: 4px;
  left: -13px;
  padding-top: 2px;
  font-variant-numeric: lining-nums;
}

.mob-menu-btn {
  margin-left: 16px;
  position: relative;
  width: 32px;
  height: 32px;
  border: 2px solid #000;
  border-radius: 50%;
}

@media only screen and (min-width: 576px) {
  .mob-menu-btn {
    margin-left: 32px;
  }
}

@media only screen and (min-width: 992px) {
  .mob-menu-btn {
    display: none;
  }
}

.mob-menu-btn .sw-topper,
.mob-menu-btn .sw-footer,
.mob-menu-btn .sw-bottom {
  margin: 0 auto;
  display: block;
  position: relative;
  width: 14px;
  height: 2px;
  background-color: #000;
  border: none;
  border-radius: 4px 4px 4px 4px;
}

.mob-menu-btn .sw-topper {
  top: -2px;
  transition: transform 0.5s, top 0.2s;
  transition-delay: 0.2s, 0s;
}

.mob-menu-btn .sw-bottom {
  top: 0px;
  transition: transform 0.5s, top 0.2s;
  transition-delay: 0.2s, 0s;
}

.mob-menu-btn .sw-footer {
  top: 2px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.mob-menu-btn.active .sw-topper {
  top: 2px;
  transform: rotate(-45deg);
}

.mob-menu-btn.active .sw-bottom {
  transform: rotate(45deg);
}

.mob-menu-btn.active .sw-footer {
  opacity: 0;
  top: 0;
  transform: rotate(180deg);
}

.main-nav {
  width: 100%;
  position: relative;
  display: none;
}

@media only screen and (min-width: 992px) {
  .main-nav {
    order: 3;
    display: block !important;
    width: auto;
    margin: 0 auto;
  }
}

.main-nav::before {
  content: '';
  position: absolute;
  display: block;
  left: -20px;
  right: -20px;
  top: 0;
  height: 1px;
  background-color: #bbbbbb;
}

@media only screen and (min-width: 992px) {
  .main-nav::before {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .main-nav > ul {
    display: flex;
  }
}

.main-nav > ul > li {
  border-bottom: 1px solid #d8d8d8;
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .main-nav > ul > li {
    border-bottom: none;
  }
}

.main-nav > ul > li > a {
  display: inline-block;
  padding: 18px 17px 17px;
  font-family: "BebasNeue", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.7px;
  text-align: center;
  color: #000000;
  transition: all 0.2s ease;
}

.main-nav > ul > li > a:hover {
  color: #54bae2;
}

@media only screen and (min-width: 576px) {
  .main-nav > ul > li > a {
    padding: 26px 25px 25px;
    font-size: 24px;
  }
}

@media only screen and (min-width: 992px) {
  .main-nav > ul > li > a {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #4a4a4a;
    padding: 0;
    margin: 0 25px;
  }
}

.map {
  padding-bottom: 30px;
  margin-top: 30px;
}

@media only screen and (min-width: 576px) {
  .map {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) {
  .map {
    margin-top: 64px;
  }
}

.map__search-box {
  margin-bottom: 20px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .map__search-box {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 992px) {
  .map__search-box {
    max-width: 610px;
  }
}

.map__search-box::after {
  content: '';
  position: absolute;
  right: 10px;
  bottom: 18px;
  width: 16px;
  height: 16px;
  background: url(../img/icons/icon-search.svg) center/cover no-repeat;
}

.map__search-box label {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: normal;
  color: #797979;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .map__search-box label {
    margin-bottom: 15px;
  }
}

.map__search-box input {
  font-family: inherit;
  height: 50px;
  border: solid 1px #676767;
  width: 100%;
  padding-left: 20px;
  font-size: 14px;
  color: #181818;
}

.map__search-box input:focus {
  border-color: #54bae2;
}

.map__map {
  height: 380px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .map__map {
    height: 450px;
  }
}

@media only screen and (min-width: 1200px) {
  .map__map {
    height: 520px;
  }
}

.map__note {
  border-top: 1px solid #E8E8E8;
  padding-top: 16px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.79;
  color: #4a4a4a;
}

.map__note a,
.map__note a:visited {
  color: #54bae2;
  display: block;
}

.map__note a:hover,
.map__note a:visited:hover {
  color: #549ce2;
}

@media only screen and (min-width: 992px) {
  .map__note a,
  .map__note a:visited {
    display: inline;
  }
}

.page-title {
  text-align: center;
  font-size: 32px;
  letter-spacing: 4.2px;
  font-weight: bold;
  margin-bottom: 40px;
}

@media only screen and (min-width: 576px) {
  .page-title {
    font-size: 38px;
    letter-spacing: 5.6px;
  }
}

@media only screen and (min-width: 992px) {
  .page-title {
    font-size: 50px;
  }
}

.page-title--small {
  font-size: 26px;
}

@media only screen and (min-width: 576px) {
  .page-title--small {
    font-size: 32px;
    letter-spacing: 4px;
  }
}

@media only screen and (min-width: 992px) {
  .page-title--small {
    margin-top: 80px;
    margin-bottom: 15px;
    font-size: 36px;
  }
}

.page-subtitle {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 576px) {
  .page-subtitle {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) {
  .page-subtitle {
    margin-bottom: 60px;
  }
}

.page-title + .page-subtitle {
  margin-top: -20px;
}

h1.page-title--shop {
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) {
  h1.page-title--shop {
    text-align: left;
  }
}

h2.page-title--shop {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: left;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 3.3px;
  margin-bottom: 10px;
}

.pagination {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.pagination li:not(:last-child) {
  margin-right: 15px;
}

@media only screen and (min-width: 768px) {
  .pagination li:not(:last-child) {
    margin-right: 25px;
  }
}

.pagination button {
  width: 46px;
  height: 34px;
  border-radius: 17px;
  background-color: #fff;
  align-items: center;
  line-height: 34px;
  font-variant-numeric: lining-nums;
  border: solid 1px #676767;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.pagination button:hover {
  background-color: #000000;
  color: #fff;
}

.pagination li.active button {
  background-color: #000000;
  color: #fff;
}

.pagination--shop {
  justify-content: center;
  margin-bottom: 70px;
}

@media only screen and (min-width: 768px) {
  .pagination--shop {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 1200px) {
  .pagination--shop {
    margin-top: -20px;
  }
}

.patents {
  padding-bottom: 90px;
}

.patents__inner {
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  padding: 57px 20px 59px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 576px) {
  .patents__inner {
    padding: 57px 47px 59px;
  }
}

@media only screen and (min-width: 992px) {
  .patents__inner {
    padding: 52px 34px 62px;
  }
}

@media only screen and (min-width: 1200px) {
  .patents__inner {
    padding: 52px 115px 62px;
  }
}

.patents__item {
  margin-bottom: 60px;
}

@media only screen and (min-width: 992px) {
  .patents__item {
    margin-bottom: 52px;
  }
}

.patents__item:last-child {
  margin-bottom: 0;
}

.patents__item-title {
  font-size: 26px;
  margin: 0 0 20px;
}

@media only screen and (min-width: 992px) {
  .patents__item-title {
    font-size: 30px;
  }
}

.patents__item p {
  margin: 0 0 21px;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) {
  .patents__item p {
    margin: 0 0 16px;
  }
}

.patents__item p:last-child {
  margin-bottom: 0;
}

.patents__item p a {
  color: #54bae2;
  font-weight: normal;
  font-size: inherit;
  display: block;
  word-break: break-word;
  margin-top: 6px;
}

@media only screen and (min-width: 992px) {
  .patents__item p a {
    display: inline;
    margin: 0;
    padding-left: 13px;
  }
}

.patents__item p a:hover, .patents__item p a:focus {
  text-decoration: underline;
}

.post__img-wrapper {
  background-color: #fafafa;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) {
  .post__img-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .post__img-wrapper .container,
  .post__img-wrapper [class^=col] {
    padding: 0;
  }
}

.post__img-wrapper img {
  height: 250px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

.post__info {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #777777;
  font-variant-numeric: lining-nums;
  margin-bottom: 30px;
}

@media only screen and (min-width: 576px) {
  .post__info {
    font-size: 14px;
  }
}

.post__info b {
  display: block;
  margin-bottom: 8px;
}

@media only screen and (min-width: 576px) {
  .post__info b {
    margin-right: 20px;
    display: inline;
    margin-bottom: 0;
  }
}

.post__info a {
  color: inherit;
}

.post__info a:hover {
  color: #54bae2;
}

.post__content {
  font-size: 12px;
  line-height: 1.64;
  color: #4a4a4a;
  margin-bottom: 50px;
}

@media only screen and (min-width: 576px) {
  .post__content {
    font-size: 14px;
  }
}

.post__categories {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
}

.post__categories-item {
  border-radius: 25px;
  padding-left: 15px;
  padding-right: 15px;
  border: solid 1px #9b9b9b;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 2.3px;
  color: #9b9b9b;
  text-transform: uppercase;
  margin-right: 7px;
  margin-left: 7px;
  margin-bottom: 14px;
}

.post__categories-item:hover {
  color: #54bae2;
  border-color: #54bae2;
}

@media only screen and (min-width: 576px) {
  .post__categories-item {
    font-size: 14px;
    height: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .post__categories-item {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
    min-width: 150px;
    height: 50px;
  }
}

@media only screen and (min-width: 992px) {
  .post__categories-item {
    min-width: 170px;
  }
}

.preloader {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cssload-container * {
  box-sizing: border-box;
}

.cssload-container {
  max-width: 49px;
}

.cssload-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cssload-flex-container li {
  padding: 10px;
  height: 97px;
  width: 97px;
  margin: 29px 19px;
  position: relative;
  text-align: center;
  list-style: none;
}

.cssload-loading {
  display: inline-block;
  position: relative;
  width: 73px;
  height: 73px;
  border-radius: 4px;
  border-bottom: 5px solid black;
  overflow: hidden;
}

.cssload-loading:after,
.cssload-loading:before {
  position: absolute;
  content: '';
  background: #54bae2;
  border-radius: 100%;
}

.cssload-loading:before {
  height: 34px;
  width: 34px;
  left: 26.5%;
  animation: cssload-bounce1 1.55s ease-in infinite;
  animation-direction: alternate;
}

.cssload-loading:after {
  height: 15px;
  width: 15px;
  left: 39.5%;
  animation: cssload-bounce2 1.55s ease-out infinite;
  animation-direction: alternate;
  transform: translateY(219px);
}

@keyframes cssload-bounce1 {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(249px);
  }
}

@keyframes cssload-bounce2 {
  from {
    transform: translateY(219px);
  }
  to {
    transform: translateY(0px);
  }
}

.press__item {
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
  .press__item {
    margin-bottom: 48px;
  }
}

.press__item-img {
  height: 240px;
  display: block;
}

.press__item-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

.press__item-title {
  display: block;
  font-family: "BebasNeue", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 3.3px;
  color: #00000a;
  margin-bottom: 6px;
  margin-top: 20px;
}

@media only screen and (min-width: 576px) {
  .press__item-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .press__item-title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) {
  .press__item-title {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1200px) {
  .press__item-title {
    font-size: 30px;
  }
}

.press__item-title:visited {
  color: inherit;
}

.press__item-title:hover {
  color: #54bae2;
}

.press__item-date {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.64;
  color: #777777;
  margin-bottom: 10px;
  display: block;
}

@media only screen and (min-width: 576px) {
  .press__item-date {
    font-size: 14px;
  }
}

.press__item-descr {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 7px;
}

@media only screen and (min-width: 576px) {
  .press__item-descr {
    font-size: 14px;
  }
}

.press__share {
  display: flex;
}

.press .cta {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .press .cta {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.product-images {
  border-top: 1px solid #D6D6D6;
  background-color: #ffffff;
  padding-top: 35px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 576px) {
  .product-images {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) {
  .product-images {
    margin-bottom: 0;
    padding-top: 15px;
    border-top: none;
  }
}

.product-images__list {
  border: solid 1px #f2f2f2;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .product-images__list {
    border: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}

@media only screen and (min-width: 992px) {
  .product-images__list {
    margin: 0 -20px;
  }
}

@media only screen and (min-width: 1200px) {
  .product-images__list {
    margin-right: 0;
  }
}

.product-images__list::before {
  content: '';
  position: absolute;
  left: 17px;
  bottom: 17px;
  width: 25px;
  height: 25px;
  background: url(../img/icons/icon-zoom-in.svg) center/cover no-repeat;
}

@media only screen and (min-width: 768px) {
  .product-images__list::before {
    display: none;
  }
}

.product-images__list li:first-child a {
  position: relative;
}

.product-images__list li:first-child a::before {
  content: '';
  position: absolute;
  left: 17px;
  bottom: 17px;
  width: 25px;
  height: 25px;
  display: none;
  background: url(../img/icons/icon-zoom-in.svg) center/cover no-repeat;
}

@media only screen and (min-width: 768px) {
  .product-images__list li:first-child a::before {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .product-images__list li {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .product-images__list li {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .product-images__list li.small {
    width: calc(50% - 20px);
  }
}

@media only screen and (min-width: 992px) {
  .product-images__list li.small {
    width: calc(50% - 40px);
  }
}

@media only screen and (min-width: 768px) {
  .product-images__list li.small .product-images__list-item {
    height: 130px;
  }
}

@media only screen and (min-width: 992px) {
  .product-images__list li.small .product-images__list-item {
    height: 170px;
  }
}

@media only screen and (min-width: 1200px) {
  .product-images__list li.small .product-images__list-item {
    height: 200px;
  }
}

.product-images__list-item {
  display: block;
  padding: 50px 0;
  height: 365px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .product-images__list-item {
    border: solid 1px #f2f2f2;
    padding: 0 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 365px;
  }
}

@media only screen and (min-width: 992px) {
  .product-images__list-item {
    height: 430px;
    padding: 0 30px;
    margin-bottom: 40px;
  }
}

.product-images__list-item img {
  max-width: 80%;
  max-height: 100%;
  margin: 0 auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

@media only screen and (min-width: 768px) {
  .product-images__list-item img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 768px) {
  .product-images__list-item--video {
    padding: 0;
  }
  .product-images__list-item--video img {
    width: 100%;
    max-width: 100%;
  }
}

.product-images .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  display: flex;
}

.product-images .slick-dots button {
  font-size: 0;
  width: 36px;
  height: 2px;
  background-color: #f2f2f2;
  transition: all 0.2s ease;
  margin: 0 5px;
  cursor: pointer;
}

.product-images .slick-dots .slick-active button {
  height: 4px;
  background-color: #54bae2;
}

img.mfp-img {
  margin: 40px auto;
  background: #fff;
  padding: 0;
}

.product-info {
  margin-bottom: 54px;
}

@media only screen and (min-width: 768px) {
  .product-info {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 992px) {
  .product-info {
    padding-top: 15px;
  }
}

.product-info__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 29px;
  border-bottom: 1px solid #D6D6D6;
}

.product-info__price-section {
  font-variant-numeric: lining-nums;
}

.product-info__code {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #9b9b9b;
  display: block;
  margin-bottom: 13px;
}

.product-info__sale-price {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
  display: block;
  margin-bottom: 9px;
}

.product-info__old-price {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #9b9b9b;
  display: block;
}

.product-info__old-price b {
  text-decoration: line-through;
}

.product-info__price {
  font-family: "BebasNeue", sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 4.4px;
  color: #00000a;
  margin-left: 30px;
}

.product-info .btn-favorite {
  margin-right: 55px;
}

@media only screen and (min-width: 768px) {
  .product-info .btn-favorite {
    margin-right: 20px;
  }
}

.product-info .cta {
  margin-top: 21px;
}

.product-info__section {
  padding-top: 38px;
}

.product-info__section:not(:last-child) {
  padding-bottom: 31px;
  border-bottom: 1px solid #D6D6D6;
}

.product-info__section p {
  font-size: 16px;
  line-height: 1.6;
  color: #4a4a4a;
}

.product-info__section p:not(:last-child) {
  margin-bottom: 25px;
}

.product-info__section ul {
  list-style: disc;
  padding-left: 18px;
  font-variant-numeric: lining-nums;
  font-size: 16px;
  line-height: 1.2;
  color: #4a4a4a;
}

.product-info__section li {
  margin-bottom: 15px;
}

.product-info__section a {
  color: #54bae2;
  font-weight: 500;
}

.product-info__section a:hover {
  color: #549ce2;
}

.product-info__section-title {
  font-family: "BebasNeue", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 4px;
  color: #00000a;
  text-transform: uppercase;
  display: block;
  margin-bottom: 14px;
}

.product-info__options {
  margin-bottom: 38px;
}

.product-info__options p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #4a4a4a;
}

.product-info__options input[type=radio] {
  display: none;
}

.product-info__options label {
  width: 120px;
  height: 50px;
  display: inline-flex;
  color: #000;
  margin-bottom: 11px;
  margin-right: 11px;
  align-items: center;
  justify-content: center;
  font-variant-numeric: lining-nums;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 576px) {
  .product-info__options label {
    width: 160px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) {
  .product-info__options label {
    font-size: 16px;
    height: 70px;
    width: 210px;
  }
}

.product-info__options input:checked + label {
  color: #ffffff;
  background-color: #00000a;
}

.product-info__options-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #777777;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-info__section-title + .product-info__options {
  margin-top: 30px;
}

.product-socials {
  margin-bottom: 54px;
  text-align: center;
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .product-socials {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 992px) {
  .product-socials {
    align-items: center;
    display: flex;
  }
}

.product-socials__text {
  text-transform: uppercase;
  font-family: "BebasNeue", sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: #000000;
  letter-spacing: 3px;
  display: block;
  margin-bottom: 15px;
}

@media only screen and (min-width: 576px) {
  .product-socials__text {
    letter-spacing: 4px;
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) {
  .product-socials__text {
    margin-bottom: 0;
  }
}

.comments {
  padding-top: 51px;
  padding-bottom: 88px;
}

.comments__title {
  font-family: "BebasNeue", sans-serif;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 4px;
  text-align: center;
  color: #00000a;
  display: block;
  margin-bottom: 28px;
}

@media only screen and (min-width: 1200px) {
  .comments__title {
    font-size: 30px;
  }
}

.comments__count {
  color: #9b9b9b;
}

.comments__item {
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.comments__item-left {
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  flex-shrink: 0;
}

@media only screen and (min-width: 768px) {
  .comments__item-left {
    margin-right: 28px;
    width: 85px;
  }
}

.comments__item-right {
  width: calc(100% - 62px);
}

@media only screen and (min-width: 768px) {
  .comments__item-right {
    width: calc(100% - 113px);
  }
}

.comments__item-avatar {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 17px;
}

.comments__item-avatar img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

.comments__item-time {
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: #9b9b9b;
  line-height: normal;
  text-transform: uppercase;
  font-variant-numeric: lining-nums;
}

@media only screen and (min-width: 768px) {
  .comments__item-time {
    font-size: 12px;
  }
}

.comments__item-header {
  margin-bottom: 5px;
}

@media only screen and (min-width: 576px) {
  .comments__item-header {
    margin-bottom: 10px;
    align-items: center;
    display: flex;
  }
}

.comments__item-author {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #00000a;
  margin-right: 20px;
  display: block;
  margin-bottom: 5px;
}

@media only screen and (min-width: 576px) {
  .comments__item-author {
    margin-bottom: 0;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .comments__item-author {
    font-size: 18px;
  }
}

.comments__item-reply {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.79;
  letter-spacing: normal;
  color: #54bae2;
  text-transform: uppercase;
}

@media only screen and (min-width: 576px) {
  .comments__item-reply {
    font-size: 14px;
  }
}

.comments__item-reply::after {
  content: '';
  width: 9px;
  height: 9px;
  display: inline-block;
  border-top: solid 2px #54bae2;
  border-right: solid 2px #54bae2;
  transform: rotate(45deg);
  margin-left: 9px;
  margin-bottom: 1px;
}

.comments__item-reply:hover {
  color: #549ce2;
}

.comments__item-reply:hover::after {
  border-color: #549ce2;
}

.comments__item.reply-active .comments__item-reply::after {
  content: '\2716';
  font-weight: bold;
  width: auto;
  height: auto;
  border: none;
  transform: rotate(0);
  margin-left: 9px;
  margin-bottom: 0;
}

.comments__item-comment {
  font-size: 12px;
  font-style: italic;
  line-height: 1.7;
  color: #4a4a4a;
}

@media only screen and (min-width: 768px) {
  .comments__item-comment {
    font-size: 14px;
  }
}

.comments__item-replies {
  width: 100%;
  padding-left: 62px;
}

@media only screen and (min-width: 768px) {
  .comments__item-replies {
    padding-left: 113px;
  }
}

.comments__item-replies .comments__item {
  margin-bottom: 25px;
}

.comments__item-replies .comments__item:last-child {
  margin-bottom: 0;
}

.comments__item-replies .comments__item:first-child {
  margin-top: 25px;
}

.select2-container--custom-theme {
  height: 60px;
  border: solid 1px #676767;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  color: #000000;
  text-transform: uppercase;
  font-variant-numeric: lining-nums;
}

@media only screen and (min-width: 576px) {
  .select2-container--custom-theme {
    font-size: 14px;
  }
}

.select2-container--custom-theme .styled-select__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 24px;
}

.select2-container--custom-theme .select2-selection--single .select2-selection__rendered {
  padding: 0;
}

.select2-container--custom-theme .select2-selection__rendered {
  padding-left: 0;
}

.select2-container--custom-theme .styled-select__dropdown {
  border: solid 1px #676767;
}

.select2-container--custom-theme .select2-results__option:hover,
.select2-container--custom-theme .select2-results__option--highlighted {
  color: #54bae2;
}

.select2-container--custom-theme .select2-selection__arrow {
  background: url(../img/icons/select-arrows.svg) center/cover no-repeat;
  height: 24px;
  width: 15px;
}

.select2-container--custom-theme-2 {
  height: 34px;
  border: solid 1px #676767;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  color: #676767;
  font-variant-numeric: lining-nums;
}

@media only screen and (min-width: 576px) {
  .select2-container--custom-theme-2 {
    font-size: 14px;
  }
}

.select2-container--custom-theme-2.select2-container--open .select2-dropdown--below {
  border: solid 1px #676767;
}

.select2-container--custom-theme-2 .styled-select__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px 0 17px;
}

.select2-container--custom-theme-2 .select2-selection--single .select2-selection__rendered {
  padding: 0;
}

.select2-container--custom-theme-2 .select2-selection__rendered {
  padding-left: 0;
}

.select2-container--custom-theme-2 .select2-results__option:hover,
.select2-container--custom-theme-2 .select2-results__option--highlighted {
  color: #54bae2;
}

.select2-container--custom-theme-2 .select2-selection__arrow {
  background: url(../img/icons/icon-down-arrow.svg) center/cover no-repeat;
  height: 7px;
  width: 14px;
}

.select2-container--custom-theme-2.select2-container--open .select2-selection__arrow {
  transform: rotate(180deg);
}

.select2-container--custom-theme-option {
  min-width: 210px;
  height: 50px;
  border: solid 1px #676767;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  color: #676767;
  font-variant-numeric: lining-nums;
}

@media only screen and (min-width: 576px) {
  .select2-container--custom-theme-option {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .select2-container--custom-theme-option {
    height: 70px;
  }
}

.select2-container--custom-theme-option .styled-select__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px 0 17px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00000a;
}

.select2-container--custom-theme-option .styled-select__dropdown .select2-results__option:not([aria-selected=true]) {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00000a;
  padding: 14px 18px;
  height: 50px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .select2-container--custom-theme-option .styled-select__dropdown .select2-results__option:not([aria-selected=true]) {
    height: 70px;
  }
}

.select2-container--custom-theme-option .select2-selection--single .select2-selection__rendered {
  padding: 0;
}

.select2-container--custom-theme-option .select2-selection__rendered {
  padding-left: 0;
}

.select2-container--custom-theme-option.select2-container--open .select2-dropdown--above {
  border-top: none;
}

.select2-container--custom-theme-option .select2-results__option:hover,
.select2-container--custom-theme-option .select2-results__option--highlighted {
  color: #54bae2;
}

.select2-container--custom-theme-option .select2-selection__arrow {
  background: url(../img/icons/icon-down-arrow.svg) center/cover no-repeat;
  height: 7px;
  width: 14px;
}

.select2-container--custom-theme-option.select2-container--open .select2-selection__arrow {
  transform: rotate(180deg);
}

.styled-select__dropdown.select2-dropdown--below {
  left: -1px;
  top: -2px;
}

.styled-select__dropdown.select2-dropdown--above {
  left: -1px;
}

.styled-select__dropdown {
  border-radius: 0;
  border: solid 1px #676767;
}

.styled-select__dropdown .select2-results__options {
  max-height: 300px;
  overflow-y: auto;
}

.styled-select__dropdown .select2-results__option {
  padding: 5px 17px;
}

.styled-select__dropdown .select2-search.select2-search--dropdown {
  padding: 5px 14px;
  border-bottom: 1px solid #ccc;
}

.styled-select__dropdown .select2-search__field {
  padding: 5px 10px;
  border: solid 1px #ccc;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  color: #676767;
  text-transform: uppercase;
  font: inherit;
}

.styled-select-option__color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 17px;
  flex-shrink: 0;
}

@media only screen and (min-width: 768px) {
  .styled-select-option__color {
    width: 44px;
    height: 44px;
  }
}

.styled-select-option__wrapper {
  display: flex;
  align-items: center;
}

.select2-results__option[aria-selected=true] {
  display: none;
}

.styled-select__dropdown.select2-dropdown--below {
  left: -1px;
  top: -2px;
}

.styled-select__dropdown.select2-dropdown--above {
  left: -1px;
}

.styled-select__dropdown {
  border-radius: 0;
}

.styled-select__dropdown .select2-results__options {
  max-height: 300px;
  overflow-y: auto;
}

.styled-select__dropdown .select2-results__option {
  padding: 18px 24px;
}

.styled-select__dropdown .select2-search.select2-search--dropdown {
  padding: 18px 14px;
  border-bottom: 1px solid #ccc;
}

.styled-select__dropdown .select2-search__field {
  padding: 5px 10px;
  border: solid 1px #ccc;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  color: #000000;
  text-transform: uppercase;
  font: inherit;
}

.socials {
  display: flex;
  position: relative;
}

.socials__add,
.socials a {
  display: inline-flex;
  background-color: #d8d8d8;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  transition: all 0.2s ease;
}

.socials__add svg,
.socials a svg {
  fill: #000;
  transition: all 0.2s ease;
  height: 18px;
  width: 18px;
}

.socials__add:hover,
.socials a:hover {
  background-color: #54bae2;
  color: #fff;
}

.socials__add:hover svg,
.socials a:hover svg {
  fill: #fff;
}

.socials__add {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
}

.socials.active-more .socials__add {
  color: #fff;
  background-color: #000;
}

.socials__more {
  position: absolute;
  border: solid 10px #fafafa;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 10px;
  top: calc(100% + 10px);
  z-index: 10;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

@media only screen and (min-width: 992px) {
  .socials__more {
    top: calc(100% + 18px);
    right: -34px;
    left: auto;
    transform: translateX(0);
  }
  .socials__more .socials li:first-child {
    margin-left: 0;
  }
}

.socials.active-more .socials__more {
  display: block;
}

.socials__more-close {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: normal;
  color: #54bae2;
  margin-top: 15px;
  text-transform: uppercase;
}

.socials--footer {
  margin-top: 20px;
}

.socials--footer li {
  margin-right: 20px;
}

.socials--product {
  justify-content: center;
}

.socials--product li {
  margin: 0 10px;
}

@media only screen and (min-width: 992px) {
  .socials--product {
    justify-content: flex-start;
  }
  .socials--product li {
    margin: 0;
    margin-left: 20px;
  }
}

.social-link {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.social-link svg {
  width: 24px;
  height: 24px;
  border-width: 2px;
  border-style: solid;
  padding: 4px;
  border-radius: 50%;
  margin-right: 12px;
}

.social-link--white {
  color: #ffffff;
}

.social-link--white svg {
  border-color: #fff;
  fill: #fff;
  transition: all 0.2s ease;
}

.social-link--white:hover svg {
  border-color: #549ce2;
  background-color: #549ce2;
  fill: #fff;
}

.social-link--black {
  color: #000;
}

.social-link--black svg {
  border-color: #000;
  background-color: #000;
  fill: #fff;
  transition: all 0.2s ease;
}

.social-link--black:hover svg {
  border-color: #54bae2;
  background-color: #54bae2;
  fill: #fff;
}

.share {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #777777;
}

.share .socials a {
  background-color: transparent;
  margin-left: 5px;
  margin-right: 5px;
}

.share .socials a:hover svg {
  fill: #54bae2;
}

.share .socials svg {
  fill: #4a4a4a;
}

.share--post {
  margin-top: 26px;
  margin-bottom: 60px;
}

.secondary-nav {
  width: 100%;
  display: none;
  position: relative;
  padding: 16px 0;
}

@media only screen and (min-width: 992px) {
  .secondary-nav {
    transition: all 0.2s ease;
    display: block !important;
    padding: 0;
    order: -1;
  }
  .secondary-nav::after {
    content: '';
    position: absolute;
    display: block;
    left: -100px;
    right: -100px;
    bottom: 0;
    height: 1px;
    background-color: #c6c6c6;
  }
}

.secondary-nav > ul {
  height: 100%;
}

@media only screen and (min-width: 992px) {
  .secondary-nav > ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.secondary-nav > ul > li {
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .secondary-nav > ul > li {
    text-align: left;
    margin-left: 49px;
  }
}

.secondary-nav > ul > li > a {
  font-family: "TTWellingtons", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  display: inline-block;
  padding: 10px;
}

.secondary-nav > ul > li > a:hover {
  color: #54bae2;
}

@media only screen and (min-width: 576px) {
  .secondary-nav > ul > li > a {
    padding: 16px;
  }
}

@media only screen and (min-width: 992px) {
  .secondary-nav > ul > li > a {
    padding: 0;
    color: #9b9b9b;
  }
}

@media only screen and (min-width: 992px) {
  .secondary-nav {
    height: 52px;
  }
}

@media only screen and (min-width: 1200px) {
  .secondary-nav {
    height: 52px;
  }
}

@media only screen and (min-width: 992px) {
  .scrolled .secondary-nav {
    height: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .scrolled .secondary-nav {
    height: 30px;
  }
}

.search {
  padding: 10px;
}

.search__input {
  width: 100%;
  border: solid 1px #b8b8b8;
  transition: all 0.2s ease;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding-left: 54px;
  padding-right: 18px;
  color: #181818;
  background-image: url(../img/icons/icon-search-999.svg);
  background-repeat: no-repeat;
  background-position: 21.6px 11px;
  transition: all 0.2s ease;
}

.search__input:focus {
  border-color: #54bae2;
  background-image: url(../img/icons/icon-search-accent.svg);
}

.search .cta {
  background-color: #fff;
}

@media only screen and (min-width: 992px) {
  .search--shop {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .search--shop {
    margin-bottom: 30px;
  }
}

.shop-filter {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 46px;
}

@media only screen and (min-width: 992px) {
  .shop-filter {
    padding-top: 9px;
  }
}

.shop-filter__list-wrapper {
  transform: translateX(-101%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 418px;
  background-color: #fff;
  z-index: 110;
  border-right: 1px solid #DDDDDD;
  transition: transform 0.2s cubic-bezier(0.2, 0, 1, 1);
}

@media only screen and (min-width: 992px) {
  .shop-filter__list-wrapper {
    transform: translateX(0) !important;
    position: static;
    border-right: none;
    z-index: 0;
  }
}

.shop-filter.opened .shop-filter__list-wrapper {
  transform: translateX(0);
}

.shop-filter__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: none;
}

@media only screen and (min-width: 992px) {
  .shop-filter__overlay {
    display: none !important;
  }
}

.shop-filter.opened .shop-filter__overlay {
  display: block;
}

.shop-filter__top-bar {
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 31px;
}

.shop-filter__top-bar img {
  width: 14px;
}

@media only screen and (min-width: 992px) {
  .shop-filter__top-bar {
    display: none;
  }
}

.shop-filter__title {
  font-family: "BebasNeue", sans-serif;
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 3.3px;
  margin-left: 10px;
}

.shop-filter__mobile-close {
  margin-left: auto;
  font-size: 60px;
  font-weight: 500;
}

.shop-filter__mobile-close:hover {
  color: #54bae2;
}

.shop-filter__list {
  border-top: 1px solid #DDDDDD;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-left: 12px;
}

@media only screen and (min-width: 992px) {
  .shop-filter__list {
    padding-left: 0;
    padding-top: 36px;
  }
}

@media only screen and (min-width: 1200px) {
  .shop-filter__list {
    margin-bottom: 60px;
  }
}

.shop-filter__item {
  margin-bottom: 19px;
}

@media only screen and (min-width: 992px) {
  .shop-filter__item {
    margin-bottom: 12px;
  }
}

.shop-filter__item a {
  padding-left: 27px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  text-transform: uppercase;
  cursor: pointer;
  color: #000;
}

.shop-filter__item a::before {
  content: '';
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  border-top: solid 1px #000;
  border-right: solid 1px #000;
  position: absolute;
  left: 0;
  top: 6px;
}

@media only screen and (min-width: 992px) {
  .shop-filter__item a {
    padding-left: 18px;
    font-size: 12px;
  }
  .shop-filter__item a::before {
    top: 3px;
  }
}

.shop-filter__item.active a,
.shop-filter__item a:hover {
  color: #54bae2;
}

.shop-filter__item.active a::before,
.shop-filter__item a:hover::before {
  border-color: #54bae2;
}

.shop-filter__mobile-open {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media only screen and (min-width: 576px) {
  .shop-filter__mobile-open {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 992px) {
  .shop-filter__mobile-open {
    display: none !important;
  }
}

.sort-block {
  background-color: #FAFAFA;
  padding: 22px 20px 24px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 576px) {
  .sort-block {
    padding: 22px 40px 24px;
    margin-top: 24px;
  }
}

.sort-block__go-back {
  height: 34px;
  margin-bottom: 20px;
  max-width: 135px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .sort-block__go-back {
    margin-bottom: 0;
  }
}

.sort-block__reset-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .sort-block__reset-block {
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 0;
    height: 100%;
  }
}

.sort-block__reset-block button {
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
  border: solid 1px #676767;
  font-size: 14px;
  font-weight: 500;
  color: #676767;
  padding-left: 19px;
  padding-right: 19px;
  margin-right: 22px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .sort-block__reset-block button {
    margin-bottom: 0;
  }
}

.sort-block__reset-block button:hover {
  color: #54bae2;
  border-color: #54bae2;
}

.sort-block__reset-block button::after {
  content: '\2716';
  padding-left: 5px;
}

.sort-block__reset-block p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #676767;
}

@media only screen and (min-width: 768px) {
  .sort-block__reset-block p {
    text-align: left;
    width: auto;
  }
}

.sort-block__form {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .sort-block__form {
    display: flex;
    align-items: center;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}

.sort-block__form label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #676767;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) {
  .sort-block__form label {
    margin-bottom: 0;
  }
}

.sort-block__form .select2 {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .sort-block__form .select2 {
    margin-left: 20px;
    margin-bottom: 0;
  }
}

.sort-block__search {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

@media only screen and (min-width: 768px) {
  .sort-block__search {
    margin-left: 20px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
}

.sort-block__search input {
  height: 34px;
  border-radius: 17px;
  border: solid 1px #676767;
  width: 100%;
  padding-left: 19px;
  line-height: 34px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: #676767;
}

.sort-block__search input:focus {
  border-color: #54bae2;
}

.shop-putter-preview {
  text-align: center;
  padding-bottom: 50px;
  border: 1px solid #f3f3f3;
  margin-bottom: 11px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 11px);
}

@media only screen and (min-width: 576px) {
  .shop-putter-preview {
    max-width: none;
  }
}

@media only screen and (min-width: 992px) {
  .shop-putter-preview {
    margin-bottom: 30px;
    height: calc(100% - 30px);
  }
}

@media only screen and (min-width: 1200px) {
  .shop-putter-preview {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.shop-putter-preview__img {
  height: 200px;
  width: 100%;
  margin-bottom: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

@media only screen and (min-width: 1200px) {
  .shop-putter-preview__img {
    height: 228px;
    margin-bottom: 50px;
  }
}

.shop-putter-preview__title {
  font-family: "BebasNeue", sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 4px;
  color: #000000;
  padding: 0 10px;
  display: block;
}

@media only screen and (min-width: 1200px) {
  .shop-putter-preview__title {
    padding: 0;
  }
}

.shop-putter-preview__descr {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  text-align: center;
  color: #4a4a4a;
  padding: 0 15px;
  margin-bottom: 12px;
  max-width: 100%;
}

@media only screen and (min-width: 992px) {
  .shop-putter-preview__descr {
    margin-bottom: 30px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .shop-putter-preview__descr {
    padding: 0;
  }
}

.shop-putter-preview .cta {
  margin-top: auto;
  width: 150px;
}

.shop-category-item {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  height: 150px;
  padding-top: 30px;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  transition: all 0.2s ease;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (min-width: 576px) {
  .shop-category-item {
    padding-top: 53px;
    height: 240px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .shop-category-item {
    margin-bottom: 30px;
  }
}

.shop-category-item svg {
  height: 50px;
  margin-bottom: 20px;
  transition: all 0.2s ease;
  max-width: 100%;
  fill: #000;
}

@media only screen and (min-width: 576px) {
  .shop-category-item svg {
    margin-bottom: 38px;
    height: 75px;
  }
}

.shop-category-item:hover {
  background-color: #000000;
  color: #54bae2;
}

.shop-category-item:hover svg {
  fill: #fff;
}

.shop-subcategory-item {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .shop-subcategory-item {
    margin-top: 30px;
  }
}

.shop-subcategory-item__img {
  height: 200px;
  text-align: center;
  flex-shrink: 0;
}

.shop-subcategory-item__img img {
  height: 100%;
}

.shop-subcategory-item__descr {
  padding: 16px 5px;
  background-color: #FAFAFA;
  text-align: center;
  transition: all 0.2s ease;
  flex-grow: 1;
  min-height: 110px;
}

@media only screen and (min-width: 1200px) {
  .shop-subcategory-item__descr {
    padding: 20px 5px;
  }
}

.shop-subcategory-item:hover .shop-subcategory-item__descr {
  background-color: #000;
}

.shop-subcategory-item__title {
  font-family: "BebasNeue", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 4px;
  color: #000000;
  margin-bottom: 16px;
  display: block;
  transition: all 0.2s ease;
}

.shop-subcategory-item:hover .shop-subcategory-item__title {
  color: #54bae2;
}

.shop-subcategory-item__text {
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;
  transition: all 0.2s ease;
}

.shop-subcategory-item:hover .shop-subcategory-item__text {
  color: #fff;
}

.shop-section-header {
  margin-top: 50px;
  margin-bottom: 30px;
}

.shop-section-header h3 {
  font-size: 20px;
}

@media only screen and (min-width: 576px) {
  .shop-section-header h3 {
    font-size: 36px;
  }
}

.shop-item {
  margin-bottom: 50px;
  margin-top: 10px;
}

.shop-item__img {
  height: 200px;
  background-color: #fdfdfd;
  text-align: center;
  margin-bottom: 30px;
  display: block;
}

.shop-item__img img {
  width: 200px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
}

@media only screen and (min-width: 576px) {
  .shop-item__img {
    height: 262px;
  }
  .shop-item__img img {
    width: 262px;
  }
}

.shop-item__title {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 11px;
  display: block;
}

.shop-item__title:hover {
  color: #54bae2;
}

@media only screen and (min-width: 576px) {
  .shop-item__title {
    font-size: 18px;
  }
}

.shop-item__descr {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.56;
  color: #000;
  font-variant-numeric: lining-nums;
  margin-bottom: 30px;
}

@media only screen and (min-width: 576px) {
  .shop-item__descr {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) {
  .shop-item__descr {
    margin-bottom: 40px;
  }
}

.shop-item__descr small {
  font-size: 16px;
  color: #9b9b9b;
}

.shop-item__footer {
  display: flex;
}

.shop-item__price-block {
  background-color: #FAFAFA;
  flex-grow: 1;
  text-align: center;
  position: relative;
}

.shop-item__current-price {
  font-family: "BebasNeue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3.3px;
  color: #000000;
  line-height: 40px;
}

@media only screen and (min-width: 576px) {
  .shop-item__current-price {
    font-size: 22px;
  }
}

.shop-item__old-price {
  font-family: "BebasNeue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  color: #9b9b9b;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 150px;
}

@media only screen and (min-width: 576px) {
  .shop-item__old-price {
    font-size: 18px;
  }
}

.shop-item__old-price::after {
  content: '';
  position: absolute;
  height: 1px;
  background-color: #9b9b9b;
  width: 72px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 576px) {
  .shop-item__old-price::after {
    width: 146px;
  }
}

@media only screen and (min-width: 768px) {
  .shop-item__old-price::after {
    width: 72px;
  }
}

.shop-items {
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 50px;
}

@media only screen and (min-width: 1200px) {
  .shop-items {
    border-bottom: none;
    margin-bottom: 0;
  }
  .shop-items [class*="col-"] {
    border-bottom: 1px solid #D6D6D6;
    margin-bottom: 50px;
  }
}

.shop-items--last-section {
  margin-bottom: 100px;
}

.shop-items--search-results {
  border-top: 1px solid #D6D6D6;
  padding-top: 30px;
}

.shop-items--search-results .shop-item {
  margin-bottom: 40px;
}

.shop-items--search-results .shop-item__img {
  height: 200px;
  margin-bottom: 25px;
}

.shop-items--search-results .shop-item__descr {
  margin-bottom: 8px;
}

.shop-items--search-results .shop-item__title {
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .shop-items--search-results .shop-item__title {
    text-align: left;
  }
}

.shop-items--search-results .shop-item__price-block {
  width: 40%;
  flex-grow: 0;
  margin: 0 auto;
}

@media only screen and (min-width: 992px) {
  .shop-items--search-results .shop-item__price-block {
    margin: 0;
  }
}

.shop-load-more {
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 150px;
  background-color: #FAFAFA;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 2.7px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
  font-family: "BebasNeue", sans-serif;
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) {
  .shop-load-more {
    height: 262px;
    font-size: 24px;
  }
}

.shop-load-more svg {
  height: 40px;
  width: 50px;
  fill: #000;
  margin-bottom: 28px;
  transition: .4s ease-out;
}

@media only screen and (min-width: 992px) {
  .shop-load-more svg {
    height: 80px;
    width: 100px;
  }
}

.shop-load-more:hover {
  background-color: #000;
  color: #fff;
}

.shop-load-more:hover svg {
  fill: #54bae2;
  transform: rotate(-360deg);
}

.slider-prev-arrow--type-1,
.slider-next-arrow--type-1 {
  height: 50px;
  width: 50px;
  border-style: solid;
  border-color: #ccc;
  transform: rotate(45deg);
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  margin-top: -25px;
}

.slider-prev-arrow--type-1:hover,
.slider-next-arrow--type-1:hover {
  border-color: #549ce2;
}

.slider-prev-arrow--type-1 {
  border-width: 0 0 1px 1px;
  left: -35px;
}

@media only screen and (min-width: 768px) {
  .slider-prev-arrow--type-1 {
    left: -50px;
  }
}

@media only screen and (min-width: 992px) {
  .slider-prev-arrow--type-1 {
    left: -70px;
  }
}

.slider-next-arrow--type-1 {
  border-width: 1px 1px 0 0;
  right: -35px;
}

@media only screen and (min-width: 768px) {
  .slider-next-arrow--type-1 {
    right: -50px;
  }
}

@media only screen and (min-width: 992px) {
  .slider-next-arrow--type-1 {
    right: -70px;
  }
}

.slider-dots--type-1 {
  position: absolute;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 0;
}

.slider-dots--type-1 button {
  width: 8px;
  height: 8px;
  font-size: 0;
  border-radius: 50%;
  margin: 0 6px;
  background-color: #d8d8d8;
  opacity: 0.5;
  transition: all 0.2s ease;
  cursor: pointer;
}

.slider-dots--type-1 button:hover,
.slider-dots--type-1 .slick-active button {
  opacity: 1;
}

.small-tabs {
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 50px;
}

.small-tabs__nav {
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 576px) {
  .small-tabs__nav {
    justify-content: flex-start;
  }
}

.small-tabs__btn {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 2.07;
  color: #54bae2;
  border-bottom: 3px solid transparent;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 992px) {
  .small-tabs__btn {
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 576px) {
  .small-tabs__btn:not(:last-child) {
    margin-right: 50px;
  }
}

.small-tabs__btn.active, .small-tabs__btn:hover {
  color: #4a4a4a;
  border-color: #676767;
}

@media only screen and (min-width: 576px) {
  .small-tabs__btn {
    font-size: 14px;
  }
}

.promo {
  height: 400px;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .promo {
    height: 477px;
  }
}

@media only screen and (min-width: 992px) {
  .promo {
    height: 640px;
  }
}

.promo__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 300px;
}

@media (min-width: 414px) {
  .promo__content {
    width: 370px;
  }
}

@media only screen and (min-width: 576px) {
  .promo__content {
    width: 540px;
  }
}

@media only screen and (min-width: 992px) {
  .promo__content {
    margin-top: 30px;
    width: 800px;
  }
}

@media only screen and (min-width: 1200px) {
  .promo__content {
    margin-top: 22px;
  }
}

.promo__title {
  text-transform: uppercase;
  font-family: "BebasNeue", sans-serif;
  margin-top: 0;
  color: #fff;
  font-size: 38px;
  line-height: 1.57;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 35px;
}

.promo__title strong {
  font-size: 48px;
}

.promo__title em {
  font-style: normal;
  color: #e1f7ff;
}

@media only screen and (min-width: 576px) {
  .promo__title {
    letter-spacing: 5.9px;
    font-size: 58px;
  }
  .promo__title strong {
    font-size: 68px;
  }
}

@media only screen and (min-width: 992px) {
  .promo__title {
    font-size: 70px;
    line-height: 1.3;
    margin-bottom: 60px;
  }
  .promo__title strong {
    font-size: 80px;
  }
}

.promo__img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center";
  height: 100%;
  width: 100%;
}

.related-posts {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) {
  .related-posts {
    padding-top: 100px;
  }
}

.related-posts h3 {
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 60px;
  border-bottom: 1px solid #d6d6d6;
}

.related-posts .cta {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .related-posts .cta {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.subscribe {
  padding: 50px 0;
  background-color: #54bae2;
}

.subscribe h3 {
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) {
  .subscribe {
    padding: 75px 0 78px;
  }
}

.subscribe__input {
  margin-bottom: 30px;
  width: 100%;
  height: 50px;
  padding: 0 24px;
  font: inherit;
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
}

@media only screen and (min-width: 768px) {
  .subscribe__input {
    margin-bottom: 0;
  }
}

.tabs-nav {
  margin-top: 10px;
  margin-bottom: 43px;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 992px) {
  .tabs-nav {
    margin-top: 33px;
  }
}

.tabs-nav__item {
  padding: 15px 5px;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  transition: all 0.2s ease;
}

@media (min-width: 414px) {
  .tabs-nav__item {
    padding: 15px 10px;
  }
}

@media only screen and (min-width: 576px) {
  .tabs-nav__item {
    padding: 27px 25px 19px;
  }
}

@media only screen and (min-width: 992px) {
  .tabs-nav__item {
    padding: 19px 60px 16px;
  }
}

@media only screen and (min-width: 1200px) {
  .tabs-nav__item {
    padding: 19px 75px 16px;
  }
}

.tabs-nav__item:first-child {
  flex-grow: 1;
  text-align: right;
  padding-left: 0;
}

.tabs-nav__item:last-child {
  flex-grow: 1;
  padding-right: 0;
}

.tabs-nav__item:first-child.active {
  border-right: 1px solid #e6e6e6;
}

.tabs-nav__item:last-child.active {
  border-left: 1px solid #e6e6e6;
}

.tabs-nav__item.active:not(:first-child):not(:last-child) {
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.tabs-nav__item.active {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid transparent;
  color: #000000;
}

.tabs-nav__item.active .tabs-nav__btn {
  color: #000000;
}

.tabs-nav__btn {
  font-family: "BebasNeue", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.2px;
  color: #54bae2;
  cursor: pointer;
  transition: all 0.2s ease;
}

@media (min-width: 414px) {
  .tabs-nav__btn {
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) {
  .tabs-nav__btn {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .tabs-nav__btn {
    font-size: 30px;
    letter-spacing: 3.3px;
  }
}

.tabs-nav__btn:hover {
  color: #000000;
}

.tab-content {
  display: none;
  margin-bottom: 70px;
}

.testimonials-small {
  padding: 100px 0;
}

.testimonials-small h2 {
  text-align: center;
}

.testimonials-small__item {
  margin-top: 50px;
  margin-bottom: 50px;
}

.testimonials-small__item-header {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.testimonials-small__item-pic {
  margin-right: 20px;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
}

.testimonials-small__item-info {
  font-size: 14px;
}

.testimonials-small__item-info b {
  display: block;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.testimonials-small__item-descr {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  line-height: 1.56;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .testimonials-small__item-descr {
    font-size: 16px;
  }
}

.testimonials-small__item-descr::before {
  content: '';
  position: absolute;
  left: -40px;
  top: 20px;
  width: 18px;
  height: 18px;
  background: url(../img/icons/icon-cite.svg) center/cover no-repeat;
}

.video {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}

.video-full {
  background-color: #181818;
}

.video-full.video-full-row2 {
  padding: 35px 0;
}

@media only screen and (min-width: 992px) {
  .video-full.video-full-row2 {
    padding: 60px 0;
  }
}

.video-full.video-full-row2 .video:first-child {
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) {
  .video-full.video-full-row2 .video:first-child {
    margin: 0;
  }
}

.video-full__height {
  width: 100%;
  height: 0;
  padding-bottom: 177.528%;
}

.video iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.video-content {
  padding: 60px 0 45px;
}

@media only screen and (min-width: 992px) {
  .video-content {
    padding: 70px 0;
  }
}

.video-content__title {
  font-size: 26px;
  margin: 0 0 25px;
  letter-spacing: 2.6px;
}

@media only screen and (min-width: 992px) {
  .video-content__title {
    font-size: 30px;
    margin: 0 0 37px;
    letter-spacing: 3px;
  }
}

.video-content__desc {
  padding: 30px 0 60px;
  font-size: 14px;
  line-height: 1.64;
}

@media only screen and (min-width: 992px) {
  .video-content__desc {
    padding: 27px 0 37px;
  }
}

@media only screen and (min-width: 1200px) {
  .video-content__desc {
    padding: 35px 0 54px;
  }
}

.video-content img {
  width: 100%;
}

.video-content__image {
  max-width: 322px;
  margin: 40px auto 0;
  display: block;
}

@media only screen and (min-width: 992px) {
  .video-content__image {
    margin: 0 0 0 -160px;
    max-width: initial;
  }
}

@media only screen and (min-width: 1200px) {
  .video-content__image {
    margin-left: -60px;
  }
}

.video-content .tech-content__inner {
  padding-top: 34px;
}

@media only screen and (min-width: 992px) {
  .video-content .tech-content__inner {
    padding-top: 64px;
  }
}

.video-content .tech-content__title {
  font-size: 26px;
  margin: 0 0 25px;
}

@media only screen and (min-width: 992px) {
  .video-content .tech-content__title {
    font-size: 30px;
    margin: 0 0 40px;
  }
}

.video-content-pages {
  padding: 60px 0 20px;
}

@media only screen and (min-width: 992px) {
  .video-content-pages {
    padding: 70px 0;
  }
}

.video-content-pages .video-content__desc {
  padding: 16px 0 39px;
}

@media only screen and (min-width: 992px) {
  .video-content-pages .video-content__desc {
    padding: 19px 0 33px;
  }
}

@media only screen and (min-width: 1200px) {
  .video-content-pages .video-content__desc {
    padding: 18px 0 29px;
  }
}

.usg {
  padding: 70px 0 80px;
}

@media only screen and (min-width: 992px) {
  .usg {
    padding: 100px 0;
  }
}

.usg__title {
  margin: 0 0 53px;
  font-size: 30px;
}

@media only screen and (min-width: 992px) {
  .usg__title {
    font-size: 36px;
    margin: 0 0 70px;
  }
}

.usg__item {
  margin-bottom: 80px;
}

@media only screen and (min-width: 992px) {
  .usg__item {
    margin: 0;
  }
}

.usg__item-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px;
  max-width: 70px;
  width: 100%;
}

@media only screen and (min-width: 576px) {
  .usg__item-wrap {
    max-width: 130px;
  }
}

@media only screen and (min-width: 992px) {
  .usg__item-wrap {
    display: inline-flex;
    height: 78px;
    margin: 0 0 35px;
    max-width: 100%;
  }
}

.usg__item-wrap img {
  width: 100%;
  max-height: 158px;
}

@media only screen and (min-width: 992px) {
  .usg__item-wrap img {
    width: auto;
  }
}

.usg__item .cta.cta--40.cta--border-white {
  height: 60px;
  border-width: 4px;
  font-size: 16px;
  display: inline-flex;
  width: auto;
  padding: 0 20px;
}

@media only screen and (min-width: 576px) {
  .usg__item .cta.cta--40.cta--border-white {
    width: auto;
    padding: 0;
    display: flex;
  }
}

@media only screen and (min-width: 992px) {
  .usg__item .cta.cta--40.cta--border-white {
    height: 40px;
    border-width: 2px;
    font-size: 14px;
  }
}

.usg .col-xl-3:last-child .usg__item {
  margin: 0;
}

.usg .col-xl-3:first-child img {
  max-width: 118px;
}

.tech-banner {
  background-color: #fafafa;
  padding: 40px 0 42px;
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .tech-banner {
    padding: 56px 0 64px;
  }
}

.tech-banner__inner {
  background: white;
  position: relative;
  padding: 40px 35px 40px;
}

@media only screen and (min-width: 576px) {
  .tech-banner__inner {
    padding: 40px 40px 34px;
  }
}

@media only screen and (min-width: 768px) {
  .tech-banner__inner {
    padding: 40px 40px 56px 40px;
  }
}

.tech-banner__inner-title {
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 29px;
  letter-spacing: 4px;
}

.tech-banner__inner p {
  line-height: 1.79;
  font-size: 14px;
}

.tech-banner__inner img {
  position: absolute;
  width: 120px;
  height: 85px;
  right: -60px;
  bottom: -25px;
}

@media only screen and (min-width: 576px) {
  .tech-banner__inner img {
    width: 206px;
    height: 188px;
    bottom: -18px;
    right: 0;
  }
}

@media only screen and (min-width: 992px) {
  .tech-banner__inner img {
    top: -30px;
    width: 305px;
    height: 278px;
    right: -30px;
    bottom: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .tech-banner__inner img {
    width: 339px;
    height: 278px;
    right: 0;
  }
}

.tech-content {
  padding: 60px 0 55px;
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .tech-content {
    padding: 100px 0 68px;
  }
}

@media only screen and (min-width: 1200px) {
  .tech-content {
    padding: 100px 0 78px;
  }
}

.tech-content__title {
  font-size: 30px;
  margin: 0 0 40px;
}

.tech-content__title.tech-content__title2 {
  font-size: 28px;
  margin: 0 0 26px;
}

@media only screen and (min-width: 992px) {
  .tech-content__title.tech-content__title2 {
    font-size: 36px;
    margin: 0 0 34px;
  }
}

.tech-content__inner p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.64;
  margin: 0 0 28px;
}

.tech-content__inner p:last-child {
  margin: 0;
}

.tech-content img {
  margin: 61px auto 0;
  display: block;
}

@media only screen and (min-width: 992px) {
  .tech-content img {
    margin: 40px 0 0 0;
  }
}

.tech-content-small img {
  max-width: initial;
  margin: 60px 0 -160px auto;
  width: 260px;
}

@media only screen and (min-width: 576px) {
  .tech-content-small img {
    margin: 60px 0 -200px auto;
    width: 350px;
  }
}

@media only screen and (min-width: 992px) {
  .tech-content-small img {
    width: 276px;
    margin: 0 0 -90px 0;
  }
}

.tech-ul {
  margin: 0 0 28px;
  padding: 0;
  list-style-type: none;
}

.tech-ul li {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.64;
  margin: 0 0 25px;
  padding-left: 20px;
}

.tech-ul li:before {
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  content: "";
  background-image: url(../img/icons/icn-carret-down.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 5px;
  width: 5px;
  height: 9px;
}

.tech-ul li:last-child {
  margin: 0;
}

.testim-top {
  background-color: #fafafa;
  padding: 40px 0;
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .testim-top {
    padding: 46px 0;
  }
}

@media only screen and (min-width: 1200px) {
  .testim-top {
    padding: 48px 0 40px;
  }
}

.testim-top__inner {
  background: white;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .testim-top__inner {
    overflow: inherit;
  }
}

.testim-top img {
  position: absolute;
  left: -40px;
  bottom: 0;
  width: 120px;
}

@media only screen and (min-width: 576px) {
  .testim-top img {
    left: -60px;
    width: 208px;
  }
}

@media only screen and (min-width: 992px) {
  .testim-top img {
    left: -1px;
  }
}

@media only screen and (min-width: 1200px) {
  .testim-top img {
    width: 245px;
  }
}

.testim-top__desc {
  padding: 35px 22px 21px 34px;
  width: calc(100% - 245px);
  margin: 0 0 0 auto;
  width: calc(100% - 60px);
}

@media only screen and (min-width: 576px) {
  .testim-top__desc {
    width: calc(100% - 149px);
  }
}

@media only screen and (min-width: 992px) {
  .testim-top__desc {
    padding: 27px 28px 28px 40px;
    width: calc(100% - 208px);
  }
}

@media only screen and (min-width: 1200px) {
  .testim-top__desc {
    padding: 41px 52px 26px 48px;
    width: calc(100% - 245px);
  }
}

.testim-top__title {
  margin: 0 0 19px;
  font-size: 22px;
}

@media only screen and (min-width: 992px) {
  .testim-top__title {
    font-size: 26px;
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .testim-top__title {
    font-size: 30px;
    margin: 0 0 19px;
  }
}

.testim-top p {
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 17px;
  line-height: 1.56;
}

@media only screen and (min-width: 576px) {
  .testim-top p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) {
  .testim-top p {
    margin: 0 0 14px;
  }
}

@media only screen and (min-width: 1200px) {
  .testim-top p {
    line-height: 1.63;
    margin: 0 0 21px;
  }
}

.testim-top__link {
  font-size: 14px;
  font-weight: bold;
  color: #54bae2;
  text-transform: uppercase;
}

.testim-main {
  padding: 12px 0 100px;
}

.testim-main__item {
  padding: 40px 0;
  border-bottom: 1px solid #d6d6d6;
}

@media only screen and (min-width: 576px) {
  .testim-main__item {
    padding: 40px 46px;
  }
}

@media only screen and (min-width: 992px) {
  .testim-main__item {
    padding: 60px 80px;
  }
}

@media only screen and (min-width: 1200px) {
  .testim-main__item {
    padding: 60px 0;
  }
}

.testim-main__item:last-child {
  margin-bottom: 50px;
}

@media only screen and (min-width: 992px) {
  .testim-main__item:last-child {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1200px) {
  .testim-main__item:last-child {
    margin-bottom: 74px;
  }
}

.testim-main__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (min-width: 992px) {
  .testim-main__inner {
    align-items: flex-start;
  }
}

.testim-main__image {
  width: 44%;
  text-align: center;
}

@media only screen and (min-width: 576px) {
  .testim-main__image {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) {
  .testim-main__image {
    width: calc(30% - 15px);
  }
}

.testim-main__image img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

@media only screen and (min-width: 576px) {
  .testim-main__image img {
    width: 150px;
    height: 150px;
  }
}

.testim-main__desc {
  width: 56%;
  padding-left: 15px;
}

@media only screen and (min-width: 576px) {
  .testim-main__desc {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) {
  .testim-main__desc {
    padding: 0 49px 0 0;
    width: calc(70% - 15px);
  }
}

@media only screen and (min-width: 1200px) {
  .testim-main__desc {
    padding: 0 72px 0 0;
  }
}

.testim-main__desc p {
  display: none;
}

@media only screen and (min-width: 992px) {
  .testim-main__desc p {
    display: block;
  }
}

.testim-main__mobile {
  padding-top: 35px;
}

@media only screen and (min-width: 992px) {
  .testim-main__mobile {
    display: none;
  }
}

.testim-main__title {
  font-size: 14px;
  font-weight: bold;
  font-family: "TTWellingtons", sans-serif;
  position: relative;
  padding-left: 35px;
  line-height: 20px;
}

@media only screen and (min-width: 992px) {
  .testim-main__title {
    padding-left: 49px;
  }
}

.testim-main__title:before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 25px;
  height: 23px;
  background: url(../img/icons/icon-cite.svg) center/cover no-repeat;
}

.testim-main__subtitle {
  padding-left: 35px;
  color: #676767;
  font-size: 14px;
  margin: 0 0 14px;
  line-height: 20px;
  display: block;
}

@media only screen and (min-width: 992px) {
  .testim-main__subtitle {
    padding-left: 49px;
  }
}

.testim-main p {
  font-size: 16px;
  color: #4a4a4a;
  line-height: 1.69;
  font-style: italic;
}

.pre-footer {
  overflow: hidden;
}

.pre-footer__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 51px 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.44;
}

.pre-footer .bg-grey {
  background-color: #fafafa;
}

.pre-footer__info {
  max-width: 338px;
  min-height: 46px;
  margin: 0 auto 22px;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.pre-footer .bg-accent .pre-footer__info {
  color: white;
}

.pre-footer .cta {
  width: 200px;
  text-align: center;
}

.return {
  background-color: #fafafa;
  padding: 40px 0 0;
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .return {
    padding: 60px 0 0;
  }
}

.return__content {
  position: relative;
  padding: 0 0 90px;
}

@media only screen and (min-width: 992px) {
  .return__content {
    padding: 0 0 60px;
  }
}

.return__content p {
  line-height: 1.64;
  margin: 0 0 19px;
  font-size: 14px;
  color: black;
}

.return__content p a {
  color: inherit;
  font-weight: 500;
}

.return__content p a:hover, .return__content p a:focus {
  text-decoration: underline;
}

.return__content .tech-ul {
  margin-bottom: 19px;
}

.return__content .tech-ul li {
  line-height: 1.64;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
}

.return__image {
  position: absolute;
  width: 130px;
  top: -90px;
  right: -40px;
}

@media only screen and (min-width: 992px) {
  .return__image {
    top: 18px;
    right: 0;
    width: initial;
  }
}

.team-banner {
  background-color: #fafafa;
  padding: 40px 0 0;
}

@media only screen and (min-width: 992px) {
  .team-banner {
    padding: 46px 0 0;
  }
}

@media only screen and (min-width: 1200px) {
  .team-banner {
    padding: 40px 0 0;
  }
}

.team-banner__inner {
  background: white;
  padding: 53px 0 55px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) {
  .team-banner__inner {
    padding: 39px 0 52;
    margin-bottom: 36px;
  }
}

@media only screen and (min-width: 1200px) {
  .team-banner__inner {
    padding: 53px 0 55px;
    margin-bottom: 42px;
  }
}

.team-banner__image {
  width: 100%;
  margin: 0 0 20px;
  text-align: center;
}

@media only screen and (min-width: 576px) {
  .team-banner__image {
    width: calc(40% - 15px);
    margin: 0;
  }
}

@media only screen and (min-width: 992px) {
  .team-banner__image {
    width: calc(30% - 15px);
  }
}

.team-banner__info {
  width: 100%;
  padding: 0 22px;
}

@media only screen and (min-width: 576px) {
  .team-banner__info {
    width: 60%;
    padding: 0 22px 0 0;
  }
}

@media only screen and (min-width: 992px) {
  .team-banner__info {
    padding: 0 28px 0 0;
    width: calc(70% - 15px);
  }
}

@media only screen and (min-width: 1200px) {
  .team-banner__info {
    padding: 0 98px 0 0;
  }
}

.team-banner__info p {
  line-height: 1.79;
  font-size: 14px;
}

.team-banner__desc {
  position: relative;
  padding: 0 20px 34px 20px;
}

@media only screen and (min-width: 992px) {
  .team-banner__desc {
    padding: 0 48px 34px 48px;
  }
}

@media only screen and (min-width: 1200px) {
  .team-banner__desc {
    padding: 0 50px 40px 68px;
  }
}

.team-banner__desc p {
  max-width: 70%;
  line-height: 1.79;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) {
  .team-banner__desc p {
    max-width: 530px;
  }
}

@media only screen and (min-width: 1200px) {
  .team-banner__desc p {
    max-width: 640px;
  }
}

.team-banner__desc img {
  position: absolute;
  bottom: 0;
  right: 15px;
}

@media only screen and (min-width: 576px) {
  .team-banner__desc img {
    right: 35px;
  }
}

@media only screen and (min-width: 992px) {
  .team-banner__desc img {
    right: 65px;
  }
}

@media only screen and (min-width: 1200px) {
  .team-banner__desc img {
    right: 86px;
  }
}

.team {
  padding: 60px 0 70px;
}

@media only screen and (min-width: 992px) {
  .team {
    padding: 80px 0 90px;
  }
}

@media only screen and (min-width: 1200px) {
  .team {
    padding: 103px 0 90px;
  }
}

.team__title {
  margin: 0 0 103px;
  font-size: 34px;
  letter-spacing: 4.4px;
}

@media only screen and (min-width: 992px) {
  .team__title {
    margin: 0 0 97px;
    letter-spacing: 5px;
    font-size: 39px;
  }
}

.team__item {
  display: flex;
  justify-content: space-between;
  margin: 0 0 103px;
  border: 10px solid #fafafa;
  flex-wrap: wrap;
}

.team__item:last-child {
  margin-bottom: 0;
}

.team__item-info {
  text-align: center;
  width: 100%;
  margin-top: -65px;
  padding: 0 20px;
}

@media only screen and (min-width: 992px) {
  .team__item-info {
    width: 30%;
  }
}

.team__item-info img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: solid 1px #979797;
  margin-bottom: 10px;
}

.team__item-title {
  letter-spacing: 4px;
  font-size: 22px;
  margin-bottom: 3px;
}

.team__item-post {
  font-size: 14px;
}

.team__item-description {
  padding: 20px 20px 40px;
  width: 100%;
}

@media only screen and (min-width: 576px) {
  .team__item-description {
    padding: 20px 35px 47px;
  }
}

@media only screen and (min-width: 992px) {
  .team__item-description {
    padding: 47px 73px 47px 15px;
    width: 70%;
  }
}

@media only screen and (min-width: 1200px) {
  .team__item-description {
    padding: 53px 114px 53px 15px;
  }
}

.team__item-description p {
  font-size: 14px;
  line-height: 1.79;
}

.review {
  background: rgba(249, 249, 249, 0.8);
  padding: 40px 0 90px;
}

@media only screen and (min-width: 768px) {
  .review {
    padding: 50px 0 133px;
  }
}

.review__inner {
  background: white;
  padding: 46px 30px;
  margin-bottom: 40px;
}

.review__title {
  font-size: 26px;
  letter-spacing: 3px;
  margin: 0 0 35px;
  font-weight: normal;
}

@media only screen and (min-width: 768px) {
  .review__title {
    font-size: 30px;
    margin: 0 0 41px;
  }
}

.review__image {
  margin-bottom: 30px;
}

.review p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  margin: 0 0 15px;
  color: #00000a;
}

.review p.review__contact {
  color: #676767;
}

.review p.review__contact a {
  color: inherit;
}

.review p.review__contact a:hover, .review p.review__contact a:focus {
  color: #54bae2;
}

.review__order {
  display: inline-block;
  line-height: 1.56;
  color: #54bae2;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}

.review__order:hover, .review__order:focus {
  text-decoration: underline;
}

.review .cta {
  min-height: 50px;
  height: auto;
  padding: 10px 25px;
}

.login {
  background: rgba(249, 249, 249, 0.8);
  padding: 41px 0 100px;
}

.login__inner {
  background: white;
}

.login .tabs-nav {
  margin: 0 0 30px;
}

.login .tabs-nav__item {
  width: 50%;
  text-align: center;
  padding: 0;
}

.login .tabs-nav__item.active {
  border-top: 1px solid transparent;
}

.login .tabs-nav__btn {
  padding: 20px 15px;
  font-size: 22px;
  letter-spacing: 4px;
  font-weight: 400;
}

.login .form-elements__label {
  margin-bottom: 20px;
}

.login form {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 0 10px 40px;
}

.login .tab-content {
  margin: 0;
}

.login .form-customer form {
  padding-bottom: 27px;
}

.login .form-customer__info {
  padding: 20px 15px 0;
  margin-bottom: -33px;
  line-height: 1.58;
  font-size: 12px;
  font-weight: 500;
  color: #9b9b9b;
  background: rgba(249, 249, 249, 0.8);
}

.login.my-account {
  padding-bottom: 139px;
}

.login.my-account .form-customer__info {
  margin-bottom: -71px;
}

.login.my-account .tab-content[data-tab-id="ex-account"] form {
  padding-bottom: 52px;
}

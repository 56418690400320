.main-nav {
  width: 100%;
  position: relative;
  display: none;

  @include lg {
    order: 3;
    display: block !important;
    width: auto;
    margin: 0 auto;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    left: -20px;
    right: -20px;
    top: 0;
    height: 1px;
    background-color: #bbbbbb;

    @include lg {
      display: none;
    }
  }

  >ul {
    @include lg {
      display: flex;
    }
  }

  >ul>li {
    border-bottom: 1px solid #d8d8d8;
    text-align: center;

    @include lg {
      border-bottom: none;
    }
  }

  >ul>li>a {
    display: inline-block;
    padding: 18px 17px 17px;
    font-family: $ff-headers;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.7px;
    text-align: center;
    color: #000000;
    transition: $trs;
    
    &:hover {
      color: $accent;
    }

    @include sm {
      padding: 26px 25px 25px;
      font-size: 24px;
    }

    @include lg {
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2px;
      color: #4a4a4a;
      padding: 0;
      margin: 0 25px;
    }
  }


}
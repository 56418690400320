.events-slider {
  background-color: #fafafa;
  padding: 30px 0;
  position: relative;

  @include sm {
    width: 100%;
  }

  @include md {
    padding: 50px 0;
  }

  &__item {
    height: 250px;
    background-color: #709F2B;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @include bg-img;
  }

  &__item-content {
    position: absolute;
    z-index: 1;
    bottom: 28px;
    right: 15px;
    left: 32px;
    color: #fff;

    @include sm {
      left: 64px;
    }
  }

  &__item-type {
    font-family: $ff-headers;
    font-size: 24px;
    letter-spacing: 3.3px;
    font-weight: normal;
    display: block;
    margin-bottom: 2px;
    text-transform: uppercase;

    @include sm {
      font-size: 30px;
    }
  }

  &__item-title {
    font-family: $ff-headers;
    font-size: 34px;
    font-weight: normal;
    letter-spacing: 4.4px;
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;

    @include sm {
      font-size: 40px;
    }
  }

  &__item-date {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.44;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: block;
    font-variant-numeric: lining-nums;

    @include sm {
      font-size: 16px;
    }
  }

  .slider-dots {
    bottom: 21px;
  }
}
.review {
  background: rgba(#f9f9f9, 0.8);
  padding: 40px 0 90px;

  @include md {
    padding: 50px 0 133px;
  }

  &__inner {
    background: white;
    padding: 46px 30px;
    margin-bottom: 40px;
  }

  &__title {
    font-size: 26px;
    letter-spacing: 3px;
    margin: 0 0 35px;
    font-weight: normal;

    @include md {
      font-size: 30px;
      margin: 0 0 41px;
    }
  }

  &__image {
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.79;
    margin: 0 0 15px;
    color: #00000a;

    &.review__contact {
      color: #676767;

      a {
        color: inherit;

        &:hover,
        &:focus {
          color: #54bae2;
        }
      }
    }
  }

  &__order {
    display: inline-block;
    line-height: 1.56;
    color: #54bae2;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .cta {
    min-height: 50px;
    height: auto;
    padding: 10px 25px;
  }
}
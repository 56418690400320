.custom-lab {
  &__summary-wrapper {
    font-variant-numeric: lining-nums;
    background-color: #272727;
    padding-top: 16px;
    padding-bottom: 45px;

    transform: translateY(100%);
    position: fixed;
    z-index: 5;
    bottom: 60px;
    left: 0;
    right: 0;
    transition: all .4s ease;
    max-height: calc(100vh - 100px);
    overflow-y: auto;

    @include md {
      padding-top: 40px;
      bottom: 100px;
    }

    &.opened {
      padding-top: 30px;
      bottom: 0;
      transform: translateY(0);

      @include md {
        padding-top: 40px;
      }
    }
  }

  &__summary {
    position: relative;

    @include md {
      display: flex;
      justify-content: space-between;
    }

    button[type=submit] {
      display: block;
      margin-top: 24px;
      width: 100%;
    }
  }

  &__summary-lead {
    font-family: $ff-primary;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
    text-transform: uppercase;

    @include md {
      font-size: 16px;
    }

    b {
      color: #fff;
    }
  }

  &__summary-steps {
    @include md {
      width: 65%;
    }
  }

  &__summary-steps-list {
    padding-top: 15px;
    display: none;
    padding-bottom: 20px;

    @include md {
      padding-bottom: 0;
      padding-top: 32px;
      columns: 2;
    }

    li {
      font-family: $ff-primary;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.88;
      letter-spacing: normal;
      color: #7d7d7d;
      padding-left: 30px;
      position: relative;

      @include md {
        padding-left: 40px;
        font-size: 16px;
      }

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 59%;
        background-color: #7d7d7d;
        position: absolute;
        left: 14px;
        top: 7px;

        @include md {
          top: 12px;
        }
      }

      &.completed {
        color: #fff;

        &::before {
          display: none;
        }

        &::after {
          content: "\2713";
          position: absolute;
          left: 10px;
          top: 0;
          transform: rotate(10deg);
        }
      }
    }
  }

  &__summary-cart-block {
    @include md {
      width: 30%;
    }
  }

  &__summary-toggle {
    position: absolute;
    z-index: 5;
    right: 0;
    top: -7px;
    width: 44px;
    height: 44px;

    @include md {
      top: -13px;
      right: -13px;
    }

    svg {
      fill: transparent;
      stroke: $accent;
      width: 100%;
      height: 100%;
      transition: all .4s ease;
    }

    &:hover svg {
      stroke: $accent-dark;
    }
  }

  &__summary-wrapper.opened &__summary-toggle svg {
    transform: rotate(180deg);
  }

  &__price-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  &__summary-price {
    user-select: none;
    font-family: $ff-headers;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 4.1px;
    text-align: right;
    color: #ffffff;
  }

  &__quantity-block {
    @extend .custom-lab__price-block;

    margin-top: 50px;
    margin-bottom: 16px;

    .wrap-number {
      margin-top: 0;

      input {
        text-align: center;
        color: #ffffff !important;
        background-color: transparent;
      }

      .number-minus,
      .number-plus {
        width: 22px;
        line-height: 22px;
        height: 22px;
        font-size: 22px;
      }
    }
  }

  &__add-to-cart-block {
    display: none;
  }
}
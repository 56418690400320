.pre-footer {
    overflow: hidden;
    &__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 51px 20px;
        font-size: 16px;
        font-weight: bold; 
        line-height: 1.44;
       
    }
    .bg-grey {
        background-color: #fafafa;
    }
    &__info {
        max-width: 338px;
        min-height: 46px;
        margin: 0 auto 22px;
        text-align: center;
        display: inline-flex;
        align-items: center;
    }
    .bg-accent {
        .pre-footer__info {
            color: white;
        }
    }
    .cta {
        width: 200px;
        text-align: center;
    }
}
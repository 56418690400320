.product-images {
  border-top: $grey-border;
  background-color: #ffffff;
  padding-top: 35px;
  margin-bottom: 40px;

  @include sm {
    margin-bottom: 80px;
  }

  @include md {
    margin-bottom: 0;
    padding-top: 15px;
    border-top: none;
  }

  &__list {
    border: solid 1px #f2f2f2;
    position: relative;

    @include md {
      border: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    @include lg {
      margin: 0 -20px;
    }

    @include xl {
      margin-right: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 17px;
      bottom: 17px;
      width: 25px;
      height: 25px;
      background: url(../img/icons/icon-zoom-in.svg) center/cover no-repeat;

      @include md {
        display: none;
      }
    }

    li {
      &:first-child a {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 17px;
          bottom: 17px;
          width: 25px;
          height: 25px;
          display: none;
          background: url(../img/icons/icon-zoom-in.svg) center/cover no-repeat;

          @include md {
            display: block;
          }
        }
      }

      @include md {
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }

      @include lg {
        margin-left: 20px;
        margin-right: 20px;
      }

      &.small {
        @include md {
          width: calc(50% - 20px);
        }

        @include lg {
          width: calc(50% - 40px);
        }

        .product-images__list-item {
          @include md {
            height: 130px;
          }

          @include lg {
            height: 170px;
          }

          @include xl {
            height: 200px;
          }
        }
      }
    }
  }

  &__list-item {
    display: block;
    padding: 50px 0;
    // display: flex;
    // justify-content: center;
    height: 365px;
    // align-items: center;
    position: relative;

    @include md {
      border: solid 1px #f2f2f2;
      padding: 0 20px;
      margin-bottom: 20px;
      width: 100%;
      height: 365px;
    }

    @include lg {
      height: 430px;
      padding: 0 30px;
      margin-bottom: 40px;
    }

    img {
      max-width: 80%;
      max-height: 100%;
      margin: 0 auto;

      @include object-fit(cover, center);

      @include md {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &--video {
      @include md {
        padding: 0;

        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex;

    button {
      font-size: 0;
      width: 36px;
      height: 2px;
      background-color: #f2f2f2;
      transition: $trs;
      margin: 0 5px;
      cursor: pointer;
    }

    .slick-active button {
      height: 4px;
      background-color: #54bae2;
    }
  }
}

img.mfp-img {
  margin: 40px auto;
  background: #fff;
  padding: 0;
}
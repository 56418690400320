$slide-trs: transform .2s cubic-bezier(0.2, 0, 1, 1);

.shop-filter {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 46px;

  @include lg {
    padding-top: 9px;
  }

  &__list-wrapper {
    transform: translateX(-101%);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 418px;
    background-color: #fff;
    z-index: 110;
    border-right: 1px solid #DDDDDD;
    transition: $slide-trs;

    @include lg {
      transform: translateX(0) !important;
      position: static;
      border-right: none;
      z-index: 0;
    }
  }

  &.opened &__list-wrapper {
    transform: translateX(0);
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: none;

    @include lg {
      display: none !important;
    }
  }

  &.opened &__overlay {
    display: block;
  }

  &__top-bar {
    height: $nav-mobile;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 31px;

    img {
      width: 14px;
    }

    @include lg {
      display: none;
    }
  }

  &__title {
    font-family: $ff-headers;
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 3.3px;
    margin-left: 10px;
  }

  &__mobile-close {
    margin-left: auto;
    font-size: 60px;
    font-weight: 500;

    &:hover {
      color: $accent;
    }
  }

  &__list {
    border-top: 1px solid #DDDDDD;
    margin-bottom: 30px;
    padding-top: 30px;
    padding-left: 12px;

    @include lg {
      padding-left: 0;
      padding-top: 36px;
    }

    @include xl {
      margin-bottom: 60px;
    }
  }

  &__item {
    margin-bottom: 19px;

    @include lg {
      margin-bottom: 12px;
    }
  }

  &__item a {
    padding-left: 27px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
    text-transform: uppercase;
    cursor: pointer;
    color: #000;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      border-top: solid 1px #000;
      border-right: solid 1px #000;
      position: absolute;
      left: 0;
      top: 6px;
    }

    @include lg {
      padding-left: 18px;
      font-size: 12px;

      &::before {
        top: 3px;
      }
    }
  }

  &__item.active a,
  &__item a:hover {
    color: $accent;

    &::before {
      border-color: $accent;
    }
  }

  &__mobile-open {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    @include sm {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
    }

    @include lg {
      display: none !important;
    }
  }
}
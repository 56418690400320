.return {
    background-color: #fafafa;
    padding:  40px 0 0;
    @include lg {
        padding:  60px 0 0;
    }
    overflow: hidden;
    &__content {
        position: relative;
        padding: 0 0 90px;
        @include lg {
            padding: 0 0 60px;
        }
        p {
            line-height: 1.64;
            margin: 0 0 19px;
            font-size: 14px;
            color: black;
            a {
                color: inherit;
                font-weight: 500;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
        .tech-ul {
            margin-bottom: 19px;
            li {
                line-height: 1.64;
                margin: 0 ;
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
    &__image {
        position: absolute;
        width: 130px;
        top: -90px;
        right: -40px;
        @include lg {
           top: 18px;
           right: 0;
           width: initial;
        }
    }
}
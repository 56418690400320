.pagination {
  margin-top: 30px;
  display: flex;
  align-items: center;

  li {
    &:not(:last-child) {
      margin-right: 15px;

      @include md {
        margin-right: 25px;
      }
    }
  }

  button {
    width: 46px;
    height: 34px;
    border-radius: 17px;
    background-color: #fff;
    align-items: center;
    line-height: 34px;
    font-variant-numeric: lining-nums;
    border: solid 1px #676767;

    font-size: 14px;
    font-weight: bold;
    color: #000;

    &:hover {
      background-color: #000000;
      color: #fff;
    }
  }

  li.active button {
    background-color: #000000;
    color: #fff;
  }
}

.pagination--shop {
  justify-content: center;
  margin-bottom: 70px;

  @include md {
    justify-content: flex-end;
  }

  @include xl {
    margin-top: -20px;
  }
}
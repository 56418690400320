$avatar-width-mobile: 50px;
$avatar-width-tablet: 85px;
$avatar-margin-mobile: 12px;
$avatar-margin-tablet: 28px;
$avatar-summary-mobile: $avatar-width-mobile+$avatar-margin-mobile;
$avatar-summary-tablet: $avatar-width-tablet+$avatar-margin-tablet;

.comments {
  padding-top: 51px;
  padding-bottom: 88px;

  &__title {
    font-family: $ff-headers;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 4px;
    text-align: center;
    color: #00000a;
    display: block;
    margin-bottom: 28px;

    @include xl {
      font-size: 30px;
    }
  }

  &__count {
    color: #9b9b9b;
  }

  &__item {
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  &__item-left {
    margin-right: $avatar-margin-mobile;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $avatar-width-mobile;
    flex-shrink: 0;

    @include md {
      margin-right: $avatar-margin-tablet;
      width: $avatar-width-tablet;
    }
  }

  &__item-right {
    width: calc(100% - #{$avatar-summary-mobile});

    @include md {
      width: calc(100% - #{$avatar-summary-tablet});
    }
  }

  &__item-avatar {
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 17px;

    img {
      height: 100%;
      width: 100%;

      @include object-fit(cover, center);
    }
  }

  &__item-time {
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    color: #9b9b9b;
    line-height: normal;
    text-transform: uppercase;
    font-variant-numeric: lining-nums;

    @include md {
      font-size: 12px;
    }
  }

  &__item-header {
    margin-bottom: 5px;

    @include sm {
      margin-bottom: 10px;
      align-items: center;
      display: flex;
    }
  }

  &__item-author {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #00000a;
    margin-right: 20px;
    display: block;
    margin-bottom: 5px;

    @include sm {
      margin-bottom: 0;
      font-size: 16px;
    }

    @include md {
      font-size: 18px;
    }
  }

  &__item-reply {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.79;
    letter-spacing: normal;
    color: $accent;
    text-transform: uppercase;

    @include sm {
      font-size: 14px;
    }

    &::after {
      content: '';
      width: 9px;
      height: 9px;
      display: inline-block;
      border-top: solid 2px $accent;
      border-right: solid 2px $accent;
      transform: rotate(45deg);
      margin-left: 9px;
      margin-bottom: 1px;
    }

    &:hover {
      color: $accent-dark;

      &::after {
        border-color: $accent-dark
      }
    }
  }

  &__item.reply-active &__item-reply::after {
    content: '\2716';
    font-weight: bold;
    width: auto;
    height: auto;
    border: none;
    transform: rotate(0);
    margin-left: 9px;
    margin-bottom: 0;
  }

  &__item-comment {
    font-size: 12px;
    font-style: italic;
    line-height: 1.7;
    color: $text;

    @include md {
      font-size: 14px;
    }
  }

  &__item-replies {
    width: 100%;
    padding-left: $avatar-summary-mobile;

    @include md {
      padding-left: $avatar-summary-tablet;
    }

    .comments__item {
      margin-bottom: 25px;
    }

    .comments__item:last-child {
      margin-bottom: 0;
    }

    .comments__item:first-child {
      margin-top: 25px;
    }
  }
}
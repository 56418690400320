.custom-lab {
  &__steps-wrapper {
    margin-top: 40px;
    margin-bottom: 40px;

    @include md {
      margin-top: 150px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 160px;
    }

    @include xl {
      margin-top: 13px;
    }
  }

  &__step-img-wrapper {
    margin-bottom: 40px;
    height: 55vw;
    text-align: center;
    position: relative;

    img {
      height: 100%;
    }

    @include md {
      height: auto;
      order: 2;
      width: 45%;
      margin-bottom: 0;

      img {
        height: auto;
        max-height: 100%;
      }
    }
  }

  &__step-img-inner {
    height: 55vw;
    cursor: zoom-in;
    position: relative;

    @include md {
      height: auto;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 25px;
      height: 25px;
      background: url(../img/icons/icn-zoom-in-white.svg) center/contain no-repeat;
    }
  }

  &__step-img-wrapper.stick &__step-img-inner {
    @include md-max {
      position: fixed;
      top: 100px;
      right: 18px;
      z-index: 1;
      width: 128px;
      height: 140px;
      border: solid 1px #979797;
      background-color: #000000;
  
      img {
        height: 100px;
      }
    }
  }

  &__step-img-wrapper.zoomed &__step-img-inner {
    
    position: fixed;
    right: auto;
    cursor: zoom-out;

    width: 90vw;
    height: 80vh;

    z-index: 100;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid 1px #979797;
    background-color: #000000;

    &::after {
      background-image: url(../img/icons/icn-zoom-out-white.svg);
    }

    img {
      height: auto;
      max-height: 90%;
      max-width: 80%;
    }
  }

  &__steps-list-wrapper {

    @include md {
      order: 1;
      width: 50%;
    }

    @include xl {
      margin-top: 140px;
    }
  }

  &__steps-bottom {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    @include md {
      margin-top: 30px;
    }
  }

  &__step-item {
    margin-bottom: 10px;

    display: none;
  }

  &__steps-bottom-status,
  &__step-title {
    font-family: $ff-headers;
    font-size: 18px;
    padding-left: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #414141;

    position: relative;
    display: inline-block;

    @include md {
      font-size: 30px;
      padding-left: 40px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border: solid 2px #414141;
      border-radius: 50%;
      left: 0;
      top: 0;

      @include md {
        top: 5px;
        width: 24px;
        height: 24px;
      }
    }
  }

  &__step-item.completed &__step-title {
    cursor: pointer;

    &:hover {
      color: $accent;
    }
  }

  &__step-content {
    display: none;
    padding: 25px 10px;

    @include md {
      padding: 40px 10px;
    }
  }

  &__step-content-inner {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5),
      0 0 0 10px #1F343C;
    border: solid 2px $accent;
    background-color: #272727;
    padding: 15px;

    @include md {}

    @include lg {
      padding: 40px;

    }
  }

  &__step-item.active &__step-content {
    // display: block;
  }

  &__step-item.completed &__step-title,
  &__steps-bottom-status.completed {
    &::after {
      content: "\2713";
      font-size: 10px;
      position: absolute;
      left: 4px;
      top: 1px;
      transform: rotate(5deg);

      @include md {
        left: 6px;
        top: 7px;
        font-size: 15px;
      }
    }
  }

  &__step-item.active &__step-title {
    color: $accent;

    &::before {
      border-color: $accent;
    }

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: $accent;
      border-radius: 50%;
      left: 5px;
      top: 5px;

      @include md {
        left: 8px;
        top: 13px;
        width: 8px;
        height: 8px;
      }
    }
  }

  &__step-lead {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
    margin-bottom: 10px;

    @include md {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  &__step-descr {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #676767;
    margin-bottom: 20px;

    @include md {
      font-size: 14px;
      margin-bottom: 40px;
    }
  }

  &__radio-buttons {
    margin-bottom: -15px;

    >* {
      width: 100%;
      margin-bottom: 15px;
      display: block;
    }

    @include sm {
      display: flex;
      margin-left: -7px;
      margin-right: -7px;

      >* {
        margin-left: 7.5px;
        margin-right: 7.5px;
        width: 100%;
      }
    }

    @include xl {
      margin-bottom: -30px;
      margin-left: -15px;
      margin-right: -15px;

      >* {
        margin-bottom: 30px;
        margin-left: 15px;
        margin-right: 15px;
        width: 100%;
      }
    }

    &--wrap {
      @include sm {
        flex-wrap: wrap;

        >* {
          width: calc(50% - 15px);
        }
      }

      @include xl {
        >* {
          width: calc(50% - 30px);
        }
      }
    }
  }

  &__radio-btn {
    input[type=radio] {
      display: none;
    }

    label {
      height: 50px;
      border: solid 1px #ffffff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: $trs;

      @include md {
        height: 90px;
      }

      @include lg {
        height: 70px;
      }

      small {
        font-size: 10px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #9b9b9b;
        font-variant-numeric: lining-nums;

        @include md {
          font-size: 12px;
        }
      }

      span {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        @include md {
          font-size: 14px;
        }

        @include xl {
          font-size: 16px;
        }
      }
    }

    label:hover,
    input[type=radio]:checked+label {
      background-color: #fff;

      span {
        color: #00000a;
      }
    }

    &--color {
      label {
        height: 90px;

        @include md {
          height: 130px;
        }
      }

      i {
        display: block;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        margin-bottom: 13px;

        @include md {
          height: 45px;
          width: 45px;
        }
      }
    }
  }

  &__step-label {
    color: #838383;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-transform: uppercase;
    margin-bottom: 3px;

    @include md {
      font-size: 14px;
    }
  }

  &__step-range-wrapper {
    position: relative;
  }

  &__step-range-next,
  &__step-range-prev {
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-color: #fff;
    display: inline-block;
    height: 12px;
    width: 12px;
    position: absolute;
    top: 20px;
  }

  &__step-range-prev {
    left: 10px;
    transform: rotate(-135deg);
  }

  &__step-range-next {
    right: 10px;
    transform: rotate(45deg);
  }

  &__step-range-outer {
    padding-top: 25px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }

  &__step-range-inner {
    height: 60px;
  }

  &__step-info {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #676767;
    font-variant-numeric: lining-nums;
    margin-top: 30px;
    padding-left: 25px;
    position: relative;

    @include sm {
      padding-left: 38px;
      font-size: 14px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: -1px;
      width: 16px;
      height: 16px;
      background: url(../img/icons/icn-info.svg) center/cover no-repeat;

      @include sm {
        width: 24px;
        height: 24px;
        top: -3px;
      }
    }

    >* {
      display: block;
      margin-right: 12%;

      @include sm {
        display: inline-block;
      }
    }
  }

  &__img-overlay {
    z-index: 10;
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#000, .8);
  }

  .noUi-pips-horizontal {
    height: 60px;
  }

  .noUi-target {
    height: 60px;
    cursor: pointer;
    background-color: transparent;
    box-shadow: none;
    border: none;

    &::after {
      content: '';
      width: 100%;
      height: 3px;
      top: 0;
      background-color: #979797;
      position: absolute;
      left: 0;
    }
  }

  .noUi-pips-horizontal {
    padding: 0;
    top: 0;
  }

  .noUi-handle {
    cursor: pointer;
    top: -18px;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.15);

    &::after,
    &::before {
      border-style: solid;
      border-width: 2px 2px 0 0;
      border-color: #D7D7D7;
      background-color: transparent;
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      position: absolute;
      top: 15px;
    }

    &::before {
      left: 9px;
      right: auto;
      transform: rotate(-135deg);
    }

    &::after {
      right: 9px;
      left: auto;
      transform: rotate(45deg);
    }

  }

  .noUi-marker-horizontal.noUi-marker-sub {
    height: 15px;
    background-color: #979797;
  }

  .noUi-value {
    top: 26px;
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
    font-variant-numeric: lining-nums;
    transition: $trs;

    @include md {
      font-size: 16px;
    }

    &.active {
      color: #fff;
      font-size: 12px;
      top: 20px;

      @include md {
        top: 18px;
        font-size: 20px;
      }
    }

  }

  .noUi-value-large {
    display: none;
  }

  .noUi-marker-large,
  .noUi-marker-small,
  .noUi-value-large,
  .noUi-value-small {
    display: none;
  }

  .noUi-background {
    background: transparent;
    box-shadow: none;
  }
}

.price {
  position: fixed;
  top: 100px;
  right: 100px;
  color: #fff;
}


.accordeon {
  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__item-header {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 13px;
    justify-content: space-between;
    background-color: #f9f9f9;
    cursor: pointer;
    user-select: none;

    &::after {
      content: '+';
      height: 26px;
      width: 26px;
      flex-shrink: 0;
      border-radius: 50%;
      border: 2px solid #676767;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #676767;
      font-size: 26px;
      padding-top: 2px;
      font-weight: 500;
    }
  }

  &__item.active &__item-header {
    background-color: $dark;
  }

  &__item.active &__item-header::after {
    content: '-';
    border: 2px solid #fff;
    color: #fff;
  }

  &__item-name {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;

    small {
      margin-left: 5px;
      font-size: 12px;
      font-weight: 500;
      color: #9b9b9b;
      text-transform: none;
    }
  }

  &__item.active &__item-name {
    background-color: $dark;
    color: #fff;
  }

  &__item-content {
    display: none;
  }
}

.accordeon--locations {
  @include lg-max {
    .accordeon__item {
      display: none;

      &.active {
        margin-top: -20px;
        display: block !important;

        .accordeon__item-content {
          display: block !important;
        }
      }
    }

    .accordeon__item-header {
      display: none;
    }
  }
}

.accordeon--help {
  .accordeon__item-header {
    justify-content: flex-start;

    @include lg {
      height: 60px;
    }

    &::after {
      order: 1;
      margin-right: 20px;
      background-color: #D8D8D8;
      border: none;
      
      @include lg {
        margin-right: 42px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .accordeon__item.active {
    .accordeon__item-header::after {
      background: none;
    }
  }

  .accordeon__item-name {
    order: 2;
    font-size: 12px;
  }

  .accordeon__item-content {
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 15px;

    font-size: 14px;
    line-height: 1.79;
    letter-spacing: normal;
    color: #00000a;

    @include sm {
      padding-left: 70px;
      padding-right: 70px;
    }

    @include lg {
      padding-left: 107px;
      padding-top: 20px;
      padding-right: 100px;
      padding-bottom: 15px;
    }
  }
}
.breadcrumbs {
  padding-top: 26px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include sm {
    padding-top: 40px;
    margin-bottom: 52px;
  }

  @include lg {
    padding-top: 26px;
    margin-bottom: 60px;
  }

  li {
    font-size: 10px;
    color: #9b9b9b;
    line-height: 1;
    font-weight: 500;

    @include sm {
      font-size: 12px;
    }

    &:not(:last-child)::after {
      content: '>';
      margin: 0 4px;
    }
  }

  a {
    color: inherit;
    text-transform: uppercase;
    display: inline-block;
    font-variant-numeric: lining-nums;

    &[href]:hover,
    &[href]:focus {
      color: $accent;
    }
  }
}
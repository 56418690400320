.related-posts {
  padding-top: 60px;

  @include md {
    padding-top: 100px;
  }

  h3 {
    text-align: center;
    padding-bottom: 30px;
    margin-bottom: 60px;
    border-bottom: 1px solid #d6d6d6;
  }

  .cta {
    margin-top: 40px;
    margin-bottom: 40px;

    @include md {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
}
.team-banner {
    background-color: #fafafa;
    padding: 40px 0 0;
    @include lg {
        padding: 46px 0 0;
    }
    @include xl {
        padding: 40px 0 0;
    }
    &__inner {
        background: white;
        padding: 53px 0 55px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        flex-wrap: wrap;
        @include lg {
            padding: 39px 0 52;
            margin-bottom: 36px;
        }
        @include xl {
            padding: 53px 0 55px;
            margin-bottom: 42px;
        }
    }
    &__image {
        width: 100%;
        margin: 0 0 20px;
        text-align: center;
        @include sm {
            width: calc(40% - 15px);
            margin: 0;
        }
        @include lg {
            width: calc(30% - 15px);
        }
    }
    &__info {
        width: 100%;
        padding: 0 22px ;
        @include sm {
            width: 60% ;
            padding: 0 22px 0 0;
        }
        @include lg {
            padding: 0 28px 0 0;
            width: calc(70% - 15px);
        }
        @include xl {
            padding: 0 98px 0 0;
        }
        p {
            line-height: 1.79;
            font-size: 14px;
        }
    }
    &__desc {
        position: relative;
        padding: 0 20px 34px 20px;
        @include lg {
            padding: 0 48px 34px 48px;
        }
        @include xl {
            padding: 0 50px 40px 68px;
        }
        p {
            max-width: 70%;
            line-height: 1.79;
            font-size: 14px;
            font-weight: 500;
            @include lg {
                max-width: 530px;
            }
            @include xl {
                max-width: 640px;
            }
        }
        img {
            position: absolute;
            bottom: 0;
            right: 15px;
            @include sm {
                right: 35px;
            }
            @include lg {
                right: 65px;
            }
            @include xl {
                right: 86px;
            }
        }
    }

}

.team {
    padding: 60px 0 70px;
    @include lg {
        padding: 80px 0 90px;
    }
    @include xl {
        padding: 103px 0 90px;
    }
    &__title {
        margin: 0 0 103px;
        font-size: 34px;
        letter-spacing: 4.4px;
        @include lg {
            margin: 0 0 97px;
            letter-spacing: 5px;
            font-size: 39px;
        }
    }
    &__item {
        display: flex;
        justify-content: space-between;
        margin: 0 0 103px;
        border: 10px solid #fafafa;
        flex-wrap: wrap;
        &:last-child {
            margin-bottom: 0;
        }
        &-info {
            text-align: center;
            width: 100%;
            margin-top: -65px;
            padding: 0 20px;
            @include lg {
                width: 30%;
            }
            img {
                width: 130px;
                height: 130px;
                border-radius: 50%;
                border: solid 1px #979797;
                margin-bottom: 10px;
            }
        }
        &-title {
            letter-spacing: 4px;
            font-size: 22px;
            margin-bottom: 3px;
        }
        &-post {
            font-size: 14px;
        }
        &-description {
            padding: 20px 20px 40px;
            width: 100%;
            @include sm {
                padding: 20px 35px 47px;
            }
            @include lg {
                padding: 47px 73px 47px 15px;
                width: 70%;
            }
            @include xl {
                padding: 53px 114px 53px 15px;
            }
            p {
                font-size: 14px;
                line-height: 1.79;
            }
        }
    }
}
.inquire-cards {
  &__list {
    @include lg {
      margin: 0 -15px;
      display: flex;
      justify-content: center;
    }
  }

  &__item {
    @include lg {
      margin: 0 15px;
      max-width: 705px;
    }

    &:first-child {
      @include xl {
        .inquire-cards__item-header,
        .inquire-cards__item-descr {
          padding-left: 60px;
          padding-right: 60px;
        }
      }

      @include rwd(1440) {
        .inquire-cards__item-header,
        .inquire-cards__item-descr {
          padding-left: 150px;
          padding-right: 98px;
        }
      }
    }

    &:last-child {
      @include xl {
        .inquire-cards__item-header,
        .inquire-cards__item-descr {
          padding-left: 60px;
          padding-right: 60px;
        }
      }

      @include rwd(1440) {
        .inquire-cards__item-header,
        .inquire-cards__item-descr {
          padding-right: 150px;
          padding-left: 98px;
        }
      }
    }
  }

  &__item-img {
    height: 250px;
    position: relative;

    @include sm {
      height: 433px;
    }

    img {
      height: 100%;
      width: 100%;
      @include object-fit(cover, center top);
    }
  }

  &__item-header {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    background-color: rgba(#fff, 0.8);
    padding: 0 15px;
  }

  &__item-header-inner {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include sm {
      height: 95px;
    }

    @include md {
      max-width: 600px;
      margin: 0 auto;
    }

    @include lg {
      max-width: 792px;
    }
  }

  &__item-title {
    margin-top: 4px;
  }

  &__item-descr {
    padding: 32px 15px;
    font-size: 14px;
    line-height: 1.64;
    background-color: #fafafa;

    p {
      @include md {
        max-width: 600px;
        margin: 0 auto;
      }

      @include lg {
        max-width: 792px;
      }
    }
  }

  .cta {
    @include xl {
      width: 165px;
    }
  }
}

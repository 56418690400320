$ff-primary: "TTWellingtons",
sans-serif;
$ff-headers: "BebasNeue",
sans-serif;

$nav-mobile: 100px;
$nav-mobile-scrolled: 50px;

$nav-tablet: 100px;
$nav-tablet-scrolled: 50px;

$nav-desktop: 100px;
$nav-desktop-scrolled: 50px;

$secondary-nav-tablet: 52px;
$secondary-nav-tablet-scrolled: 30px;

$secondary-nav-desktop: 52px;
$secondary-nav-desktop-scrolled: 30px;

$nav-shadow: 0 8px 16px -5px rgba(0, 0, 0, 0.54);

$trs: all 0.2s ease;
$grey-border: 1px solid #D6D6D6;


// colors
$accent: #54bae2;
$accent-dark: #549ce2;
$accent-light: #e1f7ff;
$dark: #181818;
$text: #4a4a4a;
$dark-light: #797979;

$grey-dark: #bbbbbb;
$grey: #c5c5c5;
$grey-light: #d8d8d8;
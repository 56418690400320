.btn-favorite {
  position: relative;
  width: 70px;
  text-align: center;

  &::before {
    content: '';
    display: inline-block;
    width: 40px;
    height: 38px;
    background: url(../img/icons/icon-heart.svg) center/cover no-repeat;
    margin-bottom: 12px;
  }

  &::after {
    content: 'Add to favorite';
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    color: #00000a;
    display: block;
  }

  &:hover {
    &::before {
      background-image: url(../img/icons/icon-heart-accent-filled.svg);
    }
  }

  &--added {
    &::before {
      background-image: url(../img/icons/icon-heart-accent-filled.svg);
    }

    &::after {
      content: 'Added to favorite'
    }
  }


}
.product-info {
  margin-bottom: 54px;

  @include md {
    margin-bottom: 70px;
  }

  @include lg {
    padding-top: 15px;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 29px;
    border-bottom: $grey-border;
  }

  &__price-section {
    font-variant-numeric: lining-nums;
  }

  &__code {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.79;
    color: #9b9b9b;
    display: block;
    margin-bottom: 13px;
  }

  &__sale-price {
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000000;
    display: block;
    margin-bottom: 9px;
  }

  &__old-price {
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #9b9b9b;
    display: block;

    b {
      text-decoration: line-through;
    }
  }

  &__price {
    font-family: $ff-headers;
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 4.4px;
    color: #00000a;
    margin-left: 30px;
  }

  .btn-favorite {
    margin-right: 55px;

    @include md {
      margin-right: 20px;
    }
  }

  .cta {
    margin-top: 21px;
  }

  &__section {
    padding-top: 38px;

    &:not(:last-child) {
      padding-bottom: 31px;
      border-bottom: $grey-border;
    }

    p {
      font-size: 16px;
      line-height: 1.6;
      color: $text;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 18px;
      font-variant-numeric: lining-nums;
      font-size: 16px;
      line-height: 1.2;
      color: $text;
    }

    li {
      margin-bottom: 15px;
    }

    a {
      color: $accent;
      font-weight: 500;

      &:hover {
        color: $accent-dark;
      }
    }
  }

  &__section-title {
    font-family: $ff-headers;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 4px;
    color: #00000a;
    text-transform: uppercase;
    display: block;
    margin-bottom: 14px;
  }

  &__options {
    margin-bottom: 38px;

    p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #4a4a4a;
    }

    input[type=radio] {
      display: none;
    }

    label {
      width: 120px;
      height: 50px;
      display: inline-flex;
      color: #000;
      margin-bottom: 11px;
      margin-right: 11px;
      align-items: center;
      justify-content: center;
      font-variant-numeric: lining-nums;

      border: 1px solid rgba(#000, .5);
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      transition: $trs;

      @include sm {
        width: 160px;
        font-size: 14px;
      }

      @include xl {
        font-size: 16px;
        height: 70px;
        width: 210px;
      }
    }

    input:checked+label {
      color: #ffffff;
      background-color: #00000a;
    }
  }

  &__options-title {
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: #777777;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__section-title+&__options {
    margin-top: 30px;
  }
}

.product-socials {
  margin-bottom: 54px;
  text-align: center;
  margin-top: 20px;

  @include md {
    margin-bottom: 70px;
  }

  @include lg {
    align-items: center;
    display: flex;
  }

  &__text {
    text-transform: uppercase;
    font-family: $ff-headers;
    font-size: 18px;
    font-weight: normal;
    color: #000000;
    letter-spacing: 3px;
    display: block;
    margin-bottom: 15px;

    @include sm {
      letter-spacing: 4px;
      font-size: 22px;
    }

    @include lg {
      margin-bottom: 0;
    }
  }
}
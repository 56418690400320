.usg {
    padding: 70px 0 80px;
    @include lg {
        padding: 100px 0;
      }
    &__title {
        margin: 0 0 53px;
        font-size: 30px;
        @include lg {
            font-size: 36px;
            margin: 0 0 70px;
        }
    }
    &__item {
        margin-bottom: 80px;
        @include lg {
           margin: 0;
        }
        &-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 40px;
            max-width: 70px;
            width: 100%;
            @include sm {
                max-width: 130px;
            }
            @include lg {
                display: inline-flex;
                height: 78px;
                margin: 0 0 35px;
                max-width: 100%;
            }
            img {
                width: 100%;
                max-height: 158px;
                @include lg {
                    width: auto;
                }
            }
        }
        .cta.cta--40.cta--border-white {
            height: 60px;
            border-width: 4px;
            font-size: 16px;
            display: inline-flex;
            width: auto;
            padding: 0 20px;
            @include sm {
                width: auto;
                padding: 0;
                display: flex;
            }
            @include lg {
               height: 40px;
               border-width: 2px;
               font-size: 14px;
            }
        }
    }
    .col-xl-3:last-child {
        .usg__item {
          margin: 0;
        }
    }
    .col-xl-3:first-child {
        img {
            max-width: 118px;
        }
    }
}